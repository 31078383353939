
import { Plus as ElIconPlus, Minus as ElIconMinus } from '@element-plus/icons-vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as _ from 'lodash'
// import enumservice from "../../../services/enumservice";
import orgService from '../../services/org/orgservice'
import workhoursservice from '../../services/workhours/workhours'
import loginService from '../../services/login'
import buildOrgTree from '../../util'

export default {
  data() {
    return {
      lodash: _,
      rules: {
        code: [
          {
            required: true,
            message: '用户名称不能为空',
            trigger: 'change',
          },
        ],
        dep_code: [
          {
            required: true,
            message: '组织名称不能为空',
            trigger: 'change',
          },
        ],
      },
      orgRules: {
        // parentcode: [
        //     {
        //         required: true,
        //         message: "上级组织不能为空",
        //         trigger: "change"
        //     }
        // ],
        name: [
          {
            required: true,
            message: '组织名称不能为空',
            trigger: 'blur',
          },
        ],
      },
      orgParentcode: '',
      orgDialogVisible: false,
      orgForm: {},
      orgList: [],
      userList: [],
      allUser: [],
      depCode: '001',
      //节点树数据
      setTree: [],
      iconSize: 'mini',
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      pageSize: 15,
      total: 0,
      currentPage: 1,
      dialogVisible: false,
      form: {},
      title: '',
      status: '',
      parentKey: 'parentcode',
      ElIconPlus,
      ElIconMinus,
    }
  },
  mounted() {
    this.getOrgInfo()
  },
  methods: {
    nodeClick(data, node) {
      this.currentPage = 1
      this.depCode = data.code
      this.getUserList()
    },
    async confirmAddOrg() {
      this.orgForm.parentcode = this.orgParentcode
      var arr = []
      for (var i = 0; i < this.orgList.length; i++) {
        if (this.orgList[i].parentcode == this.orgForm.parentcode) {
          arr.push(this.orgList[i])
        }
      }
      if (arr.length != 0) {
        var code = arr[arr.length - 1].code
        var start = code.substr(0, 2)
        var endTemp = code.substr(2)
        var end = start + (parseInt(endTemp) + 1).toString()
        this.orgForm.code = end
      } else {
        this.orgForm.code = this.orgForm.parentcode + '001'
      }
      const result: any = await orgService.addOrg(this.orgForm)
      if (result.success) {
        this.orgDialogVisible = false
        this.$message.success('添加成功')
        this.getOrgInfo()
      } else {
        this.$message.warning(result.msg)
      }
    },
    orgClose() {
      this.$refs['orgForm'].resetFields()
    },
    close() {
      // this.errorMessage = "";
      // this.$refs["form"].resetFields();
    },
    addOrg(node, data) {
      this.orgForm = {}
      this.orgParentcode = data.code
      this.orgDialogVisible = true
    },
    deleteOrg(node, data) {
      var params = {
        code: data.code,
      }
      this.$confirm('子组织会一同删除，确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const result: any = await orgService.deleteOrg({
            params,
          })
          if (result.success) {
            this.$message({
              message: '删除成功',
              type: 'success',
            })
            this.getOrgInfo()
          }
        })
        .catch((err) => {
          throw err
        })
    },
    buildTree(data) {
      if (data) {
        var pos = {}
        var tree = []
        var i = 0
        var dataLength = data.length
        while (data.length != 0) {
          if (data[i][this.parentKey] == '*') {
            var _temp = this.copy(data[i])
            tree.push(_temp)
            pos[data[i]['code']] = [tree.length - 1]
            data.splice(i, 1)
            i--
          } else {
            var posArr = pos[data[i][this.parentKey]]
            if (posArr != undefined) {
              var obj = tree[posArr[0]]
              for (var j = 1; j < posArr.length; j++) {
                obj = obj.children[posArr[j]]
              }
              var _temp = this.copy(data[i])
              obj.children.push(_temp)
              pos[data[i]['code']] = posArr.concat([obj.children.length - 1])
              data.splice(i, 1)
              i--
            }
          }
          i++
          if (i > data.length - 1) {
            i = 0
            if (data.length == dataLength) {
              break
            } else {
              dataLength = data.length
            }
          }
        }
        return tree
      }
    },
    copy(item) {
      var _temp = {
        children: [],
      }
      _temp['code'] = item['code']
      for (var _index in item) {
        if (_index != 'code') {
          var _property = item[_index]
          if (!!this.map && this.map[_index])
            _temp[this.map[_index]] = _property
          else _temp[_index] = _property
        }
      }
      return _temp
    },
    arrayDeepCopy(source) {
      var sourceCopy = source instanceof Array ? [] : {}
      for (var item in source) {
        if (source[item] != null) {
          sourceCopy[item] =
            typeof source[item] === 'object'
              ? this.arrayDeepCopy(source[item])
              : source[item]
        } else {
          sourceCopy[item] = null
        }
      }
      return sourceCopy
    },
    async getOrgInfo() {
      var self = this
      const result: any = await orgService.getOrgInfo()
      this.orgList = this.arrayDeepCopy(result.rows)
      this.getUserList()
      self.setTree = buildOrgTree(result.rows)
    },
    async getAllUserList() {
      const items: any = await workhoursservice.getAllUser({})
      if (items.success) {
        this.allUser = items.rows
      } else {
        this.$message.warning(items.msg)
      }
    },
    async getUserList() {
      var params = {
        dep_code: this.depCode,
        currentPage: this.currentPage,
        pageSize: 15,
      }
      const items: any = await workhoursservice.getAllUser(params)
      if (items.success) {
        this.userList = items.rows
        this.total = items.total
      } else {
        this.$message.warning(items.msg)
      }
    },
    updateOrAdd() {
      var self = this
      this.$refs['form'].validate((valid) => {
        if (valid) {
          self.updateAudit()
        } else {
          return false
        }
      })
    },

    async updateAudit() {
      const result: any = await loginService.updateUser(this['form'])
      if (result.success) {
        this.$message.success('修改成功')
        this.dialogVisible = false
        this.getUserList()
      } else {
        this.$message.warning(result.msg)
      }
    },

    updateUser(row) {
      this.getAllUserList()
      this.dialogVisible = true
      this.title = '编辑人员组织信息'
      this.status = 'edit'
      this.form = _.cloneDeep(row)
    },
    orgAddUser(node, data) {
      this.getAllUserList()
      this.form = {}
      this.dialogVisible = true
      this.title = '组织添加人员'
      this.status = 'add'
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getUserList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getUserList()
    },
  },
}
