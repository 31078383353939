import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "update-his-container" }
const _hoisted_2 = { class: "update-log-container" }
const _hoisted_3 = { class: "update-log" }
const _hoisted_4 = { class: "contents" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_el_button, {
      class: "add-btn",
      type: "primary",
      onClick: _ctx.addNew
    }, {
      default: _withCtx(() => [
        _createTextVNode("新增")
      ]),
      _: 1
    }, 8, ["onClick"]), [
      [_vShow, _ctx.role == 'dev']
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.updatelogs, (item, key) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("h2", null, _toDisplayString(item.date), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.logs, (content) => {
              return (_openBlock(), _createElementBlock("p", null, _toDisplayString(content), 1))
            }), 256))
          ])
        ]))
      }), 256))
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      title: "新增日志",
      width: "70%"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: _ctx.addForm,
          ref: "addForm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "更新时间",
              prop: "date"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.addForm.date,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addForm.date) = $event)),
                  type: "date",
                  "value-format": "YYYY-MM-DD",
                  placeholder: "选择日期"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "更新内容",
              prop: "content"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "textarea",
                  autosize: { minRows: 4, maxRows: 100 },
                  modelValue: _ctx.addForm.content,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addForm.content) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.save
            }, {
              default: _withCtx(() => [
                _createTextVNode("保存")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}