
import { Component, Vue, Watch } from 'vue-facing-decorator'
import orgService from '../../../services/org/orgservice'
import workhourService from '../../../services/workhours/workhours'
import { project_types }  from '../../../util'
import moment from 'moment'
import * as _ from 'lodash'

@Component
export default class PernalWork extends Vue {
  // public orgList: any[]
  // public projectTypeList: any[]
  // public developTypeList: any[]
  // public tableData: any[]
  // public queryItem: any
  // public pickerOptions: any
  // public calcHeight: number
  // public orgObject: any
  // public ptObject: any
  // public dtObject: any
  // contactArray: any[]
  // contactDot: number
  // contactArray1: any[]
  // contactDot1: number
  // contactArray2: any[]
  // contactDot2: number
  // contactArray3: any[]
  // contactDot3: number
  // projectstatusclassoptions: any[]
  // parentItems: any[]
  // childItems: any[]
  // public developTypeClass: any[]

      userInfoString = localStorage.getItem('userInfo')
      userInfo = this.userInfoString !== null ? JSON.parse(this.userInfoString) : {}
      lodash = _;
      contactArray = []
      contactDot = 0
      contactArray1 = []
      contactDot1 = 0
      contactArray2 = []
      contactDot2 = 0
      contactArray3 = []
      contactDot3 = 0
      calcHeight = document.documentElement.clientHeight - 60
      querydate = []
      queryItem: any = {
        querydate: [
          moment().startOf('months').format('YYYY-MM-DD'),
          moment().endOf('months').format('YYYY-MM-DD'),
        ],
        pro_dep: this.userInfo.dep_code,
        project_type: '',
        class: '',
        class_type: '0',
        project_name: '',
        financial_type: '0'
      }
      orgList = []
      projectTypeList = []
      developTypeList = []
      tableData = []
      pickerOptions = []
      projectstatusclassoptions = []
      orgObject: any = {}
      ptObject: any = {}
      dtObject: any = {}
      parentItems = []
      childItems = []
      developTypeClass = [
        {
          label: '一级',
          value: '0',
        },
        {
          label: '二级',
          value: '1',
        },
      ]

  public async created() {
    // this.setDevTypeList()
    this.setProjectTypeList()
    this.getOrgInfo()
    this.setProjectStatus()
    this.getAllClass()
  }

  async getAllClass() {
    const params = {
      parent_id: null,
    }
    const typeResult: any = await workhourService.getWorkHourClass(params)
    this.parentItems = _.filter(typeResult.rows, { parent_id: null })
    this.childItems = _.filter(typeResult.rows, function (o) {
      return o.parent_id !== null
    })
    this.setDevTypeList('0')
  }

  setProjectStatus() {
    this.projectstatusclassoptions = [
      {
        value: '0',
        text: '提前',
      },
      {
        value: '1',
        text: '正常',
      },
      {
        value: '2',
        text: '预警',
      },
      {
        value: '3',
        text: '延期',
      },
      {
        value: '4',
        text: '暂停',
      },
      {
        value: '5',
        text: '结项',
        disabled: true,
      },
      {
        value: '6',
        text: '终止',
        disabled: true,
      },
      {
        value: '7',
        text: '暂无',
        disabled: true,
      },
    ]
  }

  setProjectTypeList() {
    this.projectTypeList = project_types
    this.setProjectTypeObj()
  }

  setProjectTypeObj() {
    for (let i = 0; i < this.projectTypeList.length; i++) {
      const ptr = this.projectTypeList[i]
      this.ptObject[ptr.value] = ptr.name
    }
  }

  setDevTypeObj() {
    for (let i = 0; i < this.developTypeList.length; i++) {
      const ptr = this.developTypeList[i]
      this.dtObject[ptr.value] = ptr.name
    }
  }

  public setDevTypeList(type) {
    if (type == '0') {
      this.developTypeList = this.parentItems
    } else {
      this.developTypeList = this.childItems
    }
    this.setDevTypeObj()
    this.queryClick()
  }

  // 查询按钮事件
  public queryClick() {
    this.getWorkhoursReport()
  }

  async getOrgInfo() {
    const result: any = await orgService.getOrgInfo()
    this.orgList = result.rows
    this.setOrgObject(result.rows)
    this.getWorkhoursReport()
  }

  setOrgObject(rows) {
    for (let i = 0; i < rows.length; i++) {
      let row = rows[i]
      this.orgObject[row.code] = row.name
    }
  }

  async getWorkhoursReport() {
    const params = this.queryItem
    params.startDate = this.queryItem.querydate[0]
    params.endDate = this.queryItem.querydate[1]
    const result: any = await workhourService.getWeekWorkHoursReportByProject(
      params
    )
    this.tableData = result.rows
    this.normalrizeData()
  }

  public getSummaries(param) {
    const { columns, data } = param
    const sums = []
    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = '合计'
        return
      }
      if (column.property != 'itemHours') {
        return
      } else {
        const values = data.map((item) => parseFloat(item[column.property]))
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = parseFloat(curr)
            if (!isNaN(value)) {
              const res = (parseFloat(prev) + parseFloat(curr)).toFixed(1)
              return res
            } else {
              return parseFloat(prev).toFixed(1)
            }
          }, 0)
          sums[index] += ''
        } else {
          sums[index] = 'N/A'
        }
      }
    })

    return sums
  }

  normalrizeData() {
    this.contactArray = []
    this.contactArray1 = []
    this.contactArray2 = []
    this.tableData.map((item, index) => {
      if (index === 0) {
        this.contactArray.push(1)
        this.contactDot = 0
        this.contactArray1.push(1)
        this.contactDot1 = 0
      } else {
        if (item.project_code == this.tableData[index - 1].project_code) {
          this.contactArray[this.contactDot] += 1
          this.contactArray.push(0)
        } else {
          this.contactArray.push(1)
          this.contactDot = index
        }
        if (item.project_name == this.tableData[index - 1].project_name) {
          this.contactArray1[this.contactDot1] += 1
          this.contactArray1.push(0)
        } else {
          this.contactArray1.push(1)
          this.contactDot1 = index
        }
      }
    })
  }

  public objectSpanMethod({ row, column, rowIndex, columnIndex }) {
    if (columnIndex === 0) {
      const _row = this.contactArray[rowIndex]
      const _col = _row > 0 ? 1 : 0
      return {
        rowspan: _row,
        colspan: _col,
      }
    }
    if (columnIndex === 1) {
      const _row = this.contactArray1[rowIndex]
      const _col = _row > 0 ? 1 : 0
      return {
        rowspan: _row,
        colspan: _col,
      }
    }
  }

  public handleCurrentChange() {}
}
