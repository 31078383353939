import * as Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import BootstrapVueNext from 'bootstrap-vue-next'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
// import './registerServiceWorker';
import install from './directives/index'
import './element-plus-self.scss'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/dist/locale/zh-cn'
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";

import VueShepherdPlugin from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';

// import 'element-plus/dist/index.css'
const app = Vue.createApp(App)
dayjs.locale("zh-cn");
window.$vueApp = app

declare global {
  interface Window {
    ResizeObserver:any;
  }
}

const debounce = (fn, delay) => {
  let timer = null;
  return function () {
  let context = this;
  let args = arguments;
  clearTimeout(timer);
  timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
      callback = debounce(callback, 16);
      super(callback);
  }
}

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

window.$vueApp.directive('numberOnly', {
  beforeMount(el, binding, vnode) {
    el.handler = function () {
      el.value = el.value.replace(/[^0-9.]/g, '')
      if (el.value === '') {
      }
    }
    el.addEventListener('input', el.handler)
  },
  unMounted(el: any) {
    el.removeEventListener('input', el.handler)
  },
})


//axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')?localStorage.getItem('token'):'';
app.use(BootstrapVueNext)
app.use(ElementPlus, {
  locale: zhCn,
})
app.use(install)
app.use(VueShepherdPlugin)
app.use(router)
app.mount('#app')
app.config.globalProperties.routerAppend = (path, pathToAppend) => {
  return path + (path.endsWith('/') ? '' : '/') + pathToAppend
}
