import * as Vue from 'vue'
import * as VueRouter from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/login.vue'
import jump from './components/jump.vue'
import ChangePwd from './views/changepwd.vue'
import LoginHis from './views/loginhis.vue'

// // 营销分析
// import ProjectAdd from './views/dataanalysis/project/add.vue'
// import ProjectEdit from './views/dataanalysis/project/edit.vue'
// import ProjectList from './views/dataanalysis/project/list.vue'
// import ProjectSummary from './views/dataanalysis/project/summary.vue'
// import IncomeAdd from './views/dataanalysis/income/add.vue'
// import IncomeList from './views/dataanalysis/income/list.vue'
// import IncomeEdit from './views/dataanalysis/income/edit.vue'
// import IncomeReport from './views/dataanalysis/income/report.vue'
// import PaybackAdd from './views/dataanalysis/payback/add.vue'
// import PaybackEdit from './views/dataanalysis/payback/edit.vue'
// import PaybackList from './views/dataanalysis/payback/list.vue'
// import PaybackReport from './views/dataanalysis/payback/report.vue'

// // 图

// import curMonth from './views/dataanalysis/charts/curMonth.vue'
// import untilMonth from './views/dataanalysis/charts/untilMonth.vue'
// import untilMonthPercent from './views/dataanalysis/charts/untilMonthPercent.vue'
// import curMonthAna from './views/dataanalysis/charts/curAnaMonth.vue'
// import untilMonthAna from './views/dataanalysis/charts/untilAnaMonth.vue'
// import yearIncome from './views/dataanalysis/charts/yearIncome.vue'
// import yearPay from './views/dataanalysis/charts/yearPay.vue'
// import curMonRiskAna from './views/dataanalysis/charts/curMonthRiskAna.vue'
// import summary from './views/dataanalysis/charts/summary.vue'

// // 项目管理
// import pmProjectAdd from './views/projectmanager/project/add.vue'
import pmProjectList from './views/projectmanager/project/list.vue'
import pmProjectShow from './views/projectmanager/project/listshow.vue'
// import pmPlanProjectList from './views/projectmanager/project/listplan.vue'
// import pmProjectEdit from './views/projectmanager/project/edit.vue'
// import ProjectMilestone from './views/projectmanager/project/milestone.vue'
// import ProjectCostdown from './views/projectmanager/project/costdown.vue'
import pmWeekshow from './views/projectmanager/project/weekshow.vue'
import rsWeekPlan from './views/projectmanager/weekplan/list.vue'
import rsWeekreport from './views/projectmanager/weekplan/weekreport.vue'
import customer from './views/projectmanager/customer/list.vue'
import pmProjectTotal from './views/projectmanager/project/total.vue'
// import projectProcess from './views/projectmanager/project/projectprocess.vue'
import personWork from './views/projectmanager/project/personwork.vue'
import projectHours from './views/projectmanager/project/projecthours.vue'
import personHours from './views/projectmanager/project/personhours.vue'
import zentaoReport from './views/projectmanager/project/zentaoreport.vue'
import zentaoQualityReport from './views/projectmanager/project/zentaoqualityreport.vue'

// // 跟储单
// import storageList from './views/storageproject/project/list.vue'
// import storageListExport from './views/storageproject/project/list-export.vue'
// import projectReport from './views/storageproject/project/report.vue'
// import projectStruct from './views/storageproject/project/structchange.vue'

// // 应收账款
// import shouldIncome from './views/storageproject/shouldincome/list.vue'

// // 大区分析
// import analyseOrg from './views/storageproject/shouldincome/analyseorg.vue'

// // 客户分析
// import analyseCus from './views/storageproject/shouldincome/analysecustomer.vue'

// // 工时系统
import workHours from './views/workhours/List.vue'
import auditWorkHours from './views/workhours/Audit.vue'
// import workHoursHis from './views/workhours/History.vue'
import product from './views/projectmanager/product/list.vue'
import record from './views/workhours/total.vue'
import simpleRecord from './views/workhours/simpletotal.vue'
import classManage from './views/workhours/classManage.vue'

// 注册
import register from './views/register/register.vue'
import resetpwd from './views/resetpwd.vue'
// //组织
import org from './views/system/org.vue'
// // 人员
import user from './views/system/user.vue'

// // 评价管理
import assessTemplate from './views/assess/template.vue'
import assessList from './views/assess/list.vue'

// // 更新日志
// import UpdateHis from './views/system/updatehis.vue'

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
    },
    {
      path: '/register',
      name: 'register',
      component: register,
    },
    {
      path: '/resetpwd',
      name: 'resetpwd',
      component: resetpwd,
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      redirect: '/pmproject/list',
      children: [
    //     {
    //       path: '/updatehis',
    //       name: 'updatehis',
    //       component: UpdateHis,
    //     },
        {
          path: '/changepwd',
          name: 'changepwd',
          component: ChangePwd,
        },
        {
          path: '/loginhis',
          name: 'loginhis',
          component: LoginHis,
        },
        // {
        //   path: '/project',
        //   name: 'project',
        //   redirect: '/project/list',
        //   component: jump,
        //   children: [
        //     { path: 'list', component: ProjectList },
        //     { path: 'add', component: ProjectAdd },
        //     { path: 'summary', component: ProjectSummary },
        //     { name: 'projectedit', path: 'edit', component: ProjectEdit },
        //   ],
        // },
    //     {
    //       path: '/income',
    //       name: 'income',
    //       redirect: '/income/list',
    //       component: jump,
    //       children: [
    //         { path: 'list', component: IncomeList },
    //         { path: 'add', component: IncomeAdd },
    //         { name: 'incomeedit', path: 'edit', component: IncomeEdit },
    //         { name: 'incomereport', path: 'report', component: IncomeReport },
    //       ],
    //     },
    //     {
    //       path: '/payback',
    //       name: 'payback',
    //       redirect: '/payback/list',
    //       component: jump,
    //       children: [
    //         { path: 'list', component: PaybackList },
    //         { name: 'paybackedit', path: 'edit', component: PaybackEdit },
    //         { path: 'add', component: PaybackAdd },
    //         { name: 'paybackreport', path: 'report', component: PaybackReport },
    //       ],
    //     },
        {
          path: '/pmproject',
          name: 'pmproject',
          redirect: '/pmproject/list',
          component: jump,
          children: [
            { path: 'list', component: pmProjectList },
            // { path: 'planlist', component: pmPlanProjectList },
            { path: 'show', component: pmProjectShow },
            // { path: 'add', component: pmProjectAdd },
            { name: 'weekshowname', path: 'weekshow', component: pmWeekshow },
            // { name: 'pmprojectedit', path: 'edit', component: pmProjectEdit },
            // {
            //   name: 'pmprojectmilestone',
            //   path: 'milestone',
            //   component: ProjectMilestone,
            // },
            // {
            //   name: 'pmprojectcostdown',
            //   path: 'costdown',
            //   component: ProjectCostdown,
            // },
            // // { path: 'rsweekplan', component: rsWeekPlan },
            { path: 'customer', component: customer },
            { path: 'product', component: product },
            { path: 'total', component: pmProjectTotal },
            // { path: 'progress', component: projectProcess },
            { path: 'person', component: personWork },
            { path: 'hoursbyproject', component: projectHours },
            { path: 'hoursbyperson', component: personHours },
            { path: 'zentaoreport', component: zentaoReport },
            { path: 'zentaoqualityreport', component: zentaoQualityReport },
            // // { name: 'projectedit', path: 'edit', component: ProjectEdit },
          ],
        },
        {
          path: '/rsweekplan',
          name: 'rsweekplan',
          redirect: '/rsweekplan/list',
          component: jump,
          children: [
            { path: 'list', component: rsWeekPlan },
            { path: 'week', component: rsWeekreport },
          ],
        },
    //     {
    //       path: '/storage',
    //       name: 'storage',
    //       redirect: '/storage/list',
    //       component: jump,
    //       children: [
    //         { path: 'list', component: storageList },
    //         { path: 'listexport', component: storageListExport },
    //         { path: 'report', component: projectReport },
    //         { path: 'struct', component: projectStruct },
    //       ],
    //     },
    //     {
    //       path: '/charts',
    //       name: 'charts',
    //       redirect: '/charts/curmonth',
    //       component: jump,
    //       children: [
    //         { path: 'curmonth', component: curMonth },
    //         { path: 'untilmonth', component: untilMonth },
    //         { path: 'untilmonthpercent', component: untilMonthPercent },
    //         { path: 'curmonthana', component: curMonthAna },
    //         { path: 'untilmonthana', component: untilMonthAna },
    //         { path: 'yearincome', component: yearIncome },
    //         { path: 'yearpay', component: yearPay },
    //         { path: 'curmonriskana', component: curMonRiskAna },
    //         { path: 'summary', component: summary },
    //       ],
    //     },
    //     {
    //       path: '/shouldincome',
    //       name: 'shouldincome',
    //       redirect: '/shouldincome/list',
    //       component: jump,
    //       children: [{ path: 'list', component: shouldIncome }],
    //     },
    //     {
    //       path: '/analyseorg',
    //       name: 'analyseorg',
    //       redirect: '/analyseorg/list',
    //       component: jump,
    //       children: [{ path: 'list', component: analyseOrg }],
    //     },
    //     {
    //       path: '/analysecus',
    //       name: 'analysecus',
    //       redirect: '/analyseorg/list',
    //       component: jump,
    //       children: [
    //         { path: 'list', component: analyseCus },
    //         { path: 'listagent', component: analyseCus },
    //       ],
    //     },
        {
          path: '/workhours',
          name: 'workhours',
          redirect: '/workhours/list',
          component: jump,
          children: [
            { path: 'list', component: workHours },
            { path: 'audit', component: auditWorkHours },
            { path: 'history', component: record },
            { path: 'simple', component: simpleRecord },
            { path: 'classmanage', component: classManage },
          ],
        },
        {
          path: '/sys',
          name: 'sys',
          redirect: '/sys/org/list',
          component: jump,
          children: [
            { path: 'org/list', component: org },
            { path: 'user/list', component: user },
          ],
        },
        {
          path: '/assess',
          name: 'assess',
          redirect: '/assess/template',
          component: jump,
          children: [
            { path: 'template', component: assessTemplate },
            { path: 'list', component: assessList },
          ],
        },
      ],
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: Vue.defineAsyncComponent(() => import('./views/login.vue')),
    // },
  ],
})

router.beforeEach((to, from, next) => {
  // alert(to.path)
  const pwdreg = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
  if (to.path === '/' || to.path === '/login' || to.path === '/register' || to.path === '/resetpwd') {
    next()
  } else if (!localStorage.getItem('userInfo')) {
    alert('用户未登录')
    next({
      path: '/',
      query: { redirect: to.fullPath },
    })
  } else if (pwdreg.test(localStorage.getItem('success-pwd')) === false && to.path !== '/changepwd') {
    alert('密码强度不符合要求请修改密码')
    next({
      path: '/changepwd',
      query: { redirect: to.fullPath },
    })
  } else if (localStorage.getItem("isOutDateChangePwd") === '1' && to.path !== '/changepwd') {
    alert('密码使用超过90天，请修改密码')
    next({
      path: '/changepwd',
      query: { redirect: to.fullPath },
    })
  } else {
    next()
  }
})

export default router
