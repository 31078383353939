
  import { Vue, Watch } from 'vue-property-decorator'
  import Component from 'vue-class-component'
  import moment from 'moment'
  import * as _ from 'lodash'
  import cyTreeSelect from '../../components/selectTree.vue'
  import workhoursservice from '../../services/workhours/workhours'
  import orgService from '../../services/org/orgservice'
  import buildOrgTree from '../../util'
  
  export default {
    components: {
      cyTreeSelect,
    },
    mounted() {
      //this.loading = true;
      this.init()
    },
    data() {
      const userInfoString = localStorage.getItem('userInfo')
      const userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
      return {
        lodash: _,
        searchWord: '',
        orgCode: '',
        search_type: '0',
        user_type: '0',
        orgList: [],
        setTree: [],
        tableData: [],
        currentPage: 1,
        tableDataLength: 0,
        pageSize: 20,
        loading: false,
        calcHeight: document.documentElement.clientHeight - 60,
        options: [
          {
            value: '1',
            label: '预研',
          },
          {
            value: '2',
            label: '设计',
          },
          {
            value: '3',
            label: '开发',
          },
          {
            value: '4',
            label: '实施',
          },
          {
            value: '5',
            label: '支持',
          },
          {
            value: '6',
            label: '其他',
          },
        ],
        productOptions: '',
        product_code: '',
        project_code: '',
        projectList: [],
        projectNameList: [],
        userList: [],
        login_code: userInfo.username,
        dep_code: userInfo.dep_code,
        date: [
          moment().startOf('month').format('YYYY-MM-DD'),
          moment().endOf('month').format('YYYY-MM-DD'),
        ],
        addDialogVisible: false,
        addTable: [],
        title: '',
      }
    },
  
    methods: {
      // 初始化获取信息
      async init() {
        this.loading = true
        const params = {
          currentPage: this.currentPage,
          pageSize: this.pageSize,
          search_type: this.search_type,
          startDate: this.date ? this.date[0] : this.date,
          endDate: this.date ? this.date[1] : this.date,
          login_code: this.login_code,
          dep_code: this.dep_code,
        }
        const hoursResult: any = await workhoursservice.getWorkHoursRecordSimple(params)
        if (hoursResult.success) {
          this.tableData = hoursResult.rows
          this.tableDataLength = hoursResult.total
        } else {
          this.$message.warning(hoursResult.msg)
        }
        this.loading = false
      },
      // 获取组织信息
      async getOrgInfo() {
        var self = this
        const result: any = await orgService.getOrgInfo()
        this.orgList = _.cloneDeep(result.rows)
        // this.getUserList();
        self.setTree = buildOrgTree(result.rows)
      },
      editProject(row) {
        this.title = '工时详情'
        this.addDialogVisible = true
        this.addTable = []
        this.getWorkHoursRecordItem(row)
      },
      async getWorkHoursRecordItem(row) {
        const params = {
          id: row.id,
          login_code: row.login_code,
          project_code: row.project_code,
          startDate: this.date ? this.date[0] : this.date,
          endDate: this.date ? this.date[1] : this.date,
        }
        const hoursResult: any = await workhoursservice.getWorkHoursRecordItem(
          params
        )
        if (hoursResult.success) {
          this.addTable = hoursResult.rows
        } else {
          this.$message.warning(hoursResult.msg)
        }
      },
  
      handleSizeChange(val) {
        this.pageSize = val
        this.init()
      },
      handlePagenumChange(val) {
        this.currentPage = val
        this.init()
      },
  
      handleCurrentChange(val) {
        this.currentRow = val
      },
  
      // 组织选择方式发生变化
      typechanged(val) {
        this.login_code = ''
        this.user_type = ''
        if (val === '1') {
          this.project_code = ''
        }
      },
  
      // 用户选择方式发生变化
      userchanged(val) {
        this.org_type = ''
        this.orgCode = ''
        if (val === '1') {
          this.project_code = ''
        }
      },
  
      queryClick() {
        (this.currentPage = 1), this.init()
      },
    },
  }
  