
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as _ from 'lodash'
import productservice from '../../../services/projectmanager/productservice'

export default {
  mounted() {
    this.loading = true
    this.init()
  },
  data() {
    return {
      lodash: _,
      tableData: [],
      currentPage: 1,
      tableDataLength: 0,
      pageSize: 15,
      loading: false,
      calcHeight: document.documentElement.clientHeight - 60,
      status: '',
      title: '',
      form: {},
      rules: {
        code: [
          {
            required: true,
            message: '编码不能为空',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '名称不能为空',
            trigger: 'blur',
          },
        ],
      },
      dialogVisible: false,
      code: '',
      name: '',
      total: 0,
    }
  },
  methods: {
    // 初始化获取信息
    async init() {
      this.loading = true
      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        code: this.code,
        name: this.name,
      }
      const result: any = await productservice.getProductList(params)
      if (result.success) {
        this.tableData = result.rows
        this.total = result.total
      } else {
        this.$message.warning(result.msg)
      }
      this.loading = false
    },

    close() {
      this.$refs['form'].clearValidate()
    },

    add() {
      this.status = 'add'
      this.form = {}
      this.title = '添加产品'
      this.dialogVisible = true
    },

    edit(row) {
      this.status = 'edit'
      this.form = row
      this.title = '编辑产品'
      this.dialogVisible = true
    },

    async deleteWorkhours(row) {
      var userInfoString = localStorage.getItem('userInfo')
      var userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
      var params = {
        id: row.id,
      }
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const result: any = await productservice.deleteProduct({
            params,
          })
          if (result.success) {
            this.$message({
              message: '删除成功',
              type: 'success',
            })
            this.init()
          }
        })
        .catch((err) => {
          throw err
        })
    },
    checkRules() {
      var self = this
      this.$refs['form'].validate((valid) => {
        if (valid) {
          self.addOrUpdate()
        } else {
          return false
        }
      })
    },
    async addOrUpdate() {
      if (this.status == 'add') {
        const result: any = await productservice.addtProduct(this.form)
        if (result.success) {
          this.dialogVisible = false
          this.init()
          this.$message.success('添加成功')
        } else {
          this.$message.warning(result.msg)
        }
      } else {
        const result: any = await productservice.updateProduct(this.form)
        if (result.success) {
          this.dialogVisible = false
          this.init()
          this.$message.success('添加成功')
        } else {
          this.$message.warning(result.msg)
        }
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    handlePagenumChange(val) {
      this.currentPage = val
      this.init()
    },

    handleCurrentChange(val) {
      this.currentRow = val
    },

    queryClick() {
      ;(this.currentPage = 1), this.init()
    },
  },
}
