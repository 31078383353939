function copy(item) {
  var _temp = {
    children: [],
  }
  _temp['code'] = item['code']
  for (var _index in item) {
    if (_index != 'code') {
      var _property = item[_index]
      // if (!!this.map && this.map[_index])
      //     _temp[this.map[_index]] = _property;
      _temp[_index] = _property
    }
  }
  return _temp
}

export default function buildOrgTree(data) {
  if (data) {
    var pos = {}
    var tree = []
    var i = 0
    var dataLength = data.length
    while (data.length != 0) {
      if (data[i]['parentcode'] == '*') {
        var _temp = copy(data[i])
        tree.push(_temp)
        pos[data[i]['code']] = [tree.length - 1]
        data.splice(i, 1)
        i--
      } else {
        var posArr = pos[data[i]['parentcode']]
        if (posArr != undefined) {
          var obj = tree[posArr[0]]
          for (var j = 1; j < posArr.length; j++) {
            obj = obj.children[posArr[j]]
          }
          var _temp = copy(data[i])
          obj.children.push(_temp)
          pos[data[i]['code']] = posArr.concat([obj.children.length - 1])
          data.splice(i, 1)
          i--
        }
      }
      i++
      if (i > data.length - 1) {
        i = 0
        if (data.length == dataLength) {
          break
        } else {
          dataLength = data.length
        }
      }
    }
    return tree
  }
}

const project_types = [
  {
    value: '14',
    text: '公共项目',
  },
  {
    value: '15',
    text: '请假',
  },
  {
    value: '16',
    text: '非研发',
  },
  {
    value: '17',
    text: '部品引入',
  },
  {
    value: '18',
    text: '新品预研',
  },
  {
    value: '19',
    text: '设计变更',
  },
  {
    value: '20',
    text: '国内特配',
  },
  {
    value: '21',
    text: '国际特配',
  },
  {
    value: '22',
    text: '国内质量',
  },
  {
    value: '23',
    text: '国际质量',
  },
  {
    value: '24',
    text: '国内软件',
  },
  {
    value: '25',
    text: '国际软件',
  },
  {
    value: '5',
    text: '新品项目',
  },
  {
    value: '6',
    text: '交付项目',
  },
  {
    value: '7',
    text: '完善项目',
  },
  {
    value: '8',
    text: '支持项目',
  },
  {
    value: '9',
    text: '预研项目',
  },
  {
    value: '10',
    text: '规划项目',
  },
  {
    value: '11',
    text: '管理项目',
  },
  {
    value: '12',
    text: '实施项目',
  },
  {
    value: '13',
    text: '研发内部',
  },
  {
    value: '0',
    text: '降成本',
  },
  {
    value: '1',
    text: '新品',
  },
  {
    value: '2',
    text: '资源项目',
  },
]

export { project_types }

export function getDate() {}
