import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../config/index'

const apiPath = {
  getupdatehis: '/getupdatehis',
  addUpdateHis: '/addUpdateHis',
}

async function getUpdateHis(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.getupdatehis, param)
  return result.data
}

async function addUpdateHis(param: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.addUpdateHis, param)
  return result.data
}

export default {
  getUpdateHis,
  addUpdateHis,
}
