import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "panel-body org-info" }
const _hoisted_2 = { class: "full-height tree-area" }
const _hoisted_3 = { class: "slot-t-node" }
const _hoisted_4 = { class: "slot-t-icons" }
const _hoisted_5 = { class: "full-height materiel-area" }
const _hoisted_6 = { class: "dialog-footer" }
const _hoisted_7 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_el_row, { class: "full-height" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_tree, {
            ref: "SlotMenuList",
            class: "expand-tree",
            "node-key": "code",
            "highlight-current": "",
            data: $data.setTree,
            props: $data.defaultProps,
            onNodeClick: $options.nodeClick,
            "expand-on-click-node": true,
            "default-expanded-keys": ['001']
          }, {
            default: _withCtx(({ node, data }) => [
              _createElementVNode("span", _hoisted_3, [
                _createElementVNode("span", null, [
                  _createElementVNode("span", null, _toDisplayString(node.label), 1),
                  _createElementVNode("span", _hoisted_4, [
                    _createVNode(_component_el_button, {
                      icon: $data.ElIconPlus,
                      circle: "",
                      size: $data.iconSize,
                      type: "success",
                      onClick: _withModifiers(($event: any) => ($options.addOrg(node, data)), ["stop"])
                    }, null, 8, ["icon", "size", "onClick"]),
                    (node.data.code != '001')
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          icon: $data.ElIconMinus,
                          circle: "",
                          size: $data.iconSize,
                          type: "danger",
                          onClick: _withModifiers(($event: any) => ($options.deleteOrg(node, data)), ["stop"]),
                          style: {"right":"45px"}
                        }, null, 8, ["icon", "size", "onClick"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["data", "props", "onNodeClick"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_table, {
            data: $data.userList,
            border: "",
            height: "calc(100% - 40px)"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                prop: "code",
                label: "用户账户"
              }),
              _createVNode(_component_el_table_column, {
                prop: "name",
                label: "用户名称"
              }),
              _createVNode(_component_el_table_column, {
                prop: "dep_code",
                label: "组织"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.dep_code &&
                $data.lodash.find($data.orgList, { code: scope.row.dep_code })
                  ? $data.lodash.find($data.orgList, { code: scope.row.dep_code }).name
                  : scope.row.dep_code), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                prop: "pro_rank",
                label: "职级"
              }),
              _createVNode(_component_el_table_column, {
                prop: "pro_title",
                label: "职称"
              }),
              _createVNode(_component_el_table_column, {
                label: "操作",
                width: "90"
              }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_el_button, {
                    size: "mini",
                    type: "text",
                    onClick: ($event: any) => ($options.updateUser(scope.row))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("编辑")
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"]),
          _createVNode(_component_el_pagination, {
            style: {"margin-bottom":"8px"},
            class: "position-right",
            onCurrentChange: $options.handleCurrentChange,
            "current-page": $data.currentPage,
            "page-size": $data.pageSize,
            layout: "total, prev, pager, next, jumper",
            total: $data.total
          }, null, 8, ["onCurrentChange", "current-page", "page-size", "total"])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_el_dialog, {
      title: $data.title,
      modelValue: $data.dialogVisible,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.dialogVisible) = $event)),
      width: "45%",
      onClose: $options.close,
      top: "5vh"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_6, [
          _createVNode(_component_el_button, {
            onClick: _cache[3] || (_cache[3] = ($event: any) => ($data.dialogVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取 消")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $options.updateOrAdd
          }, {
            default: _withCtx(() => [
              _createTextVNode("确 定")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "form",
          model: $data.form,
          "label-width": "80px",
          rules: $data.rules,
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "用户名称",
              prop: "code"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $data.form.code,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.code) = $event)),
                  placeholder: "请选择",
                  style: {"width":"100%"},
                  disabled: $data.status == 'edit',
                  filterable: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.allUser, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.code,
                        label: item.name,
                        value: item.code
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "组织",
              prop: "dep_code"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $data.form.dep_code,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.dep_code) = $event)),
                  placeholder: "请选择",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.orgList, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.code,
                        label: item.name,
                        value: item.code
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["title", "modelValue", "onClose"]),
    _createVNode(_component_el_dialog, {
      title: "添加组织",
      modelValue: $data.orgDialogVisible,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($data.orgDialogVisible) = $event)),
      width: "45%",
      onClose: $options.orgClose,
      top: "5vh"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_7, [
          _createVNode(_component_el_button, {
            onClick: _cache[8] || (_cache[8] = ($event: any) => ($data.orgDialogVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取 消")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $options.confirmAddOrg
          }, {
            default: _withCtx(() => [
              _createTextVNode("确 定")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "orgForm",
          model: $data.orgForm,
          "label-width": "80px",
          rules: $data.orgRules,
          onSubmit: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "上级组织",
                  prop: "parentcode"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: $data.orgParentcode,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.orgParentcode) = $event)),
                      placeholder: "请选择",
                      style: {"width":"100%"},
                      disabled: true
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.orgList, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.code,
                            label: item.name,
                            value: item.code
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "组织名称",
                  prop: "name"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: $data.orgForm.name,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.orgForm.name) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["modelValue", "onClose"])
  ]))
}