
import { Component, Vue } from 'vue-facing-decorator'
import loginService from '../services/login'

@Component
export default class Loginhis extends Vue {

  public created() {
    this.queryHis()
  }

      currentPage = 1
      pageSize = 10
      loading = true
      calcHeight = document.documentElement.clientHeight - 60
      tableDataLength = 10
      username = ''
      datetime = ''
      items = []

  async queryHis() {
    const param: any = {
      currentPage: this.currentPage,
      pageSize: this.pageSize,
    }
    if (this.username) {
      param.username = this.username
    }
    if (this.datetime) {
      param.startDate = this.datetime[0]
      param.endDate = this.datetime[1]
    }
    const result: any = await loginService.getLoginhis(param)
    if (result.success) {
      this.loading = false
      this.items = result.rows
      this.tableDataLength = result.total
    }
  }

  queryNow() {
    this.queryHis()
  }

  handleSizeChange(val) {
    this.pageSize = val
    this.queryHis()
  }

  handlePagenumChange(val) {
    debugger
    this.currentPage = val
    this.queryHis()
  }
}
