
import { Component, Vue, Watch } from 'vue-facing-decorator'
import moment from 'moment'
import * as _ from 'lodash'
import updateService from '../../services/updatelogservice'

@Component
export default class UpdateHis extends Vue {

  userRoleString = localStorage.getItem('userRole')
  userRole = this.userRoleString !== null ? JSON.parse(this.userRoleString) : {}
  role = this.userRole[0].role_id
  dialogVisible = false
  updatelogs = {}
  addForm = {
    date: moment().format('YYYY-MM-DD'),
    content: '',
  }

  public created() {
    this.getUpdateLogs()
  }

  public async getUpdateLogs() {
    const result: any = await updateService.getUpdateHis({})
    for (let i = 0; i < result.rows.length; i++) {
      const item = result.rows[i]
      item.logs = item.content.split('\n')
    }
    this.updatelogs = result.rows
  }

  public addNew() {
    this.dialogVisible = true
  }

  public async save() {
    const result: any = await updateService.addUpdateHis(this.addForm)
    if (result.success) {
      this.dialogVisible = false
      this.getUpdateLogs()
    }
  }
}
