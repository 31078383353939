import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle('height:' + $data.calcHeight + 'px;overflow-y: auto')
  }, [
    _createVNode(_component_el_form, {
      model: $data.regForm,
      rules: $data.rules,
      ref: "regForm",
      "label-width": "100px",
      class: "form",
      "label-suffix": "："
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "用户账号",
          prop: "code"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $data.regForm.code,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.regForm.code) = $event)),
              "auto-complete": "off",
              placeholder: "名字拼音"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "用户姓名",
          prop: "user"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $data.regForm.user,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.regForm.user) = $event)),
              "auto-complete": "off",
              placeholder: "中文名字"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "密码",
          prop: "password"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              type: "password",
              modelValue: $data.regForm.password,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.regForm.password) = $event)),
              "auto-complete": "off",
              name: "fakeusernameremembered"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "确认密码",
          prop: "checkPass"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              type: "password",
              modelValue: $data.regForm.checkPass,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.regForm.checkPass) = $event)),
              "auto-complete": "off",
              name: "fakeusernameremembered"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "组织",
          prop: "dep"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: $data.regForm.dep,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.regForm.dep) = $event)),
              filterable: "",
              placeholder: "请选择组织",
              clearable: "",
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.depList, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.code,
                    label: item.name,
                    value: item.code
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "职级",
          prop: "pro_rank"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $data.regForm.pro_rank,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.regForm.pro_rank) = $event)),
              placeholder: "T14、T13..."
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "职称",
          prop: "pro_title"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $data.regForm.pro_title,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.regForm.pro_title) = $event)),
              placeholder: "主管、副主任..."
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "审核人",
          prop: "audit"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: $data.regForm.audit,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($data.regForm.audit) = $event)),
              filterable: "",
              placeholder: "请选择审核人员",
              clearable: "",
              "allow-create": "",
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.userList, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.code,
                    label: item.name,
                    value: item.code
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "邮箱",
          prop: "email"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $data.regForm.email,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($data.regForm.email) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "手机号",
          prop: "phone"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: $data.regForm.phone,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($data.regForm.phone) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          style: {"margin-left":"100px"},
          onClick: $options.reg
        }, {
          default: _withCtx(() => [
            _createTextVNode("注册")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_el_button, {
          style: {"margin-left":"100px"},
          onClick: $options.cancel
        }, {
          default: _withCtx(() => [
            _createTextVNode("取消")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ], 4))
}