import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../../config/index'

const apiPath = {
  weekplan: '/mpapi/rsweekplan',
  rsweekplanexcel: '/mpapi/rsweekplanexcel',
  getweekplan: '/mpapi/getrsweekplan',
  projectDesc: '/mpapi/rsweekplanprodesc',
  lastweekDes: '/mpapi/lastweekplan',
}

async function getWeekplan(param: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.getweekplan, param)
  return result.data
}

function getWeekplanExcel(param: string, cb) {
  window.open(config.url + apiPath.rsweekplanexcel + param)
  // axios.get(config.url + apiPath.rsweekplanexcel + param)
  //   .then((response) => {
  //     cb(response);
  // });
}

async function updateWeekplan(param: object): Promise<AxiosResponse> {
  const result = await axios.put(config.url + apiPath.weekplan, param)
  return result.data
}

async function updateProjectDesc(param: object): Promise<AxiosResponse> {
  const result = await axios.put(config.url + apiPath.projectDesc, param)
  return result.data
}

async function addWeekplan(param: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.weekplan, param)
  return result.data
}

async function deleteWeekplan(param: object): Promise<AxiosResponse> {
  const result = await axios.delete(config.url + apiPath.weekplan, param)
  return result.data
}

async function getLastWeekDes(param: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.lastweekDes, param)
  return result.data
}

export default {
  getWeekplan,
  updateWeekplan,
  deleteWeekplan,
  addWeekplan,
  updateProjectDesc,
  getWeekplanExcel,
  getLastWeekDes,
}
