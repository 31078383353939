
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import * as _ from 'lodash'
  import workhoursservice from '../../../services/workhours/workhours'
  import customerservice from '../../../services/projectmanager/customerservice'
  import projectService from '../../../services/projectmanager/projectservice'
  import productservice from '../../../services/projectmanager/productservice'
  import loginService from '../../../services/login'
  import orgService from '../../../services/org/orgservice'
  import { project_types }  from '../../../util'
  import moment from 'moment'
  export default {
    mounted() {
      this.loading = true
      const userInfoString = localStorage.getItem('userInfo')
      const userRoleString = localStorage.getItem('userRole')
      const userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
      const userRole = userRoleString !== null ? JSON.parse(userRoleString) : {}
      this.audit = userInfo['username']
      this.role = userRole[0]['role_id']
      this.role == 'admin' ? (this.audit = '') : ''
      // 赋予默认值
      //userInfo['dep_code'].length < 6 ? (this.dep_code = userInfo['dep_code']) : (this.dep_code = userInfo['dep_code'].slice(0,6))
    //   this.init()
      this.getOrgInfo()
      this.getCusList()
      // this.getProduct();
      //this.getAllUser();
    },
    data() {
      return {
        lodash: _,
        searchWord: '',
        customer_name: '',
        tableData: [],
        percentState: false,
        currentPage: 1,
        tableDataLength: 0,
        pageSize: 20,
        loading: false,
        calcHeight: document.documentElement.clientHeight - 60,
        dialogVisible: false,
        cusList: [],
        userList: [],
        projectstatusclassoptions: [
          {
            value: '999',
            text: '全部',
          },
          {
            value: '0',
            text: '提前',
          },
          {
            value: '1',
            text: '正常',
          },
          {
            value: '2',
            text: '预警',
          },
          {
            value: '3',
            text: '延期',
          },
          {
            value: '4',
            text: '暂停',
          },
          {
            value: '5',
            text: '结项',
            disabled: true,
          },
          {
            value: '6',
            text: '终止',
            disabled: true,
          },
          {
            value: '7',
            text: '暂无',
            disabled: true,
          },
        ],
        role: '',
        productOptions: '',
        orgList: [],
        dep_code: '001',
        product_code: '',
        project_status: '1',
        date: '',
        showData: [],
        table12: [],
        table9: [],
        table6: [],
        projectList: [],
        row: '',
        dialogLoading: false,
        filtersFlag: true,
        total: 0,
        date1: [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD')
        ],
        projecttypeoptions: project_types,
      }
    },
  
    methods: {
        // 获取客户信息
        async getCusList() {
            const params = {
                currentPage: 1,
                pageSize: 1000,
            }
            const result: any = await customerservice.getCustomerList(params)
            if (result.success) {
              this.cusList = result.rows
            }
        },
      filterProStatus(filters) {
        for (const key in filters) {
          if (key == 'project_status') {
            this.filtersFlag = false
          }
          // if (key) {
          //     this.queryItem[key] =
          //         filters[key].length === 0 ? undefined : filters[key];
          // }
          this.project_status = filters[key]
        }
        // this.init()
      },
      async getProjects() {
        var arr = []
        let param = {
          dep_code: this.dep_code,
          project_code: this.searchWord,
          customer_name: this.customer_name,
          hoursStartDate: this.date1 ? this.date1[0] : this.date1,
          hoursEndDate: this.date1 ? this.date1[1] : this.date1,
        }
        // if (this.filtersFlag == true) {
        //     param["project_status"] = JSON.stringify(["0", "1", "2"]);
        // } else {
        //     param["project_status"] = JSON.stringify(this.project_status);
        // }
        this.dialogLoading = true
  
        const result: any = await projectService.getProjectHours({
          params: param,
        })
  
        this.dialogLoading = false
        if (result.success) {
          this.projectList = result.rows
          this.total = result.total
        } else {
          this.$message.warning(result.msg)
        }
      },
      async exportProjects() {
        var arr = []
        arr.push(this.row.project_status)
        let param = {
          project_manager: this.searchWord,
          dep_code: this.row.dep_code,
          startDate: this.date ? this.date[0] : '',
          endDate: this.date ? this.date[1] : '',
          hoursStartDate: this.date1 ? this.date1[0] : '',
          hoursEndDate: this.date1 ? this.date1[1] : '',
        }
        // if (this.filtersFlag == true) {
        //     param["project_status"] = JSON.stringify(["0", "1", "2"]);
        // } else {
        //     param["project_status"] = JSON.stringify(this.project_status);
        // }
        param['project_status'] = JSON.stringify(arr)
        var url = '?' + this.objToStr(param)
        const result: any = await projectService.projectHoursExcel(url, (res) => {
          const blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
          })
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) //创建下载的链接
          downloadElement.href = href
          downloadElement.download = 'xxx.xlsx' //下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() //点击下载
          document.body.removeChild(downloadElement) //下载完成移除元素
          window.URL.revokeObjectURL(href) //释放掉blob对象
        })
      },
      objToStr(obj) {
        let paramArr = Object.keys(obj).reduce((acc, cur) => {
          acc.push(cur + '=' + obj[cur])
          return acc
        }, [])
        return paramArr.join('&')
      },
      async getOrgInfo() {
        const result: any = await orgService.getOrgInfo()
        if (result.success) {
          this.orgList = result.rows
        } else {
          this.$message.warning(result.msg)
        }
      },
      // async getProduct() {
      //     const params = {
      //         currentPage: 1,
      //         pageSize: 300
      //     };
      //     const result: any = await productservice.getProductList(params);
      //     if (result.success) {
      //         this.productOptions = result.rows;
      //     } else {
      //         this.$message.warning(result.msg);
      //     }
      // },
  
      async getAllUser() {
        const items: any = await workhoursservice.getAllUser({})
        if (items.success) {
          var temp = items.rows
          for (var i = 0; i < temp.length; i++) {
            var obj = {}
            obj['value'] = temp[i].code
            obj['name'] = temp[i].name
            this.userList.push(obj)
          }
        } else {
          this.$message.warning(items.msg)
        }
      },
  
    //   handleSizeChange(val) {
    //     this.pageSize = val
        // this.init()
    //   },
    //   handlePagenumChange(val) {
    //     this.currentPage = val
    //     this.getProjects()
    //   },
  
    //   queryClick() {
        // this.init()
    //   },
      search() {
        ;(this.currentPage = 1), this.getProjects()
      },
    },
  }
  