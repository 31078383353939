
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as _ from 'lodash'
import workhoursservice from '../../services/workhours/workhours'
import assessservice from '../../services/assess/assessservice'

export default {
  mounted() {
    //this.loading = true;

    const userRoleString = localStorage.getItem('userRole')
    this.userRole = userRoleString !== null ? JSON.parse(userRoleString) : {}
    const userInfoString = localStorage.getItem('userInfo')
    const userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
    this.create_user = userInfo['username']
    if (
      this.create_user == 'zhaijianguang' ||
      this.create_user == 'xiaojing' ||
      this.create_user == 'zhaoweihua' ||
      this.create_user == 'dengdehong'
    ) {
      this.create_user = ''
    }
    this.getTemplateItem()
    this.init()
  },
  data() {
    return {
      userRole: '',
      lodash: _,
      form: {},
      tableData: [],
      currentPage: 1,
      tableDataLength: 0,
      pageSize: 15,
      loading: false,
      calcHeight: document.documentElement.clientHeight - 60,
      dialogVisible: false,
      dimension: '',
      name: '',
      type: '',
      total: 0,
      options: [
        {
          value: '0',
          label: '月度',
        },
        {
          value: '1',
          label: '季度',
        },
        {
          value: '2',
          label: '年度',
        },
      ],
      typeOptions: [
        {
          value: '1',
          label: '总监',
        },
        {
          value: '2',
          label: '所长',
        },
        {
          value: '3',
          label: '专家',
        },
        {
          value: '4',
          label: '项目经理',
        },
        {
          value: '5',
          label: '员工',
        },
      ],
      currentPageItem: 1,
      totalItem: 0,
      pageSizeItem: 15,
      itemTableData: [],
      curRow: '',
      templateItems: [],
      lableArr: [],
      create_user: '',
    }
  },
  methods: {
    async getTemplateItem(row) {
      const result: any = await assessservice.getTemplateItem({})
      if (result.success) {
        this.templateItems = result.rows
      } else {
        this.$message.warning(result.msg)
      }
    },
    // 初始化获取信息
    async init() {
      this.loading = true

      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        dimension: this.dimension,
        create_user: this.create_user,
        name: this.name,
        type: this.type,
      }
      var userInfoString = localStorage.getItem('userInfo')
      var userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
      if (this.userRole[0].role_id != 'admin') {
        params['create_user'] = userInfo['username']
      }
      const result: any = await assessservice.getRecord(params)
      if (result.success) {
        this.tableData = result.rows
        this.total = result.total
      } else {
        this.$message.warning(result.msg)
      }
      this.loading = false
    },
    async getRecordItem(row) {
      this.curRow = row
      this.lodash.find(this.options, { value: this.curRow.dimension })
      this.lableArr = []
      if (this.templateItems.length > 0) {
        for (var i = 0; i < this.templateItems.length; i++) {
          if (this.templateItems[i]['item_code'][0] == this.curRow.type) {
            this.lableArr.push(this.templateItems[i])
          }
        }
      }
      const params = {
        id: row.id,
        currentPage: this.currentPageItem,
        pageSize: this.pageSizeItem,
      }
      const result: any = await assessservice.getRecordItem(params)
      if (result.success) {
        this.dialogVisible = true
        this.itemTableData = result.rows
        this.totalItem = result.total
      } else {
        this.$message.warning(result.msg)
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    handlePagenumChange(val) {
      this.currentPage = val
      this.init()
    },

    queryClick() {
      ;(this.currentPage = 1), this.init()
    },
  },
}
