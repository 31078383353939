
import { Component, Vue, Watch } from 'vue-facing-decorator'
import orgService from '../../../services/org/orgservice'
import workhourService from '../../../services/workhours/workhours'
import moment from 'moment'
import * as _ from 'lodash'

@Component
export default class PernalWork extends Vue {

      userInfoString = localStorage.getItem('userInfo')
      userInfo = this.userInfoString !== null ? JSON.parse(this.userInfoString) : {}
      lodash = _
      calcHeight = document.documentElement.clientHeight - 60
      querydate = []
      user = this.userInfo.username
      zentaoProjects = []
      queryItem: any = {
        // user: userInfo.username,
        splitName: '',
        projects: ['739', '742'],
        openedDate: ['2023-03-01', moment().format('YYYY-MM-DD')],
        status: [],
        // user_dep: '',
      }
      orgList = []
      users = []
      projectusers = []
      tableData = []
      statusOptions: any = {
        wait: '未开始',
        doing: '进行中',
        done: '已完成',
        pause: '已暂停',
        cancel: '已取消',
        closed: '已关闭',
      }
      typeoption: any = {
        design: '设计',
        devel: '开发',
        test: '测试',
        study: '研究',
        discuss: '讨论',
        ui: '界面',
        affair: '事务',
        misc: '其他',
      }
      currentPage = 1
      tableDataLength = 0
      pageSize = 20

  public async created() {
    // this.setDevTypeList()
    this.getZentaoUsers()
    this.getZentaoProjects()
    this.getOrgInfo()
    this.getModules()
    this.getProjectUsers()
  }

  public async mounted() {}

  async getOrgInfo() {
    const result: any = await orgService.getOrgInfo()
    this.orgList = result.rows
  }

  async getModules() {
    const result: any = await workhourService.getZentaoModules({})
    this.modules = result.rows
  }

  remakeData(rows: any) {
    for (let i = 0; i < rows.length; i++) {
      const item = rows[i]
      const nameArray = item.name.split('/')
      if (nameArray.length >= 3) {
        item.product_class = nameArray[0]
        item.product_type = nameArray[1]
        item.name = nameArray[2]
      }
    }
  }

  public async getTasks(page?) {
    this.currentPage = page || this.currentPage
    let params = _.cloneDeep(this.queryItem)
    params.pageSize = this.pageSize
    params.currentPage = this.currentPage
    params.users = this.queryItem.user_dep ? this.projectusers : []
    const result: any = await workhourService.getZentaoTasks(params)
    this.remakeData(result.rows)
    this.tableData = result.rows
    this.tableDataLength = result.total
  }

  public async exportExcel(page?) {
    this.currentPage = page || this.currentPage
    let params = _.cloneDeep(this.queryItem)
    params.pageSize = this.pageSize
    params.currentPage = this.currentPage
    params.users = this.queryItem.user_dep ? this.projectusers : []
    const result: any = await workhourService.zentaoQualityExcel(params)
    const blob = new Blob([result])
    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob) //创建下载的链接
    downloadElement.href = href
    downloadElement.download = new Date().getTime() + '.xlsx' //下载后文件名
    document.body.appendChild(downloadElement)
    downloadElement.click() //点击下载
    document.body.removeChild(downloadElement) //下载完成移除元素
    window.URL.revokeObjectURL(href) //释放掉blob对象
  }

  async getZentaoUsers() {
    const result: any = await workhourService.getZentaoUsers({})
    this.users = result.rows
  }

  async getZentaoProjects() {
    const result: any = await workhourService.getZentaoProjects({})
    this.zentaoProjects = result.rows
  }

  public clearDep() {
    this.queryItem.user_dep = ''
  }
  public clearUser() {
    this.queryItem.use = ''
    this.getProjectUsers()
  }

  async getProjectUsers() {
    const params = {
      dep_code: this.queryItem.user_dep,
    }
    const items: any = await workhourService.getAllUser(params)
    let users = []
    for (let i = 0; i < items.rows.length; i++) {
      users.push(items.rows[i].code)
    }
    this.projectusers = users
    this.getTasks()
    // this.exportExcel()
  }

  public handleSizeChange(val) {
    this.pageSize = val
    this.getTasks()
  }
  public handlePagenumChange(val) {
    this.currentPage = val
    this.getTasks()
  }
}
