import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../../config/index'

const apiPath = {
  workhoursList: '/workhours/list',
  auditWorkhoursList: '/workhours/auditlist',
  workhoursListItem: '/workhours/list/item',
  users: '/getUsers',
  auditWorkHours: '/workhours/audit',
  unAuditWorkHours: '/workhours/unaudit',
  workhoursExcel: '/workhours/excel',
  workhoursPersonExcel: '/workhours/personalexcel',
  workhoursHistory: '/workhours/history',
  lastTimeWork: '/workhours/lasttime',
  personalexcel: '/workhours/personalitem',
  getWorkHoursRecord: '/workhours/records',
  getWorkHoursRecordSimple: '/workhours/recordsSimple',
  excelRecords: '/workhours/recordexcel',
  excelRecordsTotal: '/workhours/recordexcel/total',
  projectMem: '/getProMem',
  getWorkHoursRecordItem: '/workhours/records/detail',
  getWeekWorkHours: '/workhours/week',
  getWeekWorkHoursReport: '/workhours/reportForm',
  getWeekWorkHoursReportByProject: '/workhours/reportFormByProject',
  getWeekWorkHoursReportByPerson: '/workhours/reportFormByPerson',
  getWorkHoursExcel: '/workhours/reportFormExcel',
  getWorkHourType: '/workhours/worktype',
  zentaoTasks: '/workhours/zentaotask',
  zentaoUsers: '/workhours/zentaouser',
  zentaoProjects: '/workhours/zentaoproject',
  zentaoModules: '/workhours/zentaomodules',
  zentaoQualityExcel: '/workhours/ztqulitytaskexport',
}

function workhoursExcelRecords(param: string, cb) {
  window.open(config.url + apiPath.excelRecords + param)
}
function workhoursExcelRecordsTotal(param: string, cb) {
  window.open(config.url + apiPath.excelRecordsTotal + param)
}
function workhoursExcel(param: string, cb) {
  window.open(config.url + apiPath.workhoursExcel + param)
}
function workhoursPersonExcel(param: string, cb) {
  window.open(config.url + apiPath.personalexcel + param)
}
function workhoursTotalExcel(param: string, cb) {
  window.open(config.url + apiPath.workhoursPersonExcel + param)
}
async function getWorkHoursRecord(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.getWorkHoursRecord, {
    params: params,
  })
  return result.data
}
async function getWorkHoursRecordSimple(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.getWorkHoursRecordSimple, {
    params: params,
  })
  return result.data
}
async function getWorkHourshistory(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.workhoursHistory, {
    params: params,
  })
  return result.data
}
async function getWorkhoursList(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.workhoursList, {
    params: params,
  })
  return result.data
}
async function getAuditWorkhoursList(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.auditWorkhoursList, {
    params: params,
  })
  return result.data
}
async function getWorkHoursDetail(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.workhoursListItem, {
    params: params,
  })
  return result.data
}
async function getWeekWorkHours(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.getWeekWorkHours, {
    params: params,
  })
  return result.data
}
async function addWorkhousrItem(params: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.workhoursList, params)
  return result.data
}
async function deleteWorkhousrItem(params: object): Promise<AxiosResponse> {
  const result = await axios.delete(config.url + apiPath.workhoursList, params)
  return result.data
}
async function updateWorkHours(params: object): Promise<AxiosResponse> {
  const result = await axios.put(config.url + apiPath.workhoursList, params)
  return result.data
}

async function getAllUser(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.users, { params: params })
  return result.data
}
async function getProjectMem(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.projectMem, {
    params: params,
  })
  return result.data
}

async function auditWorkHours(params: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.auditWorkHours, params)
  return result.data
}
async function unAuditWorkHours(params: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.unAuditWorkHours, params)
  return result.data
}
async function getLastTimeWork(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.lastTimeWork, params)
  return result.data
}
async function getWorkHoursRecordItem(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.getWorkHoursRecordItem, {
    params: params,
  })
  return result.data
}
async function getWeekWorkHoursReport(params: object): Promise<AxiosResponse> {
  const result = await axios.post(
    config.url + apiPath.getWeekWorkHoursReport,
    params
  )
  return result.data
}

async function getWeekWorkHoursReportByProject(
  params: object
): Promise<AxiosResponse> {
  const result = await axios.post(
    config.url + apiPath.getWeekWorkHoursReportByProject,
    params
  )
  return result.data
}
async function getWeekWorkHoursReportByPerson(
  params: object
): Promise<AxiosResponse> {
  const result = await axios.post(
    config.url + apiPath.getWeekWorkHoursReportByPerson,
    params
  )
  return result.data
}

async function getWorkHoursExcel(param: string, cb) {
  window.open(config.url + apiPath.getWorkHoursExcel + param)
}

async function getWorkHourClass(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.getWorkHourType, {
    params: params,
  })
  return result.data
}

async function addWorkHourClass(params: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.getWorkHourType, params)
  return result.data
}

async function deleteWorkHourClass(params: object): Promise<AxiosResponse> {
  const result = await axios.delete(
    config.url + apiPath.getWorkHourType,
    params
  )
  return result.data
}

async function updateWorkHourClass(params: object): Promise<AxiosResponse> {
  const result = await axios.put(config.url + apiPath.getWorkHourType, params)
  return result.data
}

async function getZentaoTasks(params: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.zentaoTasks, params)
  return result.data
}

async function getZentaoUsers(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.zentaoUsers, {
    params: params,
  })
  return result.data
}

async function getZentaoProjects(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.zentaoProjects, {
    params: params,
  })
  return result.data
}

async function getZentaoModules(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.zentaoModules, {
    params: params,
  })
  return result.data
}

async function zentaoQualityExcel(params: object): Promise<AxiosResponse> {
  const result = await axios.post(
    config.url + apiPath.zentaoQualityExcel,
    params,
    { responseType: 'arraybuffer' }
  )
  return result.data
}

// function zentaoQualityExcel(param: string, cb) {
//   window.open(config.url + apiPath.zentaoQualityExcel + param)
// }

export default {
  getWeekWorkHours,
  getWorkhoursList,
  getAuditWorkhoursList,
  addWorkhousrItem,
  deleteWorkhousrItem,
  getWorkHoursDetail,
  updateWorkHours,
  getAllUser,
  auditWorkHours,
  unAuditWorkHours,
  workhoursExcel,
  getWorkHourshistory,
  workhoursPersonExcel,
  getLastTimeWork,
  workhoursTotalExcel,
  getWorkHoursRecord,
  getWorkHoursRecordSimple,
  workhoursExcelRecords,
  workhoursExcelRecordsTotal,
  getProjectMem,
  getWorkHoursRecordItem,
  getWeekWorkHoursReport,
  getWeekWorkHoursReportByProject,
  getWeekWorkHoursReportByPerson,
  getWorkHoursExcel,
  getWorkHourClass,
  addWorkHourClass,
  deleteWorkHourClass,
  updateWorkHourClass,
  getZentaoTasks,
  getZentaoUsers,
  getZentaoProjects,
  getZentaoModules,
  zentaoQualityExcel,
}
