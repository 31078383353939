
import { Component, Vue, Watch } from 'vue-facing-decorator'
import orgService from '../../../services/org/orgservice'
import workhourService from '../../../services/workhours/workhours'
import { project_types }  from '../../../util'
import moment from 'moment'
import * as _ from 'lodash'

@Component
export default class PernalWork extends Vue {
      userInfoString = localStorage.getItem('userInfo')
      userInfo = this.userInfoString !== null ? JSON.parse(this.userInfoString) : {}
      calcHeight = document.documentElement.clientHeight - 60
      querydate =  []
      queryItem: any = {
        querydate: [
          moment().startOf('months').format('YYYY-MM-DD'),
          moment().endOf('months').format('YYYY-MM-DD'),
        ],
        pro_dep: this.userInfo.dep_code,
        user_dep: this.userInfo.dep_code,
        project_type: '',
        class: '',
        class_type: '0',
        project_name: '',
        product_name: '',
        financial_type: '0',
      }
      orgList = []
      projectTypeList = []
      developTypeList = []
      developTypeClass = [
        {
          label: '一级',
          value: '0',
        },
        {
          label: '二级',
          value: '1',
        },
      ]
      parentItems = []
      childItems = []
      tableData = []
      pickerOptions = []
      orgObject: any = {}
      ptObject: any = {}
      dtObject: any = {}

  public async created() {
    //
    this.setProjectTypeList()
    this.getOrgInfo()
    this.getAllClass()
  }

  setProjectTypeList() {
    this.projectTypeList = project_types
    this.setProjectTypeObj()
  }

  async getAllClass() {
    const params = {
      parent_id: null,
    }
    const typeResult: any = await workhourService.getWorkHourClass(params)
    this.parentItems = _.filter(typeResult.rows, { parent_id: null })
    this.childItems = _.filter(typeResult.rows, function (o) {
      return o.parent_id !== null
    })
    this.setDevTypeList('0')
  }

  setProjectTypeObj() {
    for (let i = 0; i < this.projectTypeList.length; i++) {
      const ptr = this.projectTypeList[i]
      this.ptObject[ptr.value] = ptr.text
    }
  }

  setDevTypeObj() {
    this.dtObject = {}
    for (let i = 0; i < this.developTypeList.length; i++) {
      const ptr = this.developTypeList[i]
      this.dtObject[ptr.value] = ptr.name
    }
  }

  public setDevTypeList(type) {
    if (type == '0') {
      this.developTypeList = this.parentItems
    } else {
      this.developTypeList = this.childItems
    }
    this.setDevTypeObj()
    this.queryClick()
  }

  // 查询按钮事件
  public queryClick() {
    this.getWorkhoursReport()
  }

  async getOrgInfo() {
    const result: any = await orgService.getOrgInfo()
    this.orgList = result.rows
    this.setOrgObject(result.rows)
    this.getWorkhoursReport()
  }

  setOrgObject(rows) {
    for (let i = 0; i < rows.length; i++) {
      let row = rows[i]
      this.orgObject[row.code] = row.name
    }
  }

  async getWorkhoursReport() {
    const params = this.queryItem
    params.startDate = this.queryItem.querydate[0]
    params.endDate = this.queryItem.querydate[1]
    const result: any = await workhourService.getWeekWorkHoursReport(params)
    this.tableData = result.rows
  }

  async exportExcel() {
    let string1 =
      '?startDate=' +
      this.queryItem.querydate[0] +
      '&endDate=' +
      this.queryItem.querydate[1]
    if (this.queryItem.pro_dep) {
      string1 += '&pro_dep=' + this.queryItem.pro_dep
    }
    if (this.queryItem.user_dep) {
      string1 += '&user_dep=' + this.queryItem.user_dep
    }
    if (this.queryItem.project_type) {
      string1 += '&project_type=' + this.queryItem.project_type
    }
    if (this.queryItem.class) {
      string1 += '&class=' + this.queryItem.class
    }
    if (this.queryItem.class_type) {
      string1 += '&class_type=' + this.queryItem.class_type
    }
    if (this.queryItem.project_name) {
      string1 += '&project_name=' + this.queryItem.project_name
    }
    if (this.queryItem.product_name) {
      string1 += '&product_name=' + this.queryItem.product_name
    }
    if (this.queryItem.financial_type) {
      string1 += '&financial_type=' + this.queryItem.financial_type
    }
    const result: any = await workhourService.getWorkHoursExcel(
      string1,
      (res) => {
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        })
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) //创建下载的链接
        downloadElement.href = href
        downloadElement.download = 'xxx.xlsx' //下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放掉blob对象
      }
    )
  }

  public getSummaries(param) {
    const { columns, data } = param
    const sums = []
    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = '合计'
        return
      }
      if (column.property != 'itemHours') {
        return
      } else {
        const values = data.map((item) => parseFloat(item[column.property]))
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = parseFloat(curr)
            if (!isNaN(value)) {
              const res = (parseFloat(prev) + parseFloat(curr)).toFixed(1)
              return res
            } else {
              return parseFloat(prev).toFixed(1)
            }
          }, 0)
          sums[index] += ''
        } else {
          sums[index] = 'N/A'
        }
      }
    })

    return sums
  }

  public handleCurrentChange() {}
}
