
import { Component, Vue, Watch } from 'vue-facing-decorator'
import projectService from '../../../services/projectmanager/projectservice'
import milestoneservice from '../../../services/projectmanager/milestoneservice'
import attachfileservice from '../../../services/projectmanager/attachfileservice'
import costdownservice from '../../../services/projectmanager/costdownservice'
import customerservice from '../../../services/projectmanager/customerservice'
import orgService from '../../../services/org/orgservice'
import workhoursservice from '../../../services/workhours/workhours'
import {getSTSToken, upload} from '../../../utils/oss'
import { project_types }  from '../../../util'
import moment from 'moment'
import * as _ from 'lodash'
let OSS = require('ali-oss')

@Component
export default class ProjectList extends Vue {

  // public data() {
    userInfoString = localStorage.getItem('userInfo')
    userRoleString = localStorage.getItem('userRole')
    userRole = this.userRoleString !== null ? JSON.parse(this.userRoleString) : {}
    // return {
      isAdmin = this.userRole[0].role_id == 'admin'
      mileQueryItem: any = {}
      milestoneFiltersFlag = true
      filtersFlag = true
      customerList = []
      userList = []
      activeName = 'milestone'
      lodash = _
      dialogStyle = ''
      weekPlanStyle = ''
      milestonetitle = ''
      probTitle = ''
      currentTab = 'milestone'
      costdownformtitle = ''
      currentRow: any = {}
      queryItem: any = {
        project_name: '',
        project_manager: '',
        customer_name: '',
        project_member: '',
        is_attentive: '1',
      }
      probQueryItem: any = {}
      currentPage = 1
      weekplanWeek = ''
      probWeek = ''
      addDialogType = ''
      probStyle = ''
      probDialogVisible = false
      needPreEndChange = false
      startListenPreEndChange = false
      pageSize = 10
      tableDataLength = 0
      editform: any = {
        project_member: [],
        project_code: '',
        financial_code: '',
        financial_name: '',
        project_name: '',
        project_type: '',
        project_time: '',
        riskpoint: '',
        project_goal: '',
        project_desc: '',
        project_manager: '',
        project_stage: '',
        project_status: '',
        rela_machine: '',
        req_source: '',
        reason_measure: '',
        dep_code: '',
        is_attentive: '',
        is_view: '',
        level: '',
        is_important: '',
        plan_comp_date: '',
        actual_comp_date: '',
        plan_days: '',
        real_days: '',
        percentage: '',
        customer_name: '',
        contract_code: '',
        fit_machine: '',
        basic_material_code: '',
        alt_material_code: '',
        material_name: '',
        basic_price: '',
        actual_price: '',
        cost_down: '',
        plan_imple_date: '',
        actual_imple_date: '',
        arrival_date: '',
        proposer: '',
        calc_percnt: '',
        purchase_count: '',
        purchas_price: '',
        remark: '',
      }
      detailrow: any = {}
      addWeekDialogVisible = false
      weekplantitle = ''
      notClose = false
      dialogVisible = false
      addDialogVisible = false
      addCdDialogVisible = false
      fullscreenEdit = true
      changeCodeModelVisible = false
      editDialogVisible = false
      loading = true
      needPage = true
      tabsloading = true
      enums = []
      calcHeight = document.documentElement.clientHeight - 60
      items = []
      milestoneitems = []
      costdownitems = []
      weekplanitems = []
      probitems = []
      fileitems = []
      userInfo = this.userInfoString !== null ? JSON.parse(this.userInfoString)  : {}
      costdownItem: any = {}
      probFormItem: any = {
        project_code: '',
        prob_name: '',
        manager: '',
        pre_end_time: '',
        descri: '',
        status: '',
        type: '',
      }
      mileStoneItem: any = {
        project_code: '',
        milestone: '',
        pre_start_time: '',
        start_time: '',
        pre_end_time: '',
        end_time: '',
        delay_days: '',
        version: '',
        remark: '',
        preendchangeremark: '',
        dep_code: '',
        status: '0',
      }
      updateCodeItem: any = {
        project_code: '',
        project_name: '',
      }
      weekplanItem: any = {
        project_code: '',
        current_week: '',
        manager: '',
        descri: '',
        next_week: '',
        next_manager: '',
        dep_code: '',
      }
      projecttypeoptions = project_types
      isShowOptions = [
        {
          code: '0',
          name: '否',
        },
        {
          code: '1',
          name: '是',
        },
      ]
      proborriskoptions = [
        {
          value: '0',
          text: '问题',
        },
        {
          value: '1',
          text: '风险',
        },
      ]
      probtypeoptions = [
        {
          value: '0',
          text: '打开',
        },
        {
          value: '1',
          text: '预警',
        },
        {
          value: '2',
          text: '紧急',
        },
        {
          value: '3',
          text: '延期',
        },
        {
          value: '4',
          text: '关闭',
        },
      ]
      projectstageoptions = [
        {
          value: '0',
          text: '规划中',
        },
        {
          value: '1',
          text: '进行中',
        },
        {
          value: '2',
          text: '已结项',
        },
        {
          value: '3',
          text: '已终止',
        },
        {
          value: '4',
          text: '已生效',
        },
        {
          value: '5',
          text: '已完成',
        },
      ]
      projectstatusclassoptions = [
        {
          value: '0',
          text: '提前',
        },
        {
          value: '1',
          text: '正常',
        },
        {
          value: '2',
          text: '预警',
        },
        {
          value: '3',
          text: '延期',
        },
        {
          value: '4',
          text: '暂停',
        },
        {
          value: '5',
          text: '结项',
          disabled: true,
        },
        {
          value: '6',
          text: '终止',
          disabled: true,
        },
        {
          value: '7',
          text: '暂无',
          disabled: true,
        },
      ]
      levelOptions = [
        {
          value: '0',
          text: 'A',
        },
        {
          value: '1',
          text: 'B',
        },
        {
          value: '2',
          text: 'C',
        },
        {
          value: '3',
          text: 'D',
        },
      ]
      viewOption = [
        {
          code: '0',
          name: '否',
        },
        {
          code: '1',
          name: '是',
        },
      ]
      importantOptions = [
        {
          code: '0',
          name: '重要',
        },
        {
          code: '1',
          name: '中等',
        },
        {
          code: '2',
          name: '一般',
        },
      ]
      attentiveOption = [
        {
          code: '0',
          name: '未关注',
        },
        {
          code: '1',
          name: '关注中',
        },
      ]
      milestoneOptions = [
        {
          code: '0',
          name: '正常',
        },
        {
          code: '1',
          name: '进行中',
        },
        {
          code: '2',
          name: '延期',
        },
        {
          code: '3',
          name: '延期完成',
        },
      ]
      orgList = []
      role = ''
      fileLoading = false
      fileList = []
      fileDialogVisible = false
      fileRemark = ''
      institute = ''
  //   }
  // }
  get editAuth() {
    if (!this.currentRow || Object.keys(this.currentRow).length == 0) {
      return false
    } else {
      var userName = this.userInfo.name
      var manager = this.currentRow.project_manager
      var member = this.currentRow.project_member
      var role = JSON.parse(localStorage.getItem('userRole'))
      if (
        (manager ? manager.indexOf(userName) != -1 : false) ||
        (member ? member.indexOf(userName) != -1 : false) ||
        (role.length > 0 ? role[0].role_id == 'admin' : false)
      ) {
        return true
      } else {
        return false
      }
    }
  }
  public async created() {
    console.log(project_types)
    const userRoleString = localStorage.getItem('userRole')
    const userRole = userRoleString !== null ? JSON.parse(userRoleString) : {}
    this.role = userRole[0]['role_id']
    this.institute =
      this.userInfo.dep_code.length > 6
        ? this.userInfo.dep_code.slice(0, 6)
        : this.userInfo.dep_code
    this.init()
    this.getOrgInfo()
    this.getAllUser()
    getSTSToken()
  }
  public async getOrgInfo() {
    var self = this
    const result: any = await orgService.getOrgInfo()
    this.orgList = result.rows
  }
  public async getAllUser() {
    const items: any = await workhoursservice.getAllUser({})
    if (items.success) {
      this.userList = items.rows
    } else {
      //this.$message.warning(items.msg);
    }
  }

  public async getProjects() {
    const param = {
      currentPage: this.currentPage,
      pageSize: this.pageSize,
    }
    if (!this.needPage) {
      param.currentPage = null
    }
    for (const key in this.queryItem) {
      if (
        this.queryItem[key] !== undefined &&
        this.queryItem[key] !== '' &&
        this.queryItem[key] !== null
      ) {
        param[key] =
          typeof this.queryItem[key] === 'object'
            ? JSON.stringify(this.queryItem[key])
            : this.queryItem[key]
      }
    }
    var role = JSON.parse(localStorage.getItem('userRole'))
    var role_id = role[0].role_id
    if (role_id == 'pww') {
      param['rights'] = this.userInfo.name
    }

    param['username'] = this.userInfo.username
    if (this.userInfo.dep_code.length <= 6) {
      param['dep_code'] = this.userInfo.dep_code
    } else {
      param['dep_code'] = this.userInfo.dep_code.slice(0, 6)
    }
    if (role_id == 'admin') {
      param['dep_code'] = '001'
    }
    // 放开楼上楼下限制
    param['dep_code'] = '001'
    // 换成支持按组织筛选，需求就像蓝天白云晴空万里突然暴风雨
    param['dep_code'] = this.institute

    // param["dep_code"] = this.userInfo.dep_code;
    const result: any = await projectService.getProject({
      params: param,
    })
    if (result.success) {
      return result.rows
    } else {
      return []
    }
  }
  public async exportExcel() {
    const param = {}

    for (const key in this.queryItem) {
      if (
        this.queryItem[key] !== undefined &&
        this.queryItem[key] !== '' &&
        this.queryItem[key] !== null
      ) {
        param[key] =
          typeof this.queryItem[key] === 'object'
            ? JSON.stringify(this.queryItem[key])
            : this.queryItem[key]
      }
    }
    var role = JSON.parse(localStorage.getItem('userRole'))
    var role_id = role[0].role_id
    if (role_id == 'pww') {
      param['rights'] = this.userInfo.name
    }

    param['username'] = this.userInfo.username
    if (this.userInfo.dep_code.length <= 6) {
      param['dep_code'] = this.userInfo.dep_code
    } else {
      param['dep_code'] = this.userInfo.dep_code.slice(0, 6)
    }
    if (role_id == 'admin') {
      param['dep_code'] = '001'
    }
    // 放开楼上楼下限制
    param['dep_code'] = '001'
    // 换成支持按组织筛选，需求就像蓝天白云晴空万里突然暴风雨
    param['dep_code'] = this.institute
    param['excel'] = true

    var url = '?' + this.objToStr(param)
    // param["dep_code"] = this.userInfo.dep_code;
    const result: any = await projectService.getProjectExcel(url)
    // const blob = new Blob([result], {
    //         type:
    //             "text/plain"
    //     });
    //     const downloadElement = document.createElement("a");
    //     const href = window.URL.createObjectURL(blob); //创建下载的链接
    //     downloadElement.href = href;
    //     downloadElement.download = "xxx.xlsx"; //下载后文件名
    //     document.body.appendChild(downloadElement);
    //     downloadElement.click(); //点击下载
    //     document.body.removeChild(downloadElement); //下载完成移除元素
    //     window.URL.revokeObjectURL(href);
  }
  objToStr(obj) {
    let paramArr = Object.keys(obj).reduce((acc, cur) => {
      acc.push(cur + '=' + obj[cur])
      return acc
    }, [])
    return paramArr.join('&')
  }

  // 显示修改项目编码弹框
  public showChangeCodeModal() {
    this.updateCodeItem.project_code = this.currentRow.project_code
    this.updateCodeItem.project_name = this.currentRow.project_name
    this.changeCodeModelVisible = true
  }

  // 提交修改项目编码
  public async onChangeCodeSubmit() {
    const params = {
      oldProjectCode: this.currentRow.project_code,
      newProjectCode: this.updateCodeItem.project_code,
      projectName:
        this.updateCodeItem.project_name || this.currentRow.project_name,
    }
    const result: any = await projectService.updateProjectCode(params)
    if (result.success) {
      this.$message.success('修改成功，请查询')
      this.changeCodeModelVisible = false
      this.queryClick()
    } else {
      this.$message.error(result.err)
    }
  }

  public async projectClose() {
    this.editform.project_member = []
  }
  public async getCustomer() {
    const params = {
      currentPage: 1,
      pageSize: 200,
    }
    const result: any = await customerservice.getCustomerList(params)
    if (result.success) {
      this.customerList = result.rows
    } else {
      //this.$message.warning(result.msg);
    }
  }
  public async init() {
    this.getCustomer()
    // filter 没有默认filter
    if (this.filtersFlag == true) {
      this.queryItem['project_status'] = ['0', '1', '2', '3', '4']
    }
    // var role = JSON.parse(localStorage.getItem("userRole"));
    // var role_id = role[0].role_id
    this.items = await this.getProjects()
    // if (role_id == 'pww') {
    //     var userName = this.userInfo.name;
    //     for (var i = this.items.length - 1; i >= 0; i--) {
    //         if ((this.items[i].project_member?this.items[i].project_member.indexOf(userName) == -1:true) && (this.items[i].project_member?this.items[i].project_manager.indexOf(userName) == -1:true)) {
    //             this.items.splice(i, 1)
    //         }
    //     }
    // }
    this.tableDataLength = 0
    if (this.items.length == 0) {
      this.tabsloading = false
    }
    if (this.items[0]) {
      this.setCurrent(this.items[0])
      this.tableDataLength = this.items[0].total
    }

    const table: any = this.$refs.singleTable
    // if (this.filtersFlag == true) {
    //   table['columns'][11].filteredValue = ['0', '1', '2', '3', '4']
    // }

    table.doLayout()
    this.needPage = true
    this.loading = false
  }

  @Watch('currentRow')
  public onCurrentRowChanged(val: any, oldVal: any) {
    if (!val) {
      return
    }
    this.getMilestones(val.project_code)
    this.getAttachFile(val.project_code)
    this.getCostdowns()
    this.getWeekPlans(val.project_code)
    this.getProblems(val.project_code)
    this.tabsloading = false
  }
  @Watch('editform.project_stage')
  public onProjectStageChanged(val: any, oldVal: any) {
    if (!val) {
      return
    }
    if (val == '2' || val == '3') {
      this.editform.project_status = '5'
    } else if (val == '1' || val == '0') {
      this.editform.project_status = '1'
    } else {
      this.editform.project_status = '7'
    }
  }

  @Watch('mileStoneItem.pre_end_time')
  public preEndTimeChanged(val: any, oldVal: any) {
    if (
      this.dialogStyle === 'edit' &&
      oldVal !== '' &&
      this.startListenPreEndChange
    ) {
      this.needPreEndChange = true
      this.mileStoneItem.old_pre_end_time = oldVal
    }
  }

  public mileStoneOpened() {
    this.startListenPreEndChange = true
  }

  public mileStoneClosed() {
    this.startListenPreEndChange = false
  }

  public beforeCloseMileDialog(done) {
    this.needPreEndChange = false
    done()
  }

  public milestoneFilterProStatus(filters) {
    for (const key in filters) {
      if (key == 'is_show') {
        this.milestoneFiltersFlag = false
      }
      if (key) {
        this.mileQueryItem[key] =
          filters[key].length === 0 ? undefined : filters[key]
      }
    }
    this.getMilestones(this.currentRow.project_code)
  }
  // 获取里程碑
  public async getMilestones(project_code: string) {
    if (this.milestoneFiltersFlag == true) {
      this.mileQueryItem['is_show'] = ['1']
    }
    const table: any = this.$refs.milestoneTable
    if (this.milestoneFiltersFlag == true) {
      // table['columns'][1].filteredValue = ['1']
    }
    const param = {}

    for (const key in this.mileQueryItem) {
      if (
        this.mileQueryItem[key] !== undefined &&
        this.mileQueryItem[key] !== '' &&
        this.mileQueryItem[key] !== null
      ) {
        param[key] =
          typeof this.mileQueryItem[key] === 'object'
            ? JSON.stringify(this.mileQueryItem[key])
            : this.mileQueryItem[key]
      }
    }
    param['project_code'] = project_code
    const result: any = await milestoneservice.getProjectMilestone({
      params: param,
    })
    // const result: any = await milestoneservice.getProjectMilestone({
    //     params: { project_code }
    // });
    if (result.success) {
      for (const item of result.rows) {
        const details = _.filter(result.details, (detailItem) => {
          return detailItem.milestone_id === item.id ? true : false
        })
        if (details.length > 0) {
          item.changeDetails = details
        }
      }
      this.milestoneitems = result.rows
    }
  }

  // 获取附件
  public async getAttachFile(project_code: string) {
    const result: any = await attachfileservice.getAttachFile({
      params: { project_code },
    })
    if (result.success) {
      this.fileitems = result.rows
    }
  }

  // 获取周计划
  public async getWeekPlans(project_code: string) {
    const result: any = await projectService.getProjectWeekplan({
      params: { project_code },
    })
    if (result.success) {
      this.weekplanitems = result.rows
    }
  }

  // 获取问题
  public async getProblems(project_code: string) {
    const param = {
      project_code,
    }
    for (const key in this.probQueryItem) {
      if (
        this.probQueryItem[key] !== undefined &&
        this.probQueryItem[key] !== ''
      ) {
        param[key] =
          typeof this.probQueryItem[key] === 'object'
            ? JSON.stringify(this.probQueryItem[key])
            : this.probQueryItem[key]
      }
    }
    const result: any = await projectService.getProjectProblem({
      params: param,
    })
    if (result.success) {
      this.probitems = result.rows
    }
  }

  public handleSizeChange(val) {
    this.pageSize = val
    this.init()
  }
  public handlePagenumChange(val) {
    this.currentPage = val
    this.init()
  }

  // 提交周计划
  public async onAddWeekplanSubmit(evt) {
    evt.preventDefault()
    this.weekplanItem.project_code = this.currentRow.project_code
    this.weekplanItem.last_modified = this.userInfo.username
    this.weekplanItem.last_modified_date = moment().format('YYYY-MM-DD')
    this.weekplanItem.startdate = moment(this.weekplanWeek).format('YYYY-MM-DD')
    this.weekplanItem.enddate = moment(this.weekplanWeek)
      .add(6, 'days')
      .format('YYYY-MM-DD')
    if (this.weekplanItem.current_week) {
      this.weekplanItem.current_week = this.weekplanItem.current_week
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    if (this.weekplanItem.descri) {
      this.weekplanItem.descri = this.weekplanItem.descri
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    if (this.weekplanItem.next_week) {
      this.weekplanItem.next_week = this.weekplanItem.next_week
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    const param = _.cloneDeep(this.weekplanItem)
    for (const key in param) {
      if (!param[key]) {
        delete param[key]
      }
    }
    if (this.weekPlanStyle === 'add') {
      const result: any = await projectService.addProjectWeekplan(param)
      if (result.success) {
        this.$message.success('添加周计划成功')
        this.getWeekPlans(this.currentRow.project_code)
        this.addWeekDialogVisible = false
      }
    }
    if (this.weekPlanStyle === 'edit') {
      const result: any = await projectService.updateProjectWeekplan(param)
      if (result.success) {
        this.$message.success('更新周计划成功')
        this.getWeekPlans(this.currentRow.project_code)
        this.addWeekDialogVisible = false
      }
    }
  }
  onUpload(data) {
    var self = this
    // var client = new OSS({
    //   region: 'oss-cn-beijing',
    //   accessKeyId: 'LTAI9PlTKIP2Li66',
    //   accessKeySecret: 'VeQr7LlF0HAGzK2bmWex2cF1vaDMlE',
    //   bucket: 'hitms',
    // })
    self.fileLoading = true
    var file = data.file
    var name = file.name.split('.')[0]
    var random_name =
      name + '_' + new Date().getTime() + '.' + file.name.split('.').pop()
    // client
    //   .multipartUpload('project/' + random_name, file, {
    //     partSize: 10 * 1024 * 1024,
    //   })
    //   .then((results) => {
    //     self.addAttachFile(random_name, file)
    //   })
    //   .catch((err) => {
    //     self.$message.error(err)
    //     self.fileLoading = false
    //   })
      upload(file, 'files/' + random_name, (res) => {
        if (res.success) {
          self.addAttachFile(random_name, file)
        } else {
          self.fileLoading = false
          self.$message.error('上传失败')
        }
      })
  }
  // 提交里程碑
  async addAttachFile(random_name, file) {
    var params = {
      project_code: this.currentRow.project_code,
      file_name: file.name,
      file_url:
        'https://histone-project.oss-cn-beijing.aliyuncs.com/files/' + random_name,
      remark: this.fileRemark,
    }

    const result: any = await attachfileservice.addAttachFile(params)
    this.fileLoading = false
    if (result.success) {
      this.$message.success('添加附件成功')
      this.getAttachFile(this.currentRow.project_code)
    } else {
      this.$message.error('添加附件失败')
    }
  }

  // 提交里程碑
  public async onAddMilestoneSubmit(evt) {
    evt.preventDefault()
    this.mileStoneItem.project_code = this.currentRow.project_code
    this.mileStoneItem.last_modified = this.userInfo.username
    const param = _.cloneDeep(this.mileStoneItem)
    if (param.remark) {
      param.remark = param.remark
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    if (param.complete_status) {
      param.complete_status = param.complete_status
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    for (const key in param) {
      if (!param[key]) {
        delete param[key]
      }
    }
    delete param.changeDetails
    if (this.dialogStyle === 'add') {
      const result: any = await milestoneservice.addProjectMilestone(param)
      if (result.success) {
        this.$message.success('添加里程碑成功')
        this.getMilestones(this.currentRow.project_code)
        this.addDialogVisible = false
        this.needPreEndChange = false
        delete this.mileStoneItem.old_pre_end_time
      }
    }
    if (this.dialogStyle === 'edit') {
      const result: any = await milestoneservice.updateProjectMilestone(param)
      if (result.success) {
        this.$message.success('更新里程碑成功')
        this.getMilestones(this.currentRow.project_code)
        this.addDialogVisible = false
        this.needPreEndChange = false
        delete this.mileStoneItem.old_pre_end_time
      }
    }
  }

  // 添加里程碑
  public async addNewMilestone() {
    this.dialogStyle = 'add'
    this.milestonetitle = '增加里程碑'
    for (const key in this.mileStoneItem) {
      if (this.mileStoneItem[key]) {
        this.mileStoneItem[key] = ''
      }
    }
    this.mileStoneItem['dep_code'] = this.userInfo.dep_code
    this.mileStoneItem['status'] = '0'
    this.addDialogVisible = true
  }

  // 获取上周计划
  public getLastWeekDes() {
    this.weekplanItem.current_week = ''
    if (this.weekplanitems.length > 0) {
      if (this.weekplanItem.dep_code != '') {
        var startdate = moment(this.weekplanWeek)
          .subtract(7, 'days')
          .format('YYYY-MM-DD')
        var enddate = moment(this.weekplanWeek)
          .add(6, 'days')
          .subtract(7, 'days')
          .format('YYYY-MM-DD')
      }
      for (var item of this.weekplanitems) {
        if (
          item.dep_code == this.weekplanItem.dep_code &&
          item.startdate == startdate &&
          item.enddate == enddate
        ) {
          this.weekplanItem.current_week = item.next_week
          this.weekplanItem.manager = item.next_manager
          break
        }
      }
      if (this.weekplanItem.current_week) {
        this.weekplanItem.current_week = this.weekplanItem.current_week.replace(
          /<br\/>/g,
          '\r\n'
        )
      }
    }
  }

  // 获取周计划总体情况
  public async getRsWeekplanDesc() {
    const result: any = await projectService.getRsWeekplanDesc({
      params: {
        project_code: this.currentRow.project_code,
        startDate: moment(this.weekplanWeek).format('YYYY-MM-DD'),
        endDate: moment(this.weekplanWeek).add(6, 'days').format('YYYY-MM-DD'),
      },
    })
    return result.rows[0].project_desc
  }
  // 添加/更新周计划
  public async addNewWeekplan() {
    this.weekPlanStyle = 'add'
    this.weekplantitle = '新增周计划'
    var Nowdate: any = new Date()
    var WeekFirstDay: any = new Date(
      Nowdate - (Nowdate.getDay() - 1) * 86400000
    )
    this.weekplanWeek = WeekFirstDay
    for (const key in this.weekplanItem) {
      if (this.weekplanItem[key]) {
        this.weekplanItem[key] = ''
      }
    }
    this.weekplanItem['dep_code'] = this.userInfo.dep_code
    this.getLastWeekDes()
    this.weekplanItem.descri = await this.getRsWeekplanDesc()
    // if (this.weekplanitems.length > 0) {
    //     const lastitem = this.weekplanitems[this.weekplanitems.length - 1];
    //     this.weekplanItem.current_week = lastitem.next_week;
    //     this.weekplanItem.manager = lastitem.next_manager;
    // }

    if (this.weekplanItem.descri) {
      this.weekplanItem.descri = this.weekplanItem.descri.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    if (this.weekplanItem.next_week) {
      this.weekplanItem.next_week = this.weekplanItem.next_week.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    this.weekplanItem.project_code = this.currentRow.project_code
    this.addWeekDialogVisible = true
  }

  // 删除周计划
  public async deletePlanWeek(row) {
    this.$confirm('确认删除？')
      .then(async () => {
        const result: any = await projectService.deleteProjectWeekplan({
          params: {
            row,
          },
        })
        if (result.success) {
          this.$message({
            message: '删除成功',
            type: 'success',
          })
          this.getWeekPlans(this.currentRow.project_code)
        }
      })
      .catch((err) => {
        throw err
      })
  }

  // 编辑周计划
  public editPlanWeek(row) {
    this.weekPlanStyle = 'edit'
    this.weekplantitle = '编辑周计划'
    this.weekplanItem = _.cloneDeep(row)
    this.weekplanWeek = row.startdate
    if (this.weekplanItem.current_week) {
      this.weekplanItem.current_week = this.weekplanItem.current_week.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    if (this.weekplanItem.descri) {
      this.weekplanItem.descri = this.weekplanItem.descri.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    if (this.weekplanItem.next_week) {
      this.weekplanItem.next_week = this.weekplanItem.next_week.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    this.addWeekDialogVisible = true
  }

  // 删除问题记录
  public deleteProb(row) {
    this.$confirm('确认删除？')
      .then(async () => {
        const result: any = await projectService.deleteProjectProblem({
          params: {
            row,
          },
        })
        if (result.success) {
          this.$message({
            message: '删除成功',
            type: 'success',
          })
          this.getProblems(this.currentRow.project_code)
        }
      })
      .catch((err) => {
        throw err
      })
  }

  // 编辑问题记录
  public editProb(row) {
    this.probStyle = 'edit'
    this.probTitle = '编辑问题记录'
    this.probFormItem = _.cloneDeep(row)
    this.weekplanWeek = row.startdate
    if (this.probFormItem.descri) {
      this.probFormItem.descri = this.probFormItem.descri.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    if (this.probFormItem.prob_name) {
      this.probFormItem.prob_name = this.probFormItem.prob_name.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    this.probDialogVisible = true
  }

  // 添加问题记录
  public addNewProb() {
    this.probStyle = 'add'
    this.probTitle = '添加问题记录'
    var Nowdate: any = new Date()
    var WeekFirstDay: any = new Date(
      Nowdate - (Nowdate.getDay() - 1) * 86400000
    )
    this.weekplanWeek = WeekFirstDay

    for (const key in this.probFormItem) {
      if (this.probFormItem[key]) {
        this.probFormItem[key] = ''
      }
    }
    this.probFormItem['dep_code'] = this.userInfo.dep_code
    const index = this.probitems.length - 1
    const lastPro = this.probitems[index]
    if (lastPro) {
      this.probFormItem.prob_name = lastPro.prob_name
      this.probFormItem.manager = lastPro.manager
    }
    this.probFormItem.project_code = this.currentRow.project_code
    this.probDialogVisible = true
  }

  // 提交问题记录
  public async onAddProbSubmit(evt) {
    evt.preventDefault()
    this.probFormItem.project_code = this.currentRow.project_code
    this.probFormItem.last_modified = this.userInfo.username
    this.probFormItem.last_modified_date = moment().format('YYYY-MM-DD')
    this.probFormItem.startdate = moment(this.weekplanWeek).format('YYYY-MM-DD')
    this.probFormItem.enddate = moment(this.weekplanWeek)
      .add(6, 'days')
      .format('YYYY-MM-DD')
    const param = _.cloneDeep(this.probFormItem)
    if (param.descri) {
      param.descri = param.descri
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    if (param.next_week) {
      param.next_week = param.next_week
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    for (const key in param) {
      if (!param[key]) {
        delete param[key]
      }
    }
    if (this.probStyle === 'add') {
      const result: any = await projectService.addProjectProblem(param)
      if (result.success) {
        this.$message.success('添加周计划成功')
        this.getProblems(this.currentRow.project_code)
        this.probDialogVisible = false
      }
    }
    if (this.probStyle === 'edit') {
      const result: any = await projectService.updateProjectProblem(param)
      if (result.success) {
        this.$message.success('更新周计划成功')
        this.getProblems(this.currentRow.project_code)
        this.probDialogVisible = false
      }
    }
  }

  // 编辑里程碑
  public async editMilestone(data) {
    this.dialogStyle = 'edit'
    this.milestonetitle = '编辑里程碑'
    this.mileStoneItem = _.cloneDeep(data)
    if (this.mileStoneItem.complete_status) {
      this.mileStoneItem.complete_status =
        this.mileStoneItem.complete_status.replace(/<br\/>/g, '\r\n')
    }
    if (this.mileStoneItem.remark) {
      this.mileStoneItem.remark = this.mileStoneItem.remark.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    this.addDialogVisible = true
  }

  // 删除里程碑
  public async deleteMilestone(data) {
    this.$confirm('确认删除？')
      .then(async () => {
        const result: any = await milestoneservice.deleteProjectMilestone({
          params: {
            row: data,
          },
        })
        if (result.success) {
          this.$message({
            message: '删除成功',
            type: 'success',
          })
          this.getMilestones(this.currentRow.project_code)
        }
      })
      .catch((err) => {
        throw err
      })
  }
  downloadAttachFile(url, name) {
    var self = this
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.onload = function () {
      self.saveAs(xhr.response, name)
    }

    xhr.onerror = function () {
      console.error('could not download file')
    }

    xhr.send()
  }
  saveAs(data, name) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    })
    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob) //创建下载的链接
    downloadElement.href = href
    downloadElement.download = name //下载后文件名
    document.body.appendChild(downloadElement)
    downloadElement.click() //点击下载
    document.body.removeChild(downloadElement) //下载完成移除元素
    window.URL.revokeObjectURL(href)
  }

  // 删除附件
  public async deleteAttachFile(data) {
    this.$confirm('确认删除？')
      .then(async () => {
        const result: any = await attachfileservice.deleteAttachFile({
          params: {
            row: data,
          },
        })
        if (result.success) {
          this.$message({
            message: '删除成功',
            type: 'success',
          })
          this.getAttachFile(this.currentRow.project_code)
        }
      })
      .catch((err) => {
        throw err
      })
  }

  // 获取降成本
  public async getCostdowns() {
    const result: any = await costdownservice.getCodedownPrice({
      params: {
        project_code: this.currentRow.project_code,
      },
    })
    if (result.success) {
      this.costdownitems = result.rows
    }
  }

  // 提交降成本
  public async onCostdownSubmit(evt) {
    evt.preventDefault()
    this.costdownItem.last_modified = this.userInfo.username
    this.costdownItem.project_code = this.currentRow.project_code
    let result: any = null
    if (this.cddialogType === 'edit') {
      result = await costdownservice.updateCodedownPrice(this.costdownItem)
    } else {
      result = await costdownservice.addCodedownPrice(this.costdownItem)
    }
    if (result.success) {
      this.$message({
        message: '提交成功',
        type: 'success',
      })
      this.getCostdowns()
      this.addCdDialogVisible = false
    } else {
      this.$message({
        message: result.err,
        type: 'error',
      })
    }
  }

  // 新增附件
  public attachFileShow() {
    this.fileDialogVisible = true
    this.fileRemark = ''
  }

  // 新增降成本
  public addNewCostdown() {
    this.cddialogType = 'add'
    this.costdownformtitle = '新增降成本项'
    this.costdownItem = _.cloneDeep({
      month: '',
      purchase_count: '',
      purchas_price: '',
    })
    this.addCdDialogVisible = true
  }

  // 删除降成本
  public async deleteCdRow(row) {
    this.$confirm('确认删除？')
      .then(async () => {
        const result: any = await costdownservice.deleteCodedownPrice({
          params: {
            row,
          },
        })
        if (result.success) {
          this.$message({
            message: '删除成功',
            type: 'success',
          })
          this.getCostdowns()
        }
      })
      .catch((err) => {
        throw err
      })
  }

  // 编辑降成本
  public editCd(row) {
    this.cddialogType = 'edit'
    this.costdownItem = _.cloneDeep(row)
    this.costdownformtitle = '编辑降成本项'
    this.addCdDialogVisible = true
  }

  public detail() {
    this.detailrow = this.currentRow
    this.dialogVisible = true
  }

  public edit(data: any) {
    this.addDialogType = 'edit'
    this.editform = _.cloneDeep(this.currentRow)
    // if (this.editform.project_member) {
    this.editform.project_member = this.editform.project_member
      ? this.editform.project_member.split(',')
      : []
    // }

    if (this.editform.reason_measure) {
      this.editform.reason_measure = this.editform.reason_measure.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    if (this.editform.project_goal) {
      this.editform.project_goal = this.editform.project_goal.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    if (this.editform.riskpoint) {
      this.editform.riskpoint = this.editform.riskpoint.replace(
        /<br\/>/g,
        '\r\n'
      )
    }

    this.editDialogVisible = true
    // this.$router.push({name: 'pmprojectedit', params: {row: this.currentRow}});
  }

  // 获取实际工时
  public async getActualWorkhours(project_code: string) {
    const result: any = await projectService.getProjectActualHours({
      params: {
        project_code,
      },
    })
    this.currentRow.real_days = (parseFloat(result.rows[0].hours) / 8).toFixed(
      1
    )
  }

  public add(data: any) {
    this.addDialogType = 'add'
    this.editDialogVisible = true
    this.editform = {}
    // this.$router.push({name: 'pmprojectedit', params: {row: this.currentRow}});
  }

  public milestone(data: any) {
    this.$router.push({
      name: 'pmprojectmilestone',
      params: { row: data },
    })
  }

  public costdown(data: any) {
    this.$router.push({ name: 'pmprojectcostdown', params: { row: data } })
  }

  public setCurrent(row) {
    const table: any = this.$refs.singleTable
    table.setCurrentRow(row)
    this.getActualWorkhours(this.currentRow.project_code)
  }

  public handleCurrentChange(val) {
    this.currentRow = val
    if (!val) {return;}
    this.getActualWorkhours(this.currentRow.project_code)
  }

  public quitEdit() {
    this.editDialogVisible = false
  }

  // 提交项目
  public async onEditSubmit(evt) {
    evt.preventDefault()
    // for ( const key in this.form) {
    //   if (this.form[key] === '' || this.form[key] === null) {
    //     delete this.form[key];
    //   }
    // }
    // this.loading = true;
    const userInfoString = localStorage.getItem('userInfo')
    const userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
    this.editform.last_modified = userInfo.username
    if (this.editform.reason_measure) {
      this.editform.reason_measure = this.editform.reason_measure
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    if (this.editform.project_goal) {
      this.editform.project_goal = this.editform.project_goal
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    if (this.editform.riskpoint) {
      this.editform.riskpoint = this.editform.riskpoint
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    if (
      !this.editform.project_member ||
      this.editform.project_member.length == 0
    ) {
      this.$message.error('项目成员不允许为空')
      return
    }
    if (this.addDialogType === 'edit') {
      const result: any = await projectService.updateProject(this.editform)
      if (result.success) {
        this.$message.success('提交成功')
        this.editDialogVisible = false
        this.init()
      } else {
        this.$message.error(result.err)
      }
    } else {
      if (this.userInfo.dep_code.length <= 6) {
        this.editform.dep_code = this.userInfo.dep_code
      } else {
        if (!this.editform.dep_code) {
          this.editform.dep_code = this.userInfo.dep_code.slice(0, 6)
        }
      }
      const result: any = await projectService.addProject(this.editform)
      if (result.success) {
        this.$message.success('提交成功')
        this.editDialogVisible = false
        this.init()
      } else {
        this.$message.error(result.err)
      }
    }
  }

  public handleMainTableCellClass({
    row,
    column,
    rowIndex,
    columnIndex,
  }): string {
    if (
      columnIndex === 11 &&
      (row[column.property] === '0' || row[column.property] === '1')
    ) {
      return 'blue'
    }
    if (columnIndex === 11 && row[column.property] === '2') {
      return 'yellow'
    }
    if (columnIndex === 11 && row[column.property] === '3') {
      return 'red'
    }
    if (columnIndex === 11 && row[column.property] === '4') {
      return 'gray'
    }
    return null
  }

  public sortChanged(obj) {
    const table: any = this.$refs.singleTable
    this.queryItem.order_column = obj.prop
    this.queryItem.order_type = obj.order === 'ascending' ? 'asc' : 'desc'
    this.init()
  }

  public async showPro() {
    this.needPage = false
    const table: any = this.$refs.singleTable
    const items = await this.getProjects()
    localStorage.setItem('weekshowparams', JSON.stringify({
        pros: items,
        curProject: this.currentRow,
      }))
    this.$router.push({
      name: 'weekshowname'
    })
  }

  public filterProStatus(filters) {
    for (const key in filters) {
      if (key == 'project_status') {
        this.filtersFlag = false
      }
      if (key) {
        this.queryItem[key] =
          filters[key].length === 0 ? undefined : filters[key]
      }
    }
    this.currentPage = 1
    this.init()
  }

  public filterProblemStatus(filters) {
    for (const key in filters) {
      if (key) {
        this.probQueryItem[key] =
          filters[key].length === 0 ? undefined : filters[key]
      }
    }
    this.getProblems(this.currentRow.project_code)
  }

  // 查询按钮事件
  public queryClick() {
    this.currentPage = 1
    this.init()
  }

  public tabClicked(args) {
    this.currentTab = args.paneName
  }

  public addNewDetailItem() {
    switch (this.currentTab) {
      case 'milestone':
        this.addNewMilestone()
        break
      case 'weekplan':
        this.addNewWeekplan()
        break
      case 'probtrack':
        this.addNewProb()
        break
      case 'costdown':
        this.addNewCostdown()
        break
      case 'attachedFile':
        this.attachFileShow()
        break
      default:
        break
    }
  }
}
