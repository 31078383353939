import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../../config/index'

const apiPath = {
  product: '/mpapi/product',
}

async function getProductList(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.product, {
    params: param,
  })
  return result.data
}

async function addtProduct(param: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.product, param)
  return result.data
}

async function deleteProduct(param: object): Promise<AxiosResponse> {
  const result = await axios.delete(config.url + apiPath.product, param)
  return result.data
}

async function updateProduct(param: object): Promise<AxiosResponse> {
  const result = await axios.put(config.url + apiPath.product, param)
  return result.data
}

export default {
  getProductList,
  addtProduct,
  updateProduct,
  deleteProduct,
}
