import OSS from 'ali-oss'
let client = null
let osspath = 'project/';
let accessKeyId = '', accessKeySecret = '', securityToken = ''
import loginService from '../services/login'

export async function getSTSToken(cb) {
    const res = await loginService.getSts({})
    if (res.status == '200') {
        accessKeyId = res.data.AccessKeyId
        accessKeySecret = res.data.AccessKeySecret
        securityToken = res.data.SecurityToken
        setClient()
        if (cb) {
            cb(res.data)
        }
    }
}


function setClient() {
    client = new OSS({
        region: 'oss-cn-beijing',
        accessKeyId: accessKeyId,
        accessKeySecret: accessKeySecret,
        stsToken: securityToken,
        bucket: 'histone-project',
        refreshSTSToken: async () => {
            return new Promise((resolve) => {
                getSTSToken((data) => {
                    resolve({
                        accessKeyId: data.AccessKeyId,
                        accessKeySecret: data.AccessKeySecret,
                        stsToken: data.SecurityToken
                    })
                })
            })
        },
        refreshSTSTokenInterval: 1000 * 60 * 59
    });
}


export function upload(file, name, cb, progress) {
    if (!client) return;
    client.multipartUpload(
        name,
        file, {
        partSize: 10 * 1024 * 1024,
        progress: function (
            percentage,
            point,
            res
        ) {
            progress =
                (percentage * 100).toFixed(
                    2
                ) + '%'
        },
        timeout: 10 * 60 * 1000,
        headers: {
            'Content-Type': 'text/plain; charset=UTF-8'
        }
    }
    )
        .then(results => {
            // var fileUrl = results.name
            cb({
                success: true,
                data: results
            })
        })
        .catch(err => {
            cb({
                success: false,
                err
            })
        })
}
