import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../../config/index'

const apiPath = {
  milestone: '/mpapi/milestone',
}

async function getProjectMilestone(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.milestone, param)
  return result.data
}

async function addProjectMilestone(param: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.milestone, param)
  return result.data
}

async function deleteProjectMilestone(param: object): Promise<AxiosResponse> {
  const result = await axios.delete(config.url + apiPath.milestone, param)
  return result.data
}

async function updateProjectMilestone(param: object): Promise<AxiosResponse> {
  const result = await axios.put(config.url + apiPath.milestone, param)
  return result.data
}

export default {
  getProjectMilestone,
  addProjectMilestone,
  updateProjectMilestone,
  deleteProjectMilestone,
}
