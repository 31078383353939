
import {
  Document as ElIconDocument,
  User as ElIconUser,
  SwitchButton as ElIconSwitchButton,
} from '@element-plus/icons-vue'
import { Component, Vue } from 'vue-facing-decorator'
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import perService from '../services/permissionservice'
import loginService from '../services/login'
import SidebarMenu from './sidebarMenu.vue'
import UpdateHis from './system/updatehis.vue'
// import { debug } from "util";
import * as _ from 'lodash'

interface Permission {
  permission_code: string
  permission_desc: string
  usercode: string
}

declare global {
  interface Window {
    permissons: Permission[]
  }
}

@Component({
  components: {
    SidebarMenu,
    UpdateHis,
  },
})
export default class Home extends Vue {

  data: any = {}
  drawer = false

  makedata() {
    const userInfoString = localStorage.getItem('userInfo')
    const userRoleString = localStorage.getItem('userRole')
    const userRole = userRoleString !== null ? JSON.parse(userRoleString) : {}
    const maketMenu = []
    const maketMenu_old = []
    const chartMenu = [
      {
        url: '',
        icon: '',
        caption: '登录管理',
        children: [
          {
            url: '/loginhis',
            icon: '',
            caption: '登录历史',
          },
        ],
      },
    ]
    let pmMenu = [
      {
        url: '/pmproject',
        icon: '',
        caption: '项目管理',
        children: [
          {
            url: '/pmproject/list',
            icon: '',
            caption: '项目列表',
          },

          {
            url: '/pmproject/customer',
            icon: '',
            caption: '客户列表',
          },
          {
            url: '/pmproject/product',
            icon: '',
            caption: '产品列表',
          },
        ],
      },
    ]
    var obj0 = {
      url: '/workhours/history',
      icon: '',
      caption: '日志报表',
    }
    var obj = {
      url: '/pmproject/total',
      icon: '',
      caption: '项目投入',
    }
    var obj1 = {
      url: '/pmproject/progress',
      icon: '',
      caption: '项目进展报表',
    }
    var obj2 = {
      url: '/pmproject/person',
      icon: '',
      caption: '员工工时汇总',
    }
    var classMange = {
      url: '/workhours/classmanage',
      icon: '',
      caption: '工时分类管理',
    }
    // if (JSON.parse(userInfoString).username == "caoxiuchang"||JSON.parse(userInfoString).username == "jianglili"||JSON.parse(userInfoString).username == "shifenglei"||JSON.parse(userInfoString).username == "liyanchen")
    if (userRole[0].role_id == 'pww2') {
      pmMenu[0].children.splice(1, 0, obj2)
    }
    const weekMenu = [
      {
        url: '/rsweekplan',
        icon: '',
        caption: '周报计划',
        children: [
          {
            url: '/rsweekplan/list',
            icon: '',
            caption: '周计划',
          },
        ],
      },
    ]
    const workHoursMenu = [
      {
        url: '/workhours',
        icon: '',
        caption: '工作日志',
        children: [
          {
            url: '/workhours/list',
            icon: '',
            caption: '填报日志',
          },
          {
            url: '/workhours/audit',
            icon: '',
            caption: '审核日志',
          },
          {
            url: '/workhours/simple',
            icon: '',
            caption: '日志查询',
          },
        ],
      },
    ]
    const reportMenu = [
      {
        url: '/report',
        icon: '',
        caption: '报表',
        children: [
          
          {
            url: '/rsweekplan/week',
            icon: '',
            caption: '员工工作汇总',
          },
          {
            url: '/pmproject/hoursbyproject',
            icon: '',
            caption: '项目工时分类报表',
          },
          {
            url: '/pmproject/hoursbyperson',
            icon: '',
            caption: '员工工时分类报表',
          },
          {
            url: '/pmproject/zentaoreport',
            icon: '',
            caption: '禅道任务报表',
          },
          {
            url: '/pmproject/zentaoqualityreport',
            icon: '',
            caption: '禅道质量任务',
          },
        ],
      },
    ]
    if (userRole[0].role_id == 'admin') {
      reportMenu[0].children.splice(0, 0, obj0)
      reportMenu[0].children.splice(1, 0, obj)
      reportMenu[0].children.splice(2, 0, obj2)
      workHoursMenu[0].children.splice(2, 0, classMange)
    }
    const orgMenu = [
      {
        url: '/sys',
        icon: '',
        caption: '系统管理',
        children: [
          {
            url: '/sys/org/list',
            icon: '',
            caption: '组织管理',
          },
          {
            url: '/sys/user/list',
            icon: '',
            caption: '人员管理',
          },
        ],
      },
    ]
    const assessMenu = [
      {
        url: '/assess',
        icon: '',
        caption: '评价管理',
        children: [
          {
            url: '/assess/template',
            icon: '',
            caption: '开始评价',
          },
          {
            url: '/assess/list',
            icon: '',
            caption: '评价记录',
          },
        ],
      },
    ]
    let _title = '海石研发管理系统'
    let menus = []
    if (userRole.length != 0) {
      if (userRole[0].role_id == 'pww' || userRole[0].role_id == 'pww2') {
        this._menu = menus = menus.concat(pmMenu)
        this._menu = menus = menus.concat(weekMenu)
        this._menu = menus = menus.concat(workHoursMenu)
        this._menu = menus = menus.concat(reportMenu)
        this._menu = menus = menus.concat(assessMenu)
        this._menu = menus = menus.concat(assessMenu)
      }
      if (userRole[0].role_id == 'admin') {
        this._menu = menus = menus.concat(pmMenu)
        this._menu = menus = menus.concat(weekMenu)
        this._menu = menus = menus.concat(workHoursMenu)
        this._menu = menus = menus.concat(reportMenu)
        this._menu = menus = menus.concat(orgMenu)
        this._menu = menus = menus.concat(assessMenu)
      }

      return {
        title: _title,
        userInfo: userInfoString !== null ? JSON.parse(userInfoString) : {},
        menu: this._menu,
        drawer: this.drawer,
      }
    }
    return {
      title: _title,
      userInfo: userInfoString !== null ? JSON.parse(userInfoString) : {},
      menu: this._menu,
      drawer: this.drawer,
    }
  }

  private async created() {
    this.data = this.makedata()
    const result: any = await perService.getPermission({
      username: this.data.userInfo.username,
    })
    window.permissons = result.rows
    // if (this.marketlength > 0 && this.userInfo.org_code === 0) {
    // } else if (this.cmarketlength > 0 && this.marketlength <= 0) {
    //     this.$router.push("/pmproject/list");
    // } else {
    //     this.$router.push("/home");
    // }
  }

  gotoChangePwd() {
    const self: any = this
    self.$router.push('/changepwd')
  }

  gotoUpdateHis() {
    // this.$router.push('/updatehis')
    this.drawer = true
  }

  async logout() {
    localStorage.removeItem('token')
    localStorage.removeItem('isOutDateChangePwd')
    localStorage.setItem('notlogin', '0')
    this.$router.push('/')
  }
}
