<template>
  <el-menu
    :router="true"
    background-color="#2c3b41"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
    <el-sub-menu v-for="item in menu" :index="item.caption" :key="item.caption">
      <template v-slot:title>
        <el-icon><el-icon-location /></el-icon>
        <span>{{ item.caption }}</span>
      </template>
      <template v-if="item.children">
        <el-menu-item
          v-for="children in item.children"
          :index="children.url"
          :key="children.url"
          ><el-icon><el-icon-bell /></el-icon
          >{{ children.caption }}</el-menu-item
        >
      </template>
    </el-sub-menu>
  </el-menu>
</template>

<script>
import {
  Location as ElIconLocation,
  Bell as ElIconBell,
} from '@element-plus/icons-vue'
export default {
  components: {
    ElIconLocation,
    ElIconBell,
  },
  name: 'SidebarName',
  mounted() {},
  props: {
    menu: {
      type: Array,
      require: true,
    },
  },
}
</script>
