import { ElMessageBox, ElMessage } from 'element-plus'
import { h } from 'vue'

export default function showNoti(loginData) {
    // 更新提示支持用户自定义登录后首页
    if (loginData.loginHis[0] && loginData.loginHis[0].createdtime <= '2023-05-05 23:59:59') {
        ElMessageBox({
            message: '此次更新支持用户自定义登录后首页，如有需要，可以去个人信息设置界面进行设置（点击右上角密码图标进入）。',
            title: '新功能提示',
            confirmButtonText: '确定',
            type: 'info',
            callback: (action) => {
            }
        })
    }
    if (loginData.loginHis[0] && loginData.loginHis[0].createdtime <= '2023-08-29 17:59:59') {
        ElMessageBox({
            title: '新功能提示',
            type: 'info',
            confirmButtonText: '确定',
            message: h('p', null, [
                h('span', null, '此次更新支持重置密码，忘记密码用户在登录页可以进行重置,使用邮箱接收重置链接。'),
                h('span', { style: 'color: red; font-weight: bold' }, '部分用户邮箱为海信邮箱请一定修改为现在使用的histonetec邮箱！！'),
            ]),
            callback: (action) => {
            }
        })
    }
    // if (loginData.loginHis[0] && loginData.loginHis[0].createdtime <= '2022-09-01 23:59:59') {
    //     ElMessageBox({
    //         title: '新功能提示',
    //         type: 'info',
    //         confirmButtonText: '确定',
    //         message: '测试',
    //         callback: (action) => {}
    //     })
    // }
    if (loginData.data[0].email.indexOf("@histonetec.com") === -1) {
        // alert("您的用户邮箱不是histone邮箱，请进入")
        ElMessageBox({
            title: '邮箱修改提示',
            type: 'info',
            confirmButtonText: '确定',
            message: '您的邮箱不是histone邮箱，点击确认去修改邮箱',
            closeOnPressEscape: false,
            closeOnClickModal: false,
            callback: () => {
                location.href = '#/changepwd';
            }
        })
    }
    let date = new Date(loginData.data[0].last_modified_password);
    let date2 = new Date(date)
    date2.setDate(date.getDate() + 90)
    if (date2.getTime() < new Date().getTime()) {
        localStorage.setItem('isOutDateChangePwd', '1')
        // ElMessageBox({
        //     title: '密码过期提示',
        //     type: 'info',
        //     confirmButtonText: '确定',
        //     message: '您的密码已过期，请进入修改密码页面修改密码',
        //     closeOnPressEscape: false,
        //     closeOnClickModal: false,
        //     callback: () => {
        //         location.href = '#/changepwd';
        //     }
        // })
    }
}
