import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../../config/index'

const apiPath = {
  costdown: '/mpapi/costdownprice',
}

async function getCodedownPrice(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.costdown, param)
  return result.data
}

async function addCodedownPrice(param: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.costdown, param)
  return result.data
}

async function updateCodedownPrice(param: object): Promise<AxiosResponse> {
  const result = await axios.put(config.url + apiPath.costdown, param)
  return result.data
}

async function deleteCodedownPrice(param: object): Promise<AxiosResponse> {
  const result = await axios.delete(config.url + apiPath.costdown, param)
  return result.data
}

export default {
  getCodedownPrice,
  deleteCodedownPrice,
  addCodedownPrice,
  updateCodedownPrice,
}
