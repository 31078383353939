import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "querypanel" }
const _hoisted_2 = { class: "query-item" }
const _hoisted_3 = { class: "query-item" }
const _hoisted_4 = { class: "query-item" }
const _hoisted_5 = { class: "query-item" }
const _hoisted_6 = { class: "query-item" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", {
    class: "zentao-task-container",
    style: _normalizeStyle({ height: _ctx.calcHeight + 'px' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_select, {
          modelValue: _ctx.queryItem.user_dep,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.queryItem.user_dep) = $event)),
          placeholder: "人所属组织",
          filterable: "",
          clearable: "",
          onChange: _ctx.clearUser,
          style: {"width":"150px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orgList, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.code,
                label: item.name,
                value: item.code
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_select, {
          modelValue: _ctx.queryItem.user,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.queryItem.user) = $event)),
          placeholder: "用户",
          filterable: "",
          clearable: "",
          style: {"width":"150px"},
          onChange: _ctx.clearDep
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.account,
                label: item.realname,
                value: item.account
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_select, {
          modelValue: _ctx.queryItem.project,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.queryItem.project) = $event)),
          placeholder: "所属项目",
          filterable: "",
          clearable: "",
          style: {"width":"150px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.zentaoProjects, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.name,
                value: item.id
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_el_select, {
          modelValue: _ctx.queryItem.status,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.queryItem.status) = $event)),
          "collapse-tags": "",
          placeholder: "任务状态",
          filterable: "",
          clearable: "",
          multiple: "",
          style: {"width":"250px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statusOptions, (value, key, index) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: key,
                label: value,
                value: key
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_date_picker, {
          modelValue: _ctx.queryItem.openedDate,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.queryItem.openedDate) = $event)),
          type: "daterange",
          "value-format": "YYYY-MM-DD",
          "range-separator": "至",
          "start-placeholder": "创建开始日期",
          "end-placeholder": "创建结束日期"
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.getTasks(1)))
      }, {
        default: _withCtx(() => [
          _createTextVNode("查询")
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_table, {
      height: _ctx.calcHeight - 80,
      data: _ctx.tableData,
      ref: "singleTable",
      "highlight-current-row": "",
      border: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "name",
          label: "任务名称",
          "show-overflow-tooltip": "",
          width: "150"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("a", {
              href: 
              'http://zentao.hisensehics.com/zentao/task-view-' +
              scope.row.id +
              '.html'
            ,
              target: "_blank"
            }, _toDisplayString(scope.row.name), 9, _hoisted_7)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "type",
          label: "任务类型",
          "show-overflow-tooltip": "",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.typeoption[scope.row.type]), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "status",
          label: "任务状态",
          "show-overflow-tooltip": "",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.statusOptions[scope.row.status]), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "project_name",
          label: "所属项目",
          "show-overflow-tooltip": "",
          width: "150"
        }),
        _createVNode(_component_el_table_column, {
          prop: "desc",
          label: "任务描述",
          "show-overflow-tooltip": "",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", {
              class: "desc",
              innerHTML: scope.row.desc
            }, null, 8, _hoisted_8)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "openedBy",
          label: "创建人",
          "show-overflow-tooltip": "",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.lodash.filter(_ctx.users, { account: scope.row.openedBy })[0]
              ? _ctx.lodash.filter(_ctx.users, { account: scope.row.openedBy })[0]
                  .realname
              : scope.row.openedBy), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "assignedTo",
          label: "执行人",
          "show-overflow-tooltip": "",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.assignedTo == 'closed'
              ? '已关闭'
              : _ctx.lodash.filter(_ctx.users, { account: scope.row.assignedTo })[0]
              ? _ctx.lodash.filter(_ctx.users, { account: scope.row.assignedTo })[0]
                  .realname
              : scope.row.assignedTo), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "finishedBy",
          label: "完成人",
          "show-overflow-tooltip": "",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.lodash.filter(_ctx.users, { account: scope.row.finishedBy })[0]
              ? _ctx.lodash.filter(_ctx.users, { account: scope.row.finishedBy })[0]
                  .realname
              : scope.row.finishedBy), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "canceledBy",
          label: "取消人",
          "show-overflow-tooltip": "",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.lodash.filter(_ctx.users, { account: scope.row.canceledBy })[0]
              ? _ctx.lodash.filter(_ctx.users, { account: scope.row.canceledBy })[0]
                  .realname
              : scope.row.canceledBy), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "openedDate",
          label: "创建时间",
          "show-overflow-tooltip": "",
          width: "120"
        }),
        _createVNode(_component_el_table_column, {
          prop: "finishedDate",
          label: "完成时间",
          "show-overflow-tooltip": "",
          width: "120"
        })
      ]),
      _: 1
    }, 8, ["height", "data"]),
    _createVNode(_component_el_pagination, {
      onSizeChange: _ctx.handleSizeChange,
      onCurrentChange: _ctx.handlePagenumChange,
      "current-page": _ctx.currentPage,
      "page-sizes": [20, 30, 50],
      "page-size": _ctx.pageSize,
      layout: "total, sizes, prev, pager, next, jumper",
      total: _ctx.tableDataLength,
      style: {"float":"right"}
    }, null, 8, ["onSizeChange", "onCurrentChange", "current-page", "page-size", "total"])
  ], 4))
}