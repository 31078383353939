
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Base64 } from 'js-base64'
import workhoursservice from '../../services/workhours/workhours'
import registerservice from '../../services/register/register'
import depservice from '../../services/projectmanager/department'
export default {
  data() {
    var validateUser = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('用户姓名不能为空'))
      } else {
        var reg = /^[\u4E00-\u9FA5]+$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('只允许汉字'))
        }
      }
    }
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('用户账号不能为空'))
      } else {
        var reg = /^[a-zA-Z0-9]+$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('只允许字母数字'))
        }
      }
    }
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        var reg = /^1[3|4|5|7|8]{1}[0-9]{9}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('格式不对'))
        }
      }
    }
    var validatePassword = (rule, value, callback) => {
      var reg1 = /^([A-Z]|[a-z]|[0-9]|[!@#$%^&*_.;-?\/\\\[\]])+$/
      var reg2 =
        /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*_.;-?\/\\\[\]]+$)[a-zA-Z\d!@#$%^&*_.;-?\/\\\[\]]+$/
      if (value === '') {
        callback(new Error('密码不能为空'))
      } else {
        callback()
        // if (reg1.test(value)) {
        //     if (reg2.test(value)) {
        //         if (
        //             this['regForm'].checkPass &&
        //             this['regForm'].checkPass !== ""
        //         ) {
        //             this.$refs['regForm'].validateField("checkPass");
        //         }
        //         callback();
        //     } else {
        //         callback(new Error("字母，数字和符号至少包含2种"));
        //     }
        // } else {
        //     callback(
        //         new Error(
        //             "字母，数字和!@#$%^&*_.;-?/[](英文符号),不允许空格"
        //         )
        //     );
        // }
      }
    }
    var validatecheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('密码不能为空'))
      } else {
        if (value === this['regForm'].password) {
          callback()
        } else {
          callback(new Error('两次输入密码不一致'))
        }
      }
    }
    var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('邮箱不能为空'))
      } else {
        var reg = /\w[-\w.+]*@([-A-Za-z0-9]+\.)+[A-Za-z]{1,14}/
        // var reg = /^[A-Za-z0-9\u4e00-\u9fa5_.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('邮箱格式错误，请检查'))
        }
      }
    }
    return {
      userList: [],
      depList: [],
      calcHeight: document.documentElement.clientHeight,
      regForm: {
        code: '',
        user: '',
        password: '',
        checkPass: '',
        phone: '',
        email: '',
        verificationCode: '',
        channel: '',
      },
      rules: {
        code: [
          {
            required: true,
            validator: validateCode,
            trigger: 'blur',
          },
          // {
          //     required: true,
          //     validator: validateCode,
          //     trigger: "blur"
          // },
          // {

          //     min: 1,
          //     max: 20
          // }
        ],
        user: [
          {
            required: true,
            validator: validateUser,
            trigger: 'blur',
          },
          {
            min: 1,
            max: 20,
            message: '长度要求1~20个字符',
            trigger: 'blur',
          },
        ],
        // phone: [
        // 	{required: true, validator: validatePhone, trigger: 'blur'},
        // ],
        password: [
          {
            required: true,
            validator: validatePassword,
            trigger: 'blur',
          },
          {
            min: 1,
            max: 20,
            message: '长度要求1~20个字符',
            trigger: 'blur',
          },
        ],
        dep: [
          {
            required: true,
            message: '请选择组织',
            trigger: 'change',
          },
        ],
        // org: [
        //     {
        //         required: true,
        //         message: "请选择组织",
        //         trigger: "change"
        //     }
        // ],
        checkPass: [
          {
            required: true,
            validator: validatecheckPass,
            trigger: 'blur',
          },
        ],
        // email: [
        // 	{required: true, validator: validateEmail, trigger: 'blur'},
        // ],
        // verificationCode: [
        // 	{required: true, message: '验证码不能为空', trigger: 'blur'}
        // ],
      },
      instituteOptions: [
        {
          value: '0',
          text: '平台软件所',
        },
        {
          value: '1',
          text: '应用软件所',
        },
        {
          value: '2',
          text: '结构所',
        },
        {
          value: '3',
          text: '系统所',
        },
        {
          value: '4',
          text: '预研所',
        },
        {
          value: '5',
          text: '部品所',
        },
        {
          value: '6',
          text: '硬件一所',
        },
        {
          value: '7',
          text: '硬件二所',
        },
        {
          value: '8',
          text: '研发管理室',
        },
        {
          value: '9',
          text: '项目管理室',
        },
      ],
    }
  },
  mounted() {
    this.getAllUser()
    this.getDep()
  },
  methods: {
    async getDep() {
      const items: any = await depservice.getDep({})
      if (items.success) {
        this.depList = items.rows
      } else {
        this.$message.warning('获取组织信息失败')
      }
    },
    async getAllUser() {
      const items: any = await workhoursservice.getAllUser({})
      if (items.success) {
        this.userList = items.rows
      } else {
        this.$message.warning('获取用户信息失败')
      }
    },
    async addUser() {
      var params = {
        code: this.regForm.code,
        password: Base64.encode(this.regForm.password),
        name: this.regForm.user,
        org_code: this.regForm.org,
        pro_rank: this.regForm.pro_rank,
        pro_title: this.regForm.pro_title,
        dep_code: this.regForm.dep,
        sex: '0',
        email: this.regForm.email,
        mobile: this.regForm.phone,
        create_by: '',
        modified_by: '',
        audit: this.regForm.audit,
        role: 'pww',
      }
      const result: any = await registerservice.register(params)
      if (result.success) {
        this.$message.success('注册成功')
        this.$router.go(-1)
      } else {
        this.$message.error(result.err)
      }
    },
    cancel() {
      this.$router.go(-1)
    },
    reg() {
      var self = this
      this.$refs['regForm'].validate((valid) => {
        if (valid) {
          self.addUser()
        } else {
          return false
        }
      })
    },
  },
}
