
import { Search as ElIconSearch } from '@element-plus/icons-vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as _ from 'lodash'
import Date from '../../components/Date.vue'
import workhoursservice from '../../services/workhours/workhours'
import assessservice from '../../services/assess/assessservice'

export default {
  data() {
    return {
      dialogVisible: false,
      currentPage: 1,
      total: 0,
      pageSize: 10,
      lodash: _,
      form: {},
      calcHeight: document.documentElement.clientHeight - 60,
      options: [
        {
          value: 0,
          label: '月度',
        },
        {
          value: 1,
          label: '季度',
        },
        {
          value: 2,
          label: '年度',
        },
      ],
      typeOptions: [
        {
          value: '1',
          label: '总监',
        },
        {
          value: '2',
          label: '所长',
        },
        {
          value: '3',
          label: '专家',
        },
        {
          value: '4',
          label: '项目经理',
        },
        {
          value: '5',
          label: '员工',
        },
      ],
      timeType: 'month',
      timeTypeValue: '',
      tableData: [],
      active: 1,
      loading: false,
      formInline: {},
      templateItems: [],
      quarterLabel: '',
      rules: {
        dimension: [
          {
            required: true,
            message: '请选择评价周期',
            trigger: 'change',
          },
        ],
        timeValue: [
          { required: true, message: '请选择时间', trigger: 'change' },
        ],
        type: [
          {
            required: true,
            message: '请选择评价类型',
            trigger: 'change',
          },
        ],
      },
      loadingUser: false,
      tempParams: [],
      multipleSelection: [],
      gradeOptions: [
        {
          value: 0,
          label: '0',
        },
        {
          value: 1,
          label: '1',
        },
        {
          value: 3,
          label: '3',
        }
      ],
      userList: [],
      multipleSelection1: [],
      userName: '',
      ElIconSearch,
    }
  },
  components: {
    Date,
  },
  mounted() {
    this.getAllUser()
  },
  methods: {
    cancelAction() {
      this.active = 1 
      this.multipleSelection = []
    },
    add() {
      this.currentPage = 1
      this.userName = ''
      this.getAllUser()
      this.dialogVisible = true
      this.multipleSelection1 = []
    },
    confirm() {
      var newArr = this.tableData.concat(this.multipleSelection1)
      this.tableData = newArr
      this.dialogVisible = false
    },
    queryClick() {
      ;(this.currentPage = 1), this.getAllUser()
    },
    handleSelectionChange1(val) {
      this.multipleSelection1 = val
    },
    handlePagenumChange(val) {
      this.currentPage = val
      this.getAllUser()
    },
    async getAllUser() {
      const params = {
        name: this.userName,
        currentPage: this.currentPage,
        pageSize: 10,
      }
      const items: any = await workhoursservice.getAllUser(params)
      if (items.success) {
        this.userList = items.rows
        this.total = items.total
      } else {
        this.$message.warning(items.msg)
      }
    },
    gradeChange(row, index, key, val, weight, scope) {
      if (0 <= val && val <= 5 && String(val).indexOf('.') == -1) {
        row.total = 0
        var columns = scope.store.states.columns
        var rows = scope.store.states.data
        columns.forEach((column, index) => {
          if (
            column.property == 'code' ||
            column.property == 'name' ||
            column.property == 'remark' ||
            column.property == 'total'
          ) {
            return
          }
          if (!isNaN(Number(row[column.property]))) {
            var weight = this.lodash.find(this.templateItems, {
              item_code: column.property,
            }).weight
            var grade = Number(row[column.property]) * weight
            row.total = this.adddot(row.total, grade)
          }
        })
        row.total = row.total / 100
        this.multipleSelection[index] = row
      } else {
        this.$message.error('分数不能大于5且不为小数')
        var obj = _.cloneDeep(row)
        obj[key] = ''
        this.multipleSelection[index] = obj
      }
    },
    // 小数点相加
    adddot() {
      var args = arguments, //获取所有的参数
        lens = args.length, //获取参数的长度
        d = 0, //定义小数位的初始长度，默认为整数，即小数位为0
        sum = 0 //定义sum来接收所有数据的和
      //循环所有的参数
      for (var key in args) {
        //遍历所有的参数
        //把数字转为字符串
        var str = '' + args[key]
        if (str.indexOf('.') != -1) {
          //判断数字是否为小数
          //获取小数位的长度
          var temp = str.split('.')[1].length
          //比较此数的小数位与原小数位的长度，取小数位较长的存储到d中
          d = d < temp ? temp : d
        }
      }
      //计算需要乘的数值
      var m = Math.pow(10, d)
      //遍历所有参数并相加
      for (var key in args) {
        sum += args[key] * m
      }
      //返回结果
      return sum / m
    },
    dimensionChange(val) {
      if (val == 0) {
        this.timeType = 'month'
      }
      if (val == 1) {
        this.timeType = 'quarter'
      }
      if (val == 2) {
        this.timeType = 'year'
      }
    },
    getValue(val, label) {
      this.form.timeValue = val
      this.quarterLabel = label
    },
    typeChange(val) {
      this.getUsers(val)
    },
    async getProjectMem(val) {
      this.loadingUser = true
      var userInfoString = localStorage.getItem('userInfo')
      var userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
      const params = {
        name: userInfo['name'],
        tag: val,
      }
      const items: any = await workhoursservice.getProjectMem(params)
      if (items.success) {
        this.tableData = items.rows
        for (var i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].name == userInfo['name']) {
            this.tableData.splice(i, 1)
            break
          }
        }
      } else {
        this.$message.warning(items.msg)
      }
      this.loadingUser = false
    },
    async getUsers(val) {
      this.loadingUser = true
      var userInfoString = localStorage.getItem('userInfo')
      var userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
      const params = {
        tag: val,
        currentPage: 1,
        pageSize: 600,
      }
      if (val == '5') {
        if (userInfo.tag == '3' || userInfo.tag == '4') {
          this.getProjectMem(userInfo.tag)
          return
          // params["dep_code"] = userInfo.dep_code;
        }
        if (userInfo.tag == '2') {
          params['dep_code'] = userInfo.dep_code
          delete params.tag
        } else {
          this.loadingUser = false
          this.tableData = []
          return
        }
      }
      const items: any = await workhoursservice.getAllUser(params)
      if (items.success) {
        this.tableData = items.rows
      } else {
        this.$message.warning(items.msg)
      }
      this.loadingUser = false
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    async createAssess() {
      var label =
        (this.timeType == 'month'
          ? this.form.timeValue.getFullYear() +
            (this.form.timeValue.getMonth() + 1).toString().padStart(2, '0')
          : this.timeType == 'year'
          ? this.form.timeValue.getFullYear()
          : this.quarterLabel) +
        '-' +
        this.lodash.find(this.typeOptions, { value: this.form.type }).label +
        '评价'
      var userInfoString = localStorage.getItem('userInfo')
      var userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
      var head = {
        name: label,
        time:
          this.timeType == 'month'
            ? this.form.timeValue.getFullYear() +
              (this.form.timeValue.getMonth() + 1).toString().padStart(2, '0')
            : this.timeType == 'year'
            ? this.form.timeValue.getFullYear()
            : this.form.timeValue,
        dimension: this.form.dimension,
        type: this.form.type,
        create_user: userInfo['username'],
      }
      var body = []
      this.tempParams = _.cloneDeep(this.multipleSelection)
      for (var i = 0; i < this.tempParams.length; i++) {
        //this.tempParams[i]["total"] = 0;
        for (var item of this.templateItems) {
          if (this.tempParams[i][item.item_code] === '') {
            this.$message.error('请检查是否有漏评项目')
            return
          } else {
            this.tempParams[i]['time'] =
              this.timeType == 'month'
                ? this.form.timeValue.getFullYear() +
                  (this.form.timeValue.getMonth() + 1)
                    .toString()
                    .padStart(2, '0')
                : this.timeType == 'year'
                ? this.form.timeValue.getFullYear()
                : this.form.timeValue
            this.tempParams[i]['dimension'] = this.form.dimension
            this.tempParams[i]['type'] = this.form.type
            this.tempParams[i]['user_code'] = this.tempParams[i]['code']
            this.tempParams[i]['user_name'] = this.tempParams[i]['name']
            this.tempParams[i]['total'] = this.tempParams[i]['total']
            this.tempParams[i]['remark'] = this.tempParams[i]['remark']
            // this.tempParams[i][item.item_code] = (
            //     this.tempParams[i][item.item_code] *
            //     (item.weight / 100)
            // ).toFixed(2);
            // this.tempParams[i]["total"] =
            //     this.tempParams[i]["total"] +
            //     Number(this.tempParams[i][item.item_code]);
          }
        }
        body.push(this.tempParams[i])
      }
      var params = {
        head: head,
        body: body,
      }
      const items: any = await assessservice.addAssess(params)
      if (items.success) {
        this.$message.success('评价成功')
        this.active = 1
      } else {
        this.$message.warning(items.msg)
      }
    },
    checkRules() {
      var self = this
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (self.multipleSelection.length == 0) {
            self.$message.error('请选择人员')
            return
          }
          self.createTemplate()
        } else {
          return false
        }
      })
    },
    async createTemplate() {
      this.loading = true
      const params = {
        tag: this.form.type,
      }
      const items: any = await assessservice.getTemplate(params)
      if (items.success) {
        this.templateItems = items.rows
        for (var i = 0; i < this.multipleSelection.length; i++) {
          for (var item of this.templateItems) {
            this.multipleSelection[i][item.item_code] = ''
          }
          this.multipleSelection[i]['remark'] = ''
          this.multipleSelection[i]['total'] = 0
        }
        this.active = 2
      } else {
        this.$message.warning(items.msg)
      }
      this.loading = false
    },
  },
}
