<template>
  <div id="app1">
    <RouterView />
  </div>
</template>

<style lang="scss">
html, body {
  display: block;
  height: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
#app1 {
  height: 100%;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
div[role="group"] {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    align-items: flex-end;
}
</style>
