import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../../config/index'

const apiPath = {
  costdown: '/mpapi/department',
}

async function getDep(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.costdown, param)
  return result.data
}

export default {
  getDep,
}
