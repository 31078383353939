
import { $on, $off, $once, $emit } from '../../../utils/gogocodeTransfer'
import { Component, Vue, Watch } from 'vue-facing-decorator'
import workhoursservice from '../../../services/workhours/workhours'
import projectService from '../../../services/projectmanager/projectservice'
import orgService from '../../../services/org/orgservice'
import cyTreeSelect from '../../../components/selectTree.vue'
import moment from 'moment'
import * as _ from 'lodash'
import buildOrgTree from '../../../util'

@Component({
  components: {
    cyTreeSelect,
  },
})
export default class WeekReport extends Vue {

  mounted() {
    this.getAllUser()
    this.getProject()
    this.getOrgInfo()
    this.init('project')
  }
      userInfoString = localStorage.getItem('userInfo')
      userInfo = this.userInfoString !== null ? JSON.parse(this.userInfoString) : {}
      userRoleString = localStorage.getItem('userRole')
      userRoleOp = this.userRoleString !== null ? JSON.parse(this.userRoleString) : {}
      calcHeight = document.documentElement.clientHeight - 280
      projectNameList = []
      projectList = []
      userList = []
      proList = []
      loading = false
      currentPage = 1
      tableDataLength = 0
      tableDataLength1 = 0
      tableData = []
      tableData1 = []
      setTree = []
      orgCode = ''
      userRole = this.userRoleOp[0]
      pageSize = 15
      login_code = this.userRoleOp[0].role_id === 'pww' ? this.userInfo.username : ''
      project_code = ''
      project_name = ''
      contactArray = []
      contactDot = 0
      contactArray1 = []
      contactDot1 = 0
      contactArray2 = []
      contactDot2 = 0
      contactArray3 = []
      contactDot3 = 0
      contactPersonalArray = []
      contactPersonalDot = 0
      contactPersonalArray1 = []
      contactPersonalDot1 = 0
      contactPersonalArray2 = []
      contactPersonalDot2 = 0
      contactPersonalArray3 = []
      contactPersonalDot3 = 0
      date = [
        moment().startOf('week').add(1, 'days').format('YYYY-MM-DD'),
        moment().endOf('week').add(1, 'days').format('YYYY-MM-DD'),
      ]
      date1 = [
        moment().startOf('week').add(1, 'days').format('YYYY-MM-DD'),
        moment().endOf('week').add(1, 'days').format('YYYY-MM-DD'),
      ]
      pickerOptions = {
        shortcuts: [
          {
            text: '本周',
            value() {
              const start = moment()
                .startOf('week')
                .add(1, 'days')
                .format('YYYY-MM-DD')
              const end = moment()
                .endOf('week')
                .add(1, 'days')
                .format('YYYY-MM-DD')
              return [start, end]
            },
          },
          {
            text: '上周',
            value() {
              const start = moment()
                .subtract(1, 'weeks')
                .startOf('week')
                .add(1, 'days')
                .format('YYYY-MM-DD')
              const end = moment()
                .subtract(1, 'weeks')
                .endOf('week')
                .add(1, 'days')
                .format('YYYY-MM-DD')
              return [start, end]
            },
          },
        ],
      }

    @Watch('orgCode')
    private orgCodeChanged(val: string, old: string) {
      if (val !== '') {
        this.login_code = ''
      }
    }
    
    @Watch('login_code')
    private loginCodeChanged(val: string, old: string) {
      if (val !== '') {
        this.orgCode = ''
      }
    }

   async getProject() {
    let params = {
      project_status: JSON.stringify(['0', '1', '2', '3', '4', '5']),
    }

    const items: any = await projectService.getOnlyProject({ params: params })
    if (items.success) {
      var temp = items.rows
      this.proList = items.rows
      for (var i = 0; i < temp.length; i++) {
        var obj = {}
        var name = {}
        obj['value'] = temp[i].project_code
        obj['name'] = temp[i].project_name
        obj['type'] = temp[i].project_type
        name['value'] = temp[i].project_name
        name['name'] = temp[i].project_code
        name['type'] = temp[i].project_type
        this.projectList.push(obj)
        this.projectNameList.push(name)
      }
    } else {
      this.$message.warning(items.msg)
    }
  }
   async getAllUser() {
    const items: any = await workhoursservice.getAllUser({})
    if (items.success) {
      this.userList = items.rows
    } else {
      this.$message.warning(items.msg)
    }
  }
   async init(type: string) {
    this.loading = true
    const params = {
      currentPage: this.currentPage,
      pageSize: this.pageSize,
      login_code: this.login_code,
      project_code: this.project_code,
      startDate: this.date ? this.date[0] : this.date,
      endDate: this.date ? this.date[1] : this.date,
      orgCode: this.orgCode,
      type,
    }
    const hoursResult: any = await workhoursservice.getWeekWorkHours(params)
    if (hoursResult.success) {
      if (type === 'project') {
        this.tableData = hoursResult.rows
        this.tableDataLength = hoursResult.total
        this.normalrizeData()
      } else {
        this.tableData1 = hoursResult.rows
        this.tableDataLength1 = hoursResult.total
        this.normalrizeDataPersonal()
      }
    } else {
      this.$message.warning(hoursResult.msg)
    }
    this.loading = false
  }
  async getOrgInfo() {
      var self = this
      const result: any = await orgService.getOrgInfo()
      // this.orgList = _.cloneDeep(result.rows)
      // this.getUserList();
      self.setTree = buildOrgTree(result.rows)
    }
   handleSelect(code) {}
   handleSizeChange(val) {
    this.pageSize = val
    this.init('project')
  }
   handlePagenumChange(val) {
    this.currentPage = val
    this.init('project')
  }
   handleCurrentChange(val) {
    this.currentRow = val
  }
   querySearch1(queryString, cb) {
    var restaurants = this.projectNameList
    var results = queryString
      ? restaurants.filter(this.createFilter1(queryString))
      : restaurants
    // 调用 callback 返回建议列表的数据
    cb(results)
  }
   createFilter1(queryString) {
    return (restaurant) => {
      return (
        restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) != -1
      )
    }
  }
   handleSelect1(code) {
    var name = _.find(this.projectNameList, {
      value: code,
    })['name']
    this.project_code = name
  }
   objectSpanMethod({ row, column, rowIndex, columnIndex }) {
    if (columnIndex === 0) {
      const _row = this.contactArray[rowIndex]
      const _col = _row > 0 ? 1 : 0
      return {
        rowspan: _row,
        colspan: _col,
      }
    }
    if (columnIndex === 1) {
      const _row = this.contactArray1[rowIndex]
      const _col = _row > 0 ? 1 : 0
      return {
        rowspan: _row,
        colspan: _col,
      }
    }
  }
   normalrizeData() {
    this.contactArray = []
    this.contactArray1 = []
    this.contactArray2 = []
    this.tableData.map((item, index) => {
      if (index === 0) {
        this.contactArray.push(1)
        this.contactDot = 0
        this.contactArray1.push(1)
        this.contactDot1 = 0
        this.contactArray2.push(1)
        this.contactDot2 = 0
        this.contactArray3.push(1)
        this.contactDot3 = 0
      } else {
        if (item.project_code == this.tableData[index - 1].project_code) {
          this.contactArray[this.contactDot] += 1
          this.contactArray.push(0)
        } else {
          this.contactArray.push(1)
          this.contactDot = index
        }
        if (item.project_name == this.tableData[index - 1].project_name) {
          this.contactArray1[this.contactDot1] += 1
          this.contactArray1.push(0)
        } else {
          this.contactArray1.push(1)
          this.contactDot1 = index
        }
      }
    })
  }
   objectSpanPersonalMethod({ row, column, rowIndex, columnIndex }) {
    if (columnIndex === 0) {
      const _row = this.contactPersonalArray[rowIndex]
      const _col = _row > 0 ? 1 : 0
      return {
        rowspan: _row,
        colspan: _col,
      }
    }
    if (columnIndex === 1) {
      const _row = this.contactPersonalArray1[rowIndex]
      const _col = _row > 0 ? 1 : 0
      return {
        rowspan: _row,
        colspan: _col,
      }
    }
  }
   normalrizeDataPersonal() {
    this.contactPersonalArray = []
    this.contactPersonalArray1 = []
    this.contactPersonalArray2 = []
    this.tableData1.map((item, index) => {
      if (index === 0) {
        this.contactPersonalArray.push(1)
        this.contactPersonalDot = 0
        this.contactPersonalArray1.push(1)
        this.contactPersonalDot1 = 0
        this.contactPersonalArray2.push(1)
        this.contactPersonalDot2 = 0
        this.contactPersonalArray3.push(1)
        this.contactPersonalDot3 = 0
      } else {
        if (item.login_code == this.tableData1[index - 1].login_code) {
          this.contactPersonalArray[this.contactPersonalDot] += 1
          this.contactPersonalArray.push(0)
        } else {
          this.contactPersonalArray.push(1)
          this.contactPersonalDot = index
        }
        if (
          item.login_code == this.tableData1[index - 1].login_code &&
          item.project_code == this.tableData1[index - 1].project_code
        ) {
          this.contactPersonalArray1[this.contactPersonalDot1] += 1
          this.contactPersonalArray1.push(0)
        } else {
          this.contactPersonalArray1.push(1)
          this.contactPersonalDot1 = index
        }
        if (
          item.login_code == this.tableData1[index - 1].login_code &&
          item.project_code == this.tableData1[index - 1].project_code &&
          item.project_name == this.tableData1[index - 1].project_name
        ) {
          this.contactPersonalArray2[this.contactPersonalDot1] += 1
          this.contactPersonalArray2.push(0)
        } else {
          this.contactPersonalArray2.push(1)
          this.contactPersonalDot2 = index
        }
      }
    })
  }
}
