
import { Component, Vue } from 'vue-facing-decorator'
import workhoursservice from '../../services/workhours/workhours'
import orgService from '../../services/org/orgservice'
import * as _ from 'lodash'

@Component
export default class ClassManage extends Vue {
  // public parentItems: any[]
  // public childItems: any[]
  // public dep_code: string
  // public depList: any[]
  // public rules: any[]
  // public formModel: any
  // public activeItem: any
  // public modelType: string
  // public title: string
  // public addDialogVisible: boolean

      parentItems = []
      childItems = []
      rules = {
        code: [
          {
            required: true,
            message: '编码不能为空',
            trigger: 'change, blur',
          },
        ],
        name: [
          {
            required: true,
            message: '名称不能为空',
            trigger: 'change, blur',
          },
        ],
        dep_code: [
          {
            required: true,
            message: '所属部门不能为空',
            trigger: 'change, blur',
          },
        ],
      }
      depList = []
      formModel = {}
      activeItem = ''
      title = ''
      modelType = 'add'
      addDialogVisible = false
      dep_code = '001001'

  created() {
    this.getAllClass(null)
    this.getOrgInfo()
  }

  public edit(row) {
    this.modelType = 'edit'
    this.title = '编辑分类'
    this.formModel = _.cloneDeep(row)
    this.addDialogVisible = true
  }

  public add() {
    this.modelType = 'add'
    this.title = '新增分类'
    this.formModel = {
      dep_code: '',
      name: '',
      parent_id: null,
      value: '',
    }
    this.addDialogVisible = true
  }

  public saveForm() {
    var self = this
    ;(this.$refs['formModel'] as HTMLFormElement).validate((valid) => {
      if (valid) {
        self.submitForm()
      } else {
        return false
      }
    })
  }

  async submitForm() {
    let result: any
    if (this.modelType === 'add') {
      result = await workhoursservice.addWorkHourClass(this.formModel)
    } else {
      result = await workhoursservice.updateWorkHourClass(this.formModel)
    }
    if (result.success) {
      this.$message({
        type: 'success',
        message: '处理成功',
      })
      this.addDialogVisible = false
      this.query()
    } else {
      this.$message({
        type: 'error',
        message: result.err,
      })
    }
  }

  // 获取组织信息
  async getOrgInfo() {
    const result: any = await orgService.getOrgInfo()
    this.depList = _.filter(result.rows, function (o) {
      return o.code.length === 6
    })
  }

  // 查询
  public query() {
    this.getAllClass(null)
  }

  // 获取分类数据
  public async getAllClass(parentId) {
    const params = {
      parent_id: parentId,
      dep_code: this.dep_code,
    }
    const typeResult: any = await workhoursservice.getWorkHourClass(params)
    if (!parentId) {
      this.parentItems = _.filter(typeResult.rows, { parent_id: null })
      this.activeItem = this.parentItems[0].name
      this.getAllClass(this.parentItems[0].id)
    } else {
      this.childItems = typeResult.rows
    }
  }

  // 删除某分类
  public deleteItem(item) {
    const self = this
    this.getAllClass(item.id)
    this.$confirm('确定删除?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then(() => {
        console.log(self.childItems)
        console.log(self.childItems.length)
        if (self.childItems.length > 0) {
          self.$message({
            type: 'error',
            message: '有下级分类，不能删除！',
          })
        } else {
          self.deleteNode(item)
        }
      })
      .catch(() => {
        return
      })
  }

  public deleteChildItem(item) {
    const self = this
    this.$confirm('确定删除?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    })
      .then(() => {
        self.deleteNode(item)
      })
      .catch(() => {
        return
      })
  }

  async deleteNode(params) {
    const typeResult: any = await workhoursservice.deleteWorkHourClass({
      params,
    })
    if (typeResult.success) {
      this.query()
    } else {
      this.$message({
        type: 'error',
        message: typeResult.err,
      })
    }
  }

  public changeActive(item) {
    this.activeItem = item.name
    this.getAllClass(item.id)
  }

  public tabClick(instance) {}
}
