
import { Vue, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import moment from 'moment'
import * as _ from 'lodash'
import cyTreeSelect from '../../components/selectTree.vue'
import workhoursservice from '../../services/workhours/workhours'
import projectService from '../../services/projectmanager/projectservice'
import loginService from '../../services/login'
import productservice from '../../services/projectmanager/productservice'
import orgService from '../../services/org/orgservice'
import buildOrgTree from '../../util'

export default {
  components: {
    cyTreeSelect,
  },
  mounted() {
    //this.loading = true;
    this.init()
    this.getProduct()
    this.getProject()
    this.getAllUser()
    this.getOrgInfo()
  },
  data() {
    const userInfoString = localStorage.getItem('userInfo')
    const userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
    return {
      lodash: _,
      searchWord: '',
      orgCode: '',
      org_type: '',
      user_type: '0',
      orgList: [],
      setTree: [],
      tableData: [],
      currentPage: 1,
      tableDataLength: 0,
      pageSize: 20,
      loading: false,
      calcHeight: document.documentElement.clientHeight - 60,
      options: [
        {
          value: '1',
          label: '预研',
        },
        {
          value: '2',
          label: '设计',
        },
        {
          value: '3',
          label: '开发',
        },
        {
          value: '4',
          label: '实施',
        },
        {
          value: '5',
          label: '支持',
        },
        {
          value: '6',
          label: '其他',
        },
      ],
      productOptions: '',
      product_code: '',
      project_code: '',
      projectList: [],
      projectNameList: [],
      userList: [],
      login_code: userInfo.username,
      date: [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD'),
      ],
      addDialogVisible: false,
      addTable: [],
      title: '',
    }
  },

  methods: {
    // 初始化获取信息
    async init() {
      this.loading = true
      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        login_code: this.login_code,
        product_code: this.product_code,
        project_code: this.project_code,
        startDate: this.date ? this.date[0] : this.date,
        endDate: this.date ? this.date[1] : this.date,
        orgCode: this.orgCode,
        org_type: this.org_type,
        user_type: this.user_type,
      }
      if (this.user_type === '1') {
        const user: any = _.filter(this.userList, { value: this.login_code })[0]
        params.login_code = user.name
      }
      const hoursResult: any = await workhoursservice.getWorkHoursRecord(params)
      if (hoursResult.success) {
        this.tableData = hoursResult.rows
        this.tableDataLength = hoursResult.total
      } else {
        this.$message.warning(hoursResult.msg)
      }
      this.loading = false
    },
    // 获取组织信息
    async getOrgInfo() {
      var self = this
      const result: any = await orgService.getOrgInfo()
      this.orgList = _.cloneDeep(result.rows)
      // this.getUserList();
      self.setTree = buildOrgTree(result.rows)
    },
    editProject(row) {
      this.title = '工时详情'
      this.addDialogVisible = true
      this.addTable = []
      this.getWorkHoursRecordItem(row)
    },
    async getWorkHoursRecordItem(row) {
      const params = {
        id: row.id,
        login_code: row.login_code,
        project_code: row.project_code,
        startDate: this.date ? this.date[0] : this.date,
        endDate: this.date ? this.date[1] : this.date,
      }
      const hoursResult: any = await workhoursservice.getWorkHoursRecordItem(
        params
      )
      if (hoursResult.success) {
        this.addTable = hoursResult.rows
      } else {
        this.$message.warning(hoursResult.msg)
      }
    },
    async getProduct() {
      const params = {
        currentPage: 1,
        pageSize: 300,
      }
      const result: any = await productservice.getProductList(params)
      if (result.success) {
        this.productOptions = result.rows
      } else {
        this.$message.warning(result.msg)
      }
    },
    async getAllUser() {
      const items: any = await workhoursservice.getAllUser({})
      if (items.success) {
        var temp = items.rows
        for (var i = 0; i < temp.length; i++) {
          var obj = {}
          obj['value'] = temp[i].code
          obj['name'] = temp[i].name
          this.userList.push(obj)
        }
      } else {
        this.$message.warning(items.msg)
      }
    },
    async getProject() {
      const items: any = await projectService.getOnlyProject({})
      if (items.success) {
        var temp = items.rows
        for (var i = 0; i < temp.length; i++) {
          var obj = {}
          var name = {}
          obj['value'] = temp[i].project_code
          obj['name'] = temp[i].project_name
          name['value'] = temp[i].project_name
          name['name'] = temp[i].project_code
          this.projectList.push(obj)
          this.projectNameList.push(name)
        }
      } else {
        this.$message.warning(items.msg)
      }
    },
    querySearch1(queryString, cb) {
      var restaurants = this.projectNameList
      var results = queryString
        ? restaurants.filter(this.createFilter1(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter1(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        )
      }
    },
    async exportExcel() {
      const string1 =
        '?login_code=' +
        this.login_code +
        '&product_code=' +
        this.product_code +
        '&org_type=' + 
        this.org_type + 
        '&orgCode=' + 
        this.orgCode +
        '&user_type=' + 
        this.user_type + 
        '&project_code=' +
        this.project_code +
        '&startDate=' +
        (this.date.length != 0 ? this.date[0] : '') +
        '&endDate=' +
        (this.date.length != 0 ? this.date[1] : '')
      const result: any = await workhoursservice.workhoursExcelRecords(
        string1,
        (res) => {
          const blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
          })
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) //创建下载的链接
          downloadElement.href = href
          downloadElement.download = 'xxx.xlsx' //下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() //点击下载
          document.body.removeChild(downloadElement) //下载完成移除元素
          window.URL.revokeObjectURL(href) //释放掉blob对象
        }
      )
    },
    async exportExcelTotal() {
      const string1 =
        '?login_code=' +
        this.login_code +
        '&product_code=' +
        this.product_code +
        '&org_type=' + 
        this.org_type + 
        '&orgCode=' + 
        this.orgCode +
        '&user_type=' + 
        this.user_type + 
        '&project_code=' +
        this.project_code +
        '&startDate=' +
        (this.date.length != 0 ? this.date[0] : '') +
        '&endDate=' +
        (this.date.length != 0 ? this.date[1] : '')
      const result: any = await workhoursservice.workhoursExcelRecordsTotal(
        string1,
        (res) => {
          const blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
          })
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) //创建下载的链接
          downloadElement.href = href
          downloadElement.download = 'xxx.xlsx' //下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() //点击下载
          document.body.removeChild(downloadElement) //下载完成移除元素
          window.URL.revokeObjectURL(href) //释放掉blob对象
        }
      )
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    handlePagenumChange(val) {
      this.currentPage = val
      this.init()
    },

    handleCurrentChange(val) {
      this.currentRow = val
    },

    // 组织选择方式发生变化
    orgchanged(val) {
      this.login_code = ''
      this.user_type = ''
      if (val === '1') {
        this.project_code = ''
      }
    },

    // 用户选择方式发生变化
    userchanged(val) {
      this.org_type = ''
      this.orgCode = ''
      if (val === '1') {
        this.project_code = ''
      }
    },

    queryClick() {
      if (this.org_type && !this.orgCode) {
        alert('请选择组织')
        return
      }

      if (!this.login_code && !this.orgCode) {
        this.$confirm(
          '未选择用户，将查询所有人的数据，查询较慢，确认执行？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).then(async () => {
          ;(this.currentPage = 1), this.init()
        })
      } else {
        ;(this.currentPage = 1), this.init()
      }
    },
  },
}
