import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../../config/index'

const apiPath = {
  files: '/mpapi/files',
}

async function getAttachFile(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.files, param)
  return result.data
}

async function addAttachFile(param: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.files, param)
  return result.data
}

async function deleteAttachFile(param: object): Promise<AxiosResponse> {
  const result = await axios.delete(config.url + apiPath.files, param)
  return result.data
}

export default {
  getAttachFile,
  addAttachFile,
  deleteAttachFile,
}
