// const url = 'http://10.16.12.160:3001';
// const url = 'http://39.106.58.156:3001';
const url =
  process.env.NODE_ENV === 'production'
    ? 'https://project.api.histonetec.com'
    : 'http://192.168.14.11:3005'
// const url = process.env.NODE_ENV === 'production' ? 'https://api.project.hisensehics.com' : 'https://project.api.histonetec.com';
export default {
  url,
}
