<template>
  <el-select
    ref="select"
    :model-value="value"
    placeholder="请选择组织"
    clearable
    :disabled="disabled"
    :filterable="filterable"
    :filter-method="filterMethod"
    @clear="clear"
    @visible-change="visibleChange"
    class="tree-select-container"
  >
    <el-option
      ref="option"
      class="tree-select__option"
      :value="optionData.orgCode"
      :label="optionData.orgName"
    >
    </el-option>
    <el-tree
      ref="tree"
      class="tree-select__tree"
      :node-key="nodeKey"
      :data="data"
      :props="props"
      :default-expanded-keys="['0']"
      highlight-current
      :expand-on-click-node="true"
      :filter-node-method="filterNode"
      @node-click="handleNodeClick"
    ></el-tree>
  </el-select>
</template>

<script>
import { $on, $off, $once, $emit } from '../utils/gogocodeTransfer'
import { Vue } from 'vue-property-decorator'
export default {
  name: 'CyTreeSelect',
  props: {
    // v-model绑定
    value: {
      type: [String, Number],
      default: '',
    },
    // 树形的数据
    data: {
      type: Array,
      default: function () {
        return []
      },
    },
    // 每个树节点用来作为唯一标识的属性
    nodeKey: {
      type: [String, Number],
      default: 'code',
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // tree的props配置
    props: {
      type: Object,
      default: function () {
        return {
          children: 'children',
          label: 'name',
        }
      },
    },
  },
  data() {
    return {
      optionData: {
        orgCode: '',
        orgName: '',
      },
      filterFlag: false,
      language: 'zh',
      nodeKey: 'code',
    }
  },
  watch: {
    value: {
      deep: true,

      handler(val) {
        if (!this.isEmpty(this.data)) {
          this.init(val)
        }
      },

      immediate: true,
    },
    data: function (val) {
      if (!this.isEmpty(val)) {
        this.init(this.value)
      }
    },
  },
  mounted() {
    this.language = localStorage.localeLanguage
  },
  methods: {
    // 是否为空
    isEmpty(val) {
      for (let key in val) {
        return false
      }
      return true
    },
    handleNodeClick(data) {
      $emit(this, 'update:value', data[this.nodeKey])
      this.$refs.select.visible = false
    },
    init(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(val)
          $emit(this, 'querychange')
          const node = this.$refs.tree.getNode(val)
          this.optionData.orgCode = val
          this.optionData.orgName = node.label
        })
      } else {
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(null)
        })
      }
    },
    visibleChange(e) {
      if (e) {
        this.filterFlag && this.$refs.tree.filter('')
        this.filterFlag = false
        const selectDom = this.$refs.tree.$el.querySelector('.is-current')
        setTimeout(() => {
          this.$refs.select.scrollToOption({ $el: selectDom })
        }, 0)
      }
    },
    clear() {
      $emit(this, 'update:value', '')
    },
    filterMethod(val) {
      this.filterFlag = true
      this.$refs.tree.filter(val)
    },
    filterNode(value, data) {
      if (!value) return true
      const label = this.props.label || 'name'
      return data[label].indexOf(value) !== -1
    },
  },
  emits: ['update:value', 'querychange'],
}
</script>

<style lang="scss">
.tree-select__option {
  display: none;
}
.tree-select__tree {
  font-weight: 400;
  .el-tree-node.is-current > .el-tree-node__content {
    color: #409eff;
    font-weight: 700;
  }
}
</style>
