import * as Vue from 'vue'
// import './numberonly'

import permission from './permission'

const install = function (Vue): void {
  window.$vueApp.directive('permission', permission)
}

permission.install = install

export default install
