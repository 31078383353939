
import { Component, Vue } from 'vue-facing-decorator'
import loginService from '../services/login'
import { Base64 } from 'js-base64'
import axios from 'axios'
import * as _ from 'lodash'
import showNoti from '../utils/showNotis'

@Component
export default class Login extends Vue {
  // public form!: any
  // public smsBtnText: string
  // public smsCountDown: number
  public clock: any
  // public smsBtnDisable: boolean
  // public showSmsCode: boolean
  // public randomkey: number
  public smsusers: any

  form = {
    username: '',
    mobile: '',
    password: '',
    smscode: '',
  }

  // public data() {
  //   return {
    smsBtnText = '获取短信验证码'
    smsBtnDisable = false
    showSmsCode = false
    smsCountDown = 60
    randomkey = 0
    showDialog = false
    disableSend = false
    resetmail = ''
    resetBtnText = '发送重置邮件'
      
  //   }
  // }

  public async created() {
    let aa = [1, 2, 3]
    var self = this
    aa.forEach(async function (doc) {
      await self.getSmsUser()
    })
    // for (let item of aa) {
    //   await this.getSmsUser()
    // }
  }

  public mounted() {
    const global: any = window
    global.startLoginPage()
  }

  private async getSmsUser() {
    const result: any = await loginService.getSmsUser()
    if (result.success) {
      return result.rows
    }
  }

  public checkNeedSms() {
    const re = _.find(this.smsusers, { username: this.form.username })
    if (re) {
      this.showSmsCode = true
    } else {
      this.showSmsCode = false
    }
  }

  public async sendRequestMail() {
    if (this.resetmail === '') {
      this.$message({
        message: '请输入邮箱',
        type: 'error',
      })
      return;
    }
    this.disableSend = true
    this.resetBtnText = '发送中...'
    var userInfoString = localStorage.getItem('userInfo')
    var userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
    const param = {
      resetemail: this.resetmail + '@histonetec.com',
      localemail: userInfo.email
    }
    const result: any = await loginService.resetPwdRequest(param)
    this.$message({
      message: result.message,
      type: 'info',
    })
    this.resetBtnText = '发送重置邮件'
    this.disableSend = false
    if (result.success) {
      this.showDialog = false
    }
  }

  private register() {
    this.$router.push('/register')
  }

  private async checkUser() {
    const checkResult: any = await loginService.checkUser({
      username: this.form.username,
    })
    return checkResult
  }

  private async checkCode(code) {
    const url = 'https://itopr.saasp.hisensehics.com/publsvc-sms'
    const result = await axios({
      method: 'post',
      url: url + '/api/checkcode',
      headers: { 'H-App-Id': 1 },
      params: {
        key: this.randomkey,
        code,
      },
      withCredentials: false,
    })
    return result
  }

  private async getSmsCode() {
    const checkResult: any = await this.checkUser()
    if (!checkResult.success) {
      alert(checkResult.msg)
      return
    }
    const url = 'https://itopr.saasp.hisensehics.com/publsvc-sms'
    this.randomkey = Math.random()
    axios({
      method: 'post',
      url: url + '/api/sendsave',
      headers: { 'H-App-Id': 1 },
      params: {
        key: this.randomkey,
        phone: checkResult.data[0].mobile,
        templatecode: '433467',
      },
      withCredentials: false,
    }).then((e) => {
      true === e.data.status
        ? ((this.smsBtnDisable = true),
          (this.clock = setInterval(this.smsBtnIntervalLoop, 1e3)))
        : this.$message({
            message: e.data.data,
            type: 'error',
          })
    })
  }

  private smsBtnIntervalLoop() {
    ;(this.smsBtnText = '发送成功（' + this.smsCountDown + '）'),
      this.smsCountDown > 0
        ? this.smsCountDown--
        : ((this.smsCountDown = 60),
          clearInterval(this.clock),
          (this.smsBtnDisable = false),
          (this.smsBtnText = '重新获取验证码'))
  }

  private onReset() {
    alert(1)
  }

  async onSubmit(evt: any) {
    evt.preventDefault()
    const data: any = await loginService.login({
      username: this.form.username,
      mobile: this.form.mobile,
      password: Base64.encode(this.form.password),
    })

    if (data.success) {
      localStorage.setItem('success-pwd', this.form.password)
      localStorage.setItem('userInfo', JSON.stringify(data.data[0]))
      localStorage.setItem('userRole', JSON.stringify(data.userRole))
      localStorage.setItem('token', data.token)
      localStorage.setItem('notlogin', '0')
      axios.defaults.headers.common['Authorization'] =
        localStorage.getItem('token')
      

      let userRole = data.userRole

      showNoti(data)

      if (data.data[0].loginroute) {
        this.$router.push(data.data[0].loginroute)
        return
      }
      if (userRole[0].role_id == 'leader') {
        this.$router.push('/pmproject/show')
      } else {
        this.$router.push('/home')
      }
    } else {
      alert(data.msg)
      return
    }

    if (this.showSmsCode) {
      const checkResult: any = await this.checkCode(this.form.smscode)
      if (checkResult.data.status !== true) {
        alert('短信验证码错误')
        return
      }
    }
  }
}
