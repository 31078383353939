import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../../config/index'

const apiPath = {
  register: '/register',
}

async function register(params: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.register, params)
  return result.data
}

export default {
  register,
}
