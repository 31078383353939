
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as _ from 'lodash'
import workhoursservice from '../../services/workhours/workhours'
import projectService from '../../services/projectmanager/projectservice'
import productservice from '../../services/projectmanager/productservice'
import loginService from '../../services/login'
import orgService from '../../services/org/orgservice'
export default {
  mounted() {
    this.loading = true
    const userInfoString = localStorage.getItem('userInfo')
    const userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
    const userRoleString = localStorage.getItem('userRole')
    const userRole = userRoleString !== null ? JSON.parse(userRoleString) : {}
    this.audit = userInfo['username']
    this.role = userRole[0]['role_id']
    //this.role == "admin" ? (this.audit = "") : "";
    this.init()
    this.getOrgInfo()
    this.getProduct()
    this.getProject()
    this.getAllUser()
  },
  data() {
    return {
      lodash: _,
      searchWord: '',
      tableData: [],
      percentState: false,
      currentPage: 1,
      tableDataLength: 0,
      pageSize: 20,
      loading: false,
      calcHeight: document.documentElement.clientHeight - 60,
      addDialogVisible: false,
      status: '',
      title: '',
      form: {},
      login_code: '',
      project_code: '',
      date: '',
      formHead: {},
      addTable: [
        {
          project_code: '',
          project_name: '',
          jira_code: '',
          class: '',
          hours: '',
          pro_des: '',
        },
      ],
      projectList: [],
      workStatus: 0,
      statusOptions: [
        {
          value: 0,
          label: '未审核',
        },
        {
          value: 1,
          label: '已审核',
        },
      ],
      projectNameList: [],
      options: [
        {
          value: '1',
          label: '预研',
        },
        {
          value: '2',
          label: '设计',
        },
        {
          value: '3',
          label: '开发',
        },
        {
          value: '4',
          label: '实施',
        },
        {
          value: '5',
          label: '支持',
        },
        {
          value: '6',
          label: '其他',
        },
      ],
      workHoursDetail: {},
      userList: [],
      multipleSelection: [],
      role: '',
      productOptions: '',
      orgList: [],
    }
  },

  methods: {
    // 初始化获取信息
    async init() {
      this.loading = true
      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        login_code: this.login_code,
        audit: this.audit,
        status: this.workStatus,
        project_code: this.project_code,
        startDate: this.date ? this.date[0] : this.date,
        endDate: this.date ? this.date[1] : this.date,
      }
      const hoursResult: any = await workhoursservice.getAuditWorkhoursList(
        params
      )
      if (hoursResult.success) {
        this.tableData = hoursResult.rows
        this.tableDataLength = hoursResult.total
      } else {
        this.$message.warning(hoursResult.msg)
      }
      this.loading = false
    },
    async getOrgInfo() {
      const result: any = await orgService.getOrgInfo()
      if (result.success) {
        this.orgList = result.rows
      } else {
        this.$message.warning(result.msg)
      }
    },
    async getProduct() {
      const params = {
        currentPage: 1,
        pageSize: 300,
      }
      const result: any = await productservice.getProductList(params)
      if (result.success) {
        this.productOptions = result.rows
      } else {
        this.$message.warning(result.msg)
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    selectable(row, index) {
      if (row.status == '1') {
        return false
      }
      return true
    },
    async getWorkHoursDetail(row) {
      const params = {
        id: row.id,
        login_code: row.login_code,
        date: row.date,
      }
      const hoursResult: any = await workhoursservice.getWorkHoursDetail(params)
      if (hoursResult.success) {
        this.addTable = hoursResult.body.rows
        this.formHead = hoursResult.rows[0]
      } else {
        this.$message.warning(hoursResult.msg)
      }
    },
    async batchAudit() {
      var params = []
      var isEight = true
      if (this.multipleSelection.length == 0) {
        this.$message.error('请选择需要批量审批的工时')
        return
      }
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (this.multipleSelection[i].total < 8) {
          isEight = false
        }
        params.push(this.multipleSelection[i].id)
      }
      if (isEight == false) {
        this.$confirm('所选工时包含未满8小时，是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const result: any = await workhoursservice.auditWorkHours(params)
            if (result.success) {
              this.$message.success('审核成功')
              this.init()
            } else {
              this.$message.warning(result.msg)
            }
          })
          .catch((err) => {
            throw err
          })
      } else {
        const result: any = await workhoursservice.auditWorkHours(params)
        if (result.success) {
          this.$message.success('审核成功')
          this.init()
        } else {
          this.$message.warning(result.msg)
        }
      }
    },
    async auditWorkHours(row) {
      const params = [row.id]
      if (row['total'] < 8) {
        this.$confirm('所选工时未满8小时，是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const result: any = await workhoursservice.auditWorkHours(params)
            if (result.success) {
              this.$message.success('审核成功')
              this.init()
            } else {
              this.$message.warning(result.msg)
            }
          })
          .catch((err) => {
            throw err
          })
      } else {
        const result: any = await workhoursservice.auditWorkHours(params)
        if (result.success) {
          this.$message.success('审核成功')
          this.init()
        } else {
          this.$message.warning(result.msg)
        }
      }
    },
    async unAuditWorkHours(row) {
      const params = [row.id]
      const result: any = await workhoursservice.unAuditWorkHours(params)
      if (result.success) {
        this.$message.success('取消审核成功')
        this.init()
      } else {
        this.$message.warning(result.msg)
      }
    },
    close() {
      this.$refs['formHead'].clearValidate()
    },
    editProject(row) {
      this.title = '工时详情'
      this.status = 'edit'
      this.addDialogVisible = true
      this.addTable = []
      this.getWorkHoursDetail(row)
    },

    async getAllUser() {
      const items: any = await workhoursservice.getAllUser({})
      if (items.success) {
        var temp = items.rows
        for (var i = 0; i < temp.length; i++) {
          var obj = {}
          obj['value'] = temp[i].code
          obj['name'] = temp[i].name
          this.userList.push(obj)
        }
      } else {
        this.$message.warning(items.msg)
      }
    },
    async getProject() {
      const items: any = await projectService.getOnlyProject({})
      if (items.success) {
        var temp = items.rows
        for (var i = 0; i < temp.length; i++) {
          var obj = {}
          var name = {}
          obj['value'] = temp[i].project_code
          obj['name'] = temp[i].project_name
          name['value'] = temp[i].project_name
          name['name'] = temp[i].project_code
          this.projectList.push(obj)
          this.projectNameList.push(name)
        }
      } else {
        this.$message.warning(items.msg)
      }
    },
    querySearch(queryString, cb) {
      var restaurants = this.projectList
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        )
      }
    },
    handleSelect(code, index) {
      var name = _.find(this.projectList, {
        value: code,
      })['name']
      this.addTable[index].project_name = name
    },
    querySearch1(queryString, cb) {
      var restaurants = this.projectNameList
      var results = queryString
        ? restaurants.filter(this.createFilter1(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter1(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        )
      }
    },
    handleSelect1(code, index) {
      var name = _.find(this.projectNameList, {
        value: code,
      })['name']
      this.addTable[index].project_code = name
    },

    async deleteWorkhours(row) {
      var userInfoString = localStorage.getItem('userInfo')
      var userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
      var params = {
        id: row.id,
        login_code: userInfo['username'],
        date: row.date,
      }
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const result: any = await workhoursservice.deleteWorkhousrItem({
            params,
          })
          if (result.success) {
            this.$message({
              message: '删除成功',
              type: 'success',
            })
            this.init()
          }
        })
        .catch((err) => {
          throw err
        })
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    handlePagenumChange(val) {
      this.currentPage = val
      this.init()
    },

    handleCurrentChange(val) {
      this.currentRow = val
    },

    queryClick() {
      ;(this.currentPage = 1), this.init()
    },
  },
}
