import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../../config/index'

const apiPath = {
  getTemplate: '/assess/template',
  addAssess: '/assess/list',
  getRecord: '/assess/list',
  getRecordItem: '/assess/list/item',
  getTemplateItem: '/assess/template/item',
}

async function getTemplateItem(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.getTemplateItem, {})
  return result.data
}
async function getTemplate(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.getTemplate, { params })
  return result.data
}
async function addAssess(params: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.addAssess, params)
  return result.data
}
async function getRecord(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.getRecord, { params })
  return result.data
}
async function getRecordItem(params: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.getRecordItem, { params })
  return result.data
}

export default {
  getTemplate,
  addAssess,
  getRecord,
  getRecordItem,
  getTemplateItem,
}
