
import { $on, $off, $once, $emit } from '../../../utils/gogocodeTransfer'
import { Component, Vue, Watch } from 'vue-facing-decorator'
import wpService from '../../../services/projectmanager/rsweekplan'
import projectService from '../../../services/projectmanager/projectservice'
import orgService from '../../../services/org/orgservice'
import workhoursservice from '../../../services/workhours/workhours'
import moment from 'moment'
import * as _ from 'lodash'

@Component
export default class WeekPlanList extends Vue {

      userInfoString = localStorage.getItem('userInfo')
      loading = false
      pickerOptions1: any = {
        firstDayOfWeek: 1,
      }
      lodash = _
      institute = JSON.parse(this.userInfoString).dep_code
      searchDate = null
      search_project_code = ''
      projectNameList = []
      userList = []
      projectList = []
      calcHeight = document.documentElement.clientHeight - 120
      weekplanWeek = ''
      manager = ''
      showtable = true
      currentPage = 1
      items = []
      contactArray = []
      contactDot = 0
      contactArray1 = []
      contactDot1 = 0
      contactArray2 = []
      contactDot2 = 0
      contactArray3 = []
      contactDot3 = 0
      addDialogVisible = false
      editDescDialogVisible = false
      userInfo = this.userInfoString !== null ? JSON.parse(this.userInfoString) : {}
      notClose = false
      formEdittitle = ''
      currentProject_code = ''
      project_all_desc = ''
      username = ''
      pageSize = 20
      tableDataLength = 5
      formItem: any = {}
      rules: any = {
        institute: [
          {
            required: true,
            message: '请选择研究所',
            trigger: 'change',
          },
        ],
        // type: [
        //     { required: true, message: "请选择分类", trigger: "change" }
        // ],
        project_code: [
          {
            required: true,
            message: '请填写项目编码',
            trigger: 'blur',
          },
        ],
        project_name: [
          {
            required: true,
            message: '请填写项目名称',
            trigger: 'change',
          },
        ],
        current_week: [
          {
            required: true,
            message: '请填写本周工作计划',
            trigger: 'blur',
          },
        ],
        current_week_status: [
          {
            required: true,
            message: '请填写本周完成工作',
            trigger: 'blur',
          },
        ],
        next_week: [
          {
            required: true,
            message: '请填写下周计划工作',
            trigger: 'blur',
          },
        ],
        manager: [
          {
            required: true,
            message: '请填写工作负责人',
            trigger: 'blur',
          },
        ],
        weekplanWeek: [
          {
            required: true,
            message: '请选择本周时间',
            trigger: 'blur',
          },
        ],
        planhours: [
          {
            required: true,
            message: '请填写预计工时',
            trigger: 'blur',
          },
        ],
      }
      projecttypeoptions = [
        {
          value: '0',
          text: '降成本',
        },
        {
          value: '1',
          text: '新品',
        },
        {
          value: '2',
          text: '资源项目',
        },
        {
          value: '5',
          text: '新品项目',
        },
        {
          value: '6',
          text: '交付项目',
        },
        {
          value: '7',
          text: '完善项目',
        },
        {
          value: '8',
          text: '支持项目',
        },
        {
          value: '9',
          text: '预研项目',
        },
        {
          value: '10',
          text: '规划项目',
        },
        // {
        //     value: "3",
        //     text: "二次开发"
        // },
        // {
        //     value: "4",
        //     text: "产品预研"
        // }
      ]
      instituteOptions = [
        // {
        //     value: '0',
        //     text: "平台软件所"
        // },
        // {
        //     value: '1',
        //     text: "应用软件所"
        // },
        // {
        //     value: '2',
        //     text: "结构所"
        // },
        // {
        //     value: '3',
        //     text: "系统所"
        // },
        // {
        //     value: '4',
        //     text: "预研所"
        // },
        // {
        //     value: '5',
        //     text: "部品所"
        // },
        // {
        //     value: '6',
        //     text: "硬件一所"
        // },
        // {
        //     value: '7',
        //     text: "硬件二所"
        // },
        // {
        //     value: '8',
        //     text: "研发管理室"
        // },
        // {
        //     value: '9',
        //     text: "项目管理室"
        // }
      ]
      daterangeArea = [
        moment().startOf('week').add(1, 'days').format('YYYY-MM-DD'),
        moment().endOf('week').add(1, 'days').format('YYYY-MM-DD'),
      ]
      pickerOptions:any = {
        shortcuts: [
          {
            text: '本周',
            value() {
              const start = moment()
                .startOf('week')
                .add(1, 'days')
                .format('YYYY-MM-DD')
              const end = moment()
                .endOf('week')
                .add(1, 'days')
                .format('YYYY-MM-DD')
              return [start, end]
            },
          },
          {
            text: '上周',
            value() {
              const start = moment()
                .subtract(1, 'weeks')
                .startOf('week')
                .add(1, 'days')
                .format('YYYY-MM-DD')
              const end = moment()
                .subtract(1, 'weeks')
                .endOf('week')
                .add(1, 'days')
                .format('YYYY-MM-DD')
              return [start, end]
            },
          },
          {
            text: '上月',
            value() {
              const start = moment()
                .subtract(1, 'months')
                .startOf('month')
                .format('YYYY-MM-DD')
              const end = moment()
                .subtract(1, 'months')
                .endOf('month')
                .format('YYYY-MM-DD')
              return [start, end]
            },
          },
          {
            text: '本月',
            value() {
              const end = moment().endOf('month').format('YYYY-MM-DD')
              const start = moment().startOf('month').format('YYYY-MM-DD')
              return [start, end]
            },
          },
        ],
      }

   created() {
    const userInfoString = localStorage.getItem('userInfo')
    const userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
    this.userInfo = userInfo
    this.username = userInfo.username
    // this.institute = userInfo.dep_code
    this.searchWeekPlans()
    this.getProject()
      .then((result) => {})
      .catch((err) => {})
    this.getAllUser()
    this.getOrgInfo()
    var Nowdate: any = new Date()
    var WeekFirstDay: any = new Date(
      Nowdate - (Nowdate.getDay() - 1) * 86400000
    )
    this.searchDate = WeekFirstDay
  }

   searchDateChanged(val) {
    let date = this.searchDate
    this.daterangeArea[0] = moment(val).format('YYYY-MM-DD')
    this.daterangeArea[1] = moment(val).add(6, 'days').format('YYYY-MM-DD')
  }
  async getAllUser() {
    const items: any = await workhoursservice.getAllUser({
      dep_code: this.institute,
    })
    if (items.success) {
      this.userList = items.rows
    } else {
      this.$message.warning(items.msg)
    }
  }
   async getOrgInfo() {
    const result: any = await orgService.getOrgInfo()
    this.instituteOptions = result.rows
  }
   insChange(val) {
    this.formItem['institute'] = val
  }
   typeChange(val) {
    this.formItem['type'] = val
  }
   async getProject() {
    let params = {
      project_status: JSON.stringify(['0', '1', '2', '3', '4']),
    }

    const items: any = await projectService.getOnlyProject({ params: params })
    if (items.success) {
      var temp = items.rows
      for (var i = 0; i < temp.length; i++) {
        var obj = {}
        var name = {}
        obj['value'] = temp[i].project_code
        obj['name'] = temp[i].project_name
        obj['type'] = temp[i].project_type
        name['value'] = temp[i].project_name
        name['name'] = temp[i].project_code
        name['type'] = temp[i].project_type
        this.projectList.push(obj)
        this.projectNameList.push(name)
      }
    } else {
      this.$message.warning(items.msg)
    }
  }
   querySearch(queryString, cb) {
    var restaurants = this.projectList
    var results = queryString
      ? restaurants.filter(this.createFilter(queryString))
      : restaurants
    // 调用 callback 返回建议列表的数据
    cb(results)
  }
   createFilter(queryString) {
    return (restaurant) => {
      return (
        restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) != -1
      )
    }
  }
   handleSelect(code) {
    var name = _.find(this.projectList, {
      value: code,
    })['name']
    var type = _.find(this.projectList, {
      value: code,
    })['type']
    this.formItem['project_name'] = name
    this.formItem['type'] = type
    if (this.formStyle == 'add') {
      this.getLastWeekDes()
    }
    //this.formItem.project_name = name;
  }

   querySearch1(queryString, cb) {
    var restaurants = this.projectNameList
    var results = queryString
      ? restaurants.filter(this.createFilter1(queryString))
      : restaurants
    // 调用 callback 返回建议列表的数据
    cb(results)
  }
   createFilter1(queryString) {
    return (restaurant) => {
      return (
        restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) != -1
      )
    }
  }
   handleSelect1(code) {
    var name = _.find(this.projectNameList, {
      value: code,
    })['name']
    var type = _.find(this.projectNameList, {
      value: code,
    })['type']
    this.formItem['project_code'] = name
    this.formItem['type'] = type
    if (this.formStyle == 'add') {
      this.getLastWeekDes()
    }
    //this.formItem.project_code = name;
  }
   async getLastWeekDes() {
    if (!this.formItem.project_code) {
      return
    }
    let date = this.formItem.weekplanWeek
    if (typeof date == 'object') {
      if (date.getDay() == 2) {
        date = date - 86400000
      }
    }
    let startDate = moment(date).subtract(7, 'days').format('YYYY-MM-DD')
    let endDate = moment(date)
      .add(6, 'days')
      .subtract(7, 'days')
      .format('YYYY-MM-DD')
    const params = {
      lastmodified: this.userInfo.username,
      project_code: this.formItem.project_code,
      startDate: startDate,
      endDate: endDate,
    }
    //this.loading = true
    const result: any = await wpService.getLastWeekDes(params)
    //this.loading = false
    if (result.success) {
      if (result.rows.length == 0) {
        // this.$set(this.formItem, 'current_week', '')
      } else {
        this.formItem['current_week'] = result.rows[0].next_week
        if (this.formItem.current_week) {
          this.formItem.current_week = this.formItem.current_week.replace(
            /<br\/>/g,
            '\r\n'
          )
        }
      }
    } else {
      this.formItem['current_week'] = ''
      this.loading = false
    }
  }
   close() {
    ;(this.$refs['formItem'] as any).clearValidate()
  }
   async searchWeekPlans() {
    const params = {
      currentPage: this.currentPage,
      pageSize: this.pageSize,
      institute: this.institute,
      manager: this.manager,
      project_code: this.search_project_code,
      startDate: moment(this.daterangeArea[0]).format('YYYY-MM-DD'),
      endDate: moment(this.daterangeArea[1]).format('YYYY-MM-DD'),
    }
    this.showtable = false
    const result: any = await wpService.getWeekplan(params)
    if (result.success) {
      this.items = result.rows
      this.tableDataLength = result.total
    } else {
      this.$message.error('获取数据失败')
    }
    this.normalrizeData()
    this.showtable = true
    // const table: any = this.$refs.singleTable;
    // table.doLayout();
  }

   async exportExcel() {
    const string1 =
      '?currentPage=' +
      1 +
      '&pageSize=' +
      10000 +
      '&startDate=' +
      moment(this.daterangeArea[0]).format('YYYY-MM-DD') +
      '&endDate=' +
      moment(this.daterangeArea[1]).format('YYYY-MM-DD') + 
      '&institute=' + this.institute +
      '&manager=' + this.manager +
      '&project_code=' +  this.search_project_code
    const result: any = await wpService.getWeekplanExcel(string1, (res) => {
      // const blob = new Blob([res.data], {
      //     type:
      //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
      // });
      // const downloadElement = document.createElement("a");
      // const href = window.URL.createObjectURL(blob); //创建下载的链接
      // downloadElement.href = href;
      // downloadElement.download = "xxx.xlsx"; //下载后文件名
      // document.body.appendChild(downloadElement);
      // downloadElement.click(); //点击下载
      // document.body.removeChild(downloadElement); //下载完成移除元素
      // window.URL.revokeObjectURL(href); //释放掉blob对象
    })
  }

   search() {
    this.searchWeekPlans()
  }

   handleSizeChange(val) {
    this.pageSize = val
    this.searchWeekPlans()
  }

   handlePagenumChange(val) {
    this.currentPage = val
    this.searchWeekPlans()
  }
   async submit() {
    if (this.formItem.current_week) {
      this.formItem.current_week = this.formItem.current_week
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    if (this.formItem.current_week_status) {
      this.formItem.current_week_status = this.formItem.current_week_status
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    if (this.formItem.next_week) {
      this.formItem.next_week = this.formItem.next_week
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }

    // element时间组件选择周一为第一天时会变为周二
    if (typeof this.formItem.weekplanWeek == 'object') {
      if (this.formItem.weekplanWeek.getDay() == 2) {
        this.formItem.weekplanWeek = this.formItem.weekplanWeek - 86400000
      }
    }

    this.formItem.startDate = moment(this.formItem.weekplanWeek).format(
      'YYYY-MM-DD'
    )
    this.formItem.endDate = moment(this.formItem.weekplanWeek)
      .add(6, 'days')
      .format('YYYY-MM-DD')

    this.formItem.lastmodified = this.userInfo.username
    this.formItem.lastmodified_date = moment().format('YYYY-MM-DD HH:mm:ss')
    if (this.formStyle == 'add') {
      this.formItem.type =
        this.formItem.type &&
        (this as any).lodash.find(this.projecttypeoptions, {
          value: this.formItem.type,
        })
          ? (this as any).lodash.find(this.projecttypeoptions, {
              value: this.formItem.type,
            }).text
          : ''
    }
    delete this.formItem.project_desc_sum
    delete this.formItem.weekplanWeek
    if (this.formStyle === 'add') {
      const result: any = await wpService.addWeekplan(this.formItem)
      if (result.success) {
        this.$message.success('提交成功')
        this.addDialogVisible = false
        this.searchWeekPlans()
      }
    } else {
      const result: any = await wpService.updateWeekplan(this.formItem)
      if (result.success) {
        this.$message.success('提交成功')
        this.addDialogVisible = false
        this.searchWeekPlans()
      }
    }
  }
   async onWeekPlanSubmit(evt) {
    ;(this.$refs['formItem'] as any).validate((valid) => {
      if (valid) {
        this.submit()
      } else {
        return false
      }
    })
  }

   editPlan(row) {
    this.formItem = _.cloneDeep(row)
    if (this.formItem.current_week) {
      this.formItem.current_week = this.formItem.current_week.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    if (this.formItem.current_week_status) {
      this.formItem.current_week_status =
        this.formItem.current_week_status.replace(/<br\/>/g, '\r\n')
    }
    if (this.formItem.next_week) {
      this.formItem.next_week = this.formItem.next_week.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    this.formItem['weekplanWeek'] = this.formItem.startDate
    //this.formItem.weekplanWeek = this.formItem.startDate;
    this.formEdittitle = '编辑周计划'
    this.formStyle = 'edit'
    this.addDialogVisible = true
  }

   editNextPlan(row) {
    this.formItem = _.cloneDeep(row)
    if (this.formItem.current_week) {
      this.formItem.current_week = this.formItem.current_week.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    if (this.formItem.current_week_status) {
      this.formItem.current_week_status =
        this.formItem.current_week_status.replace(/<br\/>/g, '\r\n')
    }
    delete this.formItem.id
    var WeekFirstDay: any = new Date(
      moment().endOf('week').add(2, 'days').format('YYYY-MM-DD')
    )

    this.formItem['weekplanWeek'] = WeekFirstDay
    this.formEdittitle = '编辑周计划'
    this.formStyle = 'add'
    this.addDialogVisible = true
  }

   async deletePlan(row) {
    this.$confirm('确认删除？')
      .then(async () => {
        const result: any = await wpService.deleteWeekplan({
          params: {
            row,
          },
        })
        if (result.success) {
          this.$message.success('删除成功')
          this.searchWeekPlans()
        }
      })
      .catch((err) => {
        throw err
      })
  }

   editDesc(row) {
    this.project_all_desc = ''
    this.currentProject_code = row.project_code
    const arrays = _.filter(this.items, { project_code: row.project_code })
    for (const item of arrays) {
      this.project_all_desc += item.project_desc ? item.project_desc + '\n' : ''
    }
    this.editDescDialogVisible = true
  }

   async onProDescSubmit() {
    const result: any = await wpService.updateProjectDesc({
      project_desc: this.project_all_desc,
      project_code: this.currentProject_code,
      startDate: moment(this.daterangeArea[0]).format('YYYY-MM-DD'),
      endDate: moment(this.daterangeArea[1]).format('YYYY-MM-DD'),
    })
    if (result.success) {
      this.$message.success('提交成功')
      this.searchWeekPlans()
    }
  }

   addNewWeekPlan() {
    const userInfoString = localStorage.getItem('userInfo')
    const userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
    for (const key in this.formItem) {
      if (this.formItem[key]) {
        this.formItem[key] = ''
      }
    }
    delete this.formItem.id
    var Nowdate: any = new Date()
    var WeekFirstDay: any = new Date(
      Nowdate - (Nowdate.getDay() - 1) * 86400000
    )

    this.formItem['weekplanWeek'] = WeekFirstDay
    this.formItem['institute'] = userInfo.dep_code
    this.formItem['manager'] = userInfo.name
    this.formItem['type'] = ''
    this.formEdittitle = '提交新计划'
    this.formStyle = 'add'
    this.addDialogVisible = true
  }

   objectSpanMethod({ row, column, rowIndex, columnIndex }) {
    if (columnIndex === 0) {
      const _row = this.contactArray[rowIndex]
      const _col = _row > 0 ? 1 : 0
      return {
        rowspan: _row,
        colspan: _col,
      }
    }
    if (columnIndex === 1) {
      const _row = this.contactArray1[rowIndex]
      const _col = _row > 0 ? 1 : 0
      return {
        rowspan: _row,
        colspan: _col,
      }
    }
    // if (columnIndex === 2) {
    //     const _row = this.contactArray2[rowIndex];
    //     const _col = _row > 0 ? 1 : 0;
    //     return {
    //         rowspan: _row,
    //         colspan: _col
    //     };
    // }
    // if (columnIndex === 3) {
    //     const _row = this.contactArray3[rowIndex];
    //     const _col = _row > 0 ? 1 : 0;
    //     return {
    //         rowspan: _row,
    //         colspan: _col
    //     };
    // }
  }

   normalrizeData() {
    this.contactArray = []
    this.contactArray1 = []
    this.contactArray2 = []
    this.items.map((item, index) => {
      if (index === 0) {
        this.contactArray.push(1)
        this.contactDot = 0
        this.contactArray1.push(1)
        this.contactDot1 = 0
        // this.contactArray2.push(1);
        // this.contactDot2 = 0;
        // this.contactArray3.push(1);
        // this.contactDot3 = 0;
      } else {
        // if (item.institute == this.items[index - 1].institute) {
        //     this.contactArray[this.contactDot] += 1;
        //     this.contactArray.push(0);
        // } else {
        //     this.contactArray.push(1);
        //     this.contactDot = index;
        // }
        // // if (item.project_desc === this.items[index - 1].project_desc) {
        // //     this.contactArray3[this.contactDot3] += 1;
        // //     this.contactArray3.push(0);
        // // } else {
        // //     this.contactArray3.push(1);
        // //     this.contactDot3 = index;
        // // }
        if (item.project_name == this.items[index - 1].project_name) {
          this.contactArray[this.contactDot] += 1
          this.contactArray.push(0)
        } else {
          this.contactArray.push(1)
          this.contactDot = index
        }
        if (
          item.project_name == this.items[index - 1].project_name &&
          item.project_desc_sum == this.items[index - 1].project_desc_sum
        ) {
          this.contactArray1[this.contactDot1] += 1
          this.contactArray1.push(0)
        } else {
          this.contactArray1.push(1)
          this.contactDot1 = index
        }
      }
    })
  }

   getSummaries(param) {
    const { columns, data } = param
    const sums = []
    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = '合计'
        return
      }
      const values = data.map((item) => Number(item[column.property]))
      if (
        !values.every((value) => isNaN(value)) &&
        (column.property == 'planhours' || column.property == 'hours')
      ) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr)
          if (!isNaN(value)) {
            return (parseFloat(prev) +parseFloat(curr)).toFixed(2)
          } else {
            return parseFloat(prev).toFixed(2)
          }
        }, 0)
        // sums[index] += ' 小时';
      } else {
        sums[index] = ''
      }
    })

    return sums
  }
}
