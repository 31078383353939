import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"10px","text-align":"left","margin-left":"20px"} }
const _hoisted_2 = { style: {"width":"300px"} }
const _hoisted_3 = { style: {"width":"300px"} }
const _hoisted_4 = { class: "description" }
const _hoisted_5 = { style: {"width":"300px"} }
const _hoisted_6 = { class: "description" }
const _hoisted_7 = { style: {"width":"300px"} }
const _hoisted_8 = { class: "description" }
const _hoisted_9 = { style: {"width":"300px"} }
const _hoisted_10 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: "project-total-container",
    style: _normalizeStyle({ height: $data.calcHeight + 'px' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createTextVNode(" 组织： "),
      _createVNode(_component_el_select, {
        modelValue: $data.dep_code,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.dep_code) = $event)),
        filterable: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.orgList, (item) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: item.code,
              label: item.name,
              value: item.code
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createTextVNode(" 客户： "),
      _createVNode(_component_el_select, {
        modelValue: $data.customer_name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.customer_name) = $event)),
        filterable: "",
        clearable: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.cusList, (item) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: item.code,
              label: item.name,
              value: item.code
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_el_input, {
        placeholder: "项目名称/项目编码",
        modelValue: $data.searchWord,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.searchWord) = $event)),
        size: "large",
        style: {"width":"250px"}
      }, null, 8, ["modelValue"]),
      _createTextVNode(" 工时统计时间： "),
      _createVNode(_component_el_date_picker, {
        modelValue: $data.date1,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.date1) = $event)),
        type: "daterange",
        "value-format": "YYYY-MM-DD",
        "range-separator": "至",
        "start-placeholder": "开始日期",
        "end-placeholder": "结束日期"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: $options.search,
        style: {"margin-left":"10px"}
      }, {
        default: _withCtx(() => [
          _createTextVNode("搜索")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: $options.exportProjects
      }, {
        default: _withCtx(() => [
          _createTextVNode("导出")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _withDirectives((_openBlock(), _createBlock(_component_el_table, { data: $data.projectList }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: "客户名称",
          width: "140"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(scope.row.customer_name &&
                    $data.lodash.find($data.cusList, {
                      code: scope.row.customer_name,
                    })
                      ? $data.lodash.find($data.cusList, {
                        code: scope.row.customer_name,
                        }).name
                      : ''), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "project_code",
          label: "项目编号",
          width: "140"
        }),
        _createVNode(_component_el_table_column, {
          prop: "project_name",
          label: "项目名称"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_tooltip, {
              "show-after": 1000,
              placement: "bottom"
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(scope.row.project_name), 1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, _toDisplayString(scope.row.project_name), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "project_type",
          label: "项目类型",
          width: "180"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_tooltip, {
              "show-after": 1000,
              placement: "bottom"
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, _toDisplayString(scope.row.project_type &&
                      $data.lodash.find($data.projecttypeoptions, {
                        value: scope.row.project_type,
                      })
                        ? $data.lodash.find($data.projecttypeoptions, {
                            value: scope.row.project_type,
                          }).text
                        : ''), 1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, _toDisplayString(scope.row.project_type &&
                    $data.lodash.find($data.projecttypeoptions, {
                      value: scope.row.project_type,
                    })
                      ? $data.lodash.find($data.projecttypeoptions, {
                          value: scope.row.project_type,
                        }).text
                      : ''), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          props: "plan_days",
          label: "项目计划工时",
          width: "140"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.plan_days !== '' && scope.row.plan_days !== null
                ? parseFloat(scope.row.plan_days) * 8
                : ''), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "hours",
          label: "工时",
          width: "140"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_tooltip, {
              "show-after": 1000,
              placement: "bottom"
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, _toDisplayString(scope.row.hours), 1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, _toDisplayString(scope.row.hours), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "percent",
          label: "占比",
          width: "140"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_tooltip, {
              "show-after": 1000,
              placement: "bottom"
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, _toDisplayString(scope.row.percent), 1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, _toDisplayString(scope.row.percent), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])), [
      [_directive_loading, $data.dialogLoading]
    ])
  ], 4))
}