import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"padding":"15px"}
}
const _hoisted_2 = {
  key: 1,
  style: {"padding":"15px"}
}
const _hoisted_3 = { style: {"margin-right":"10px"} }
const _hoisted_4 = { style: {"margin-right":"10px"} }
const _hoisted_5 = { style: {"margin-right":"10px"} }
const _hoisted_6 = ["onUpdate:modelValue"]
const _hoisted_7 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_date = _resolveComponent("date")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("section", {
    class: "panel-body assess-template",
    style: _normalizeStyle({ height: $data.calcHeight + 'px' })
  }, [
    _createVNode(_component_el_steps, {
      active: $data.active,
      "finish-status": "success",
      simple: "",
      style: {"margin-top":"20px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_step, { title: "评价类别" }),
        _createVNode(_component_el_step, { title: "评价详情" })
      ]),
      _: 1
    }, 8, ["active"]),
    ($data.active == 1)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_form, {
            ref: "form",
            model: $data.form,
            "label-width": "120px",
            rules: $data.rules
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "评价周期：",
                prop: "dimension"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: $data.form.dimension,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.dimension) = $event)),
                    placeholder: "请选择评价评价周期",
                    onChange: $options.dimensionChange
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.options, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          label: item.label,
                          value: item.value,
                          key: item.value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onChange"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "时间：",
                prop: "timeValue"
              }, {
                default: _withCtx(() => [
                  ($data.timeType != 'quarter')
                    ? (_openBlock(), _createBlock(_component_el_date_picker, {
                        key: 0,
                        modelValue: $data.form.timeValue,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.timeValue) = $event)),
                        type: $data.timeType,
                        onChange: _cache[2] || (_cache[2] = () => {})
                      }, null, 8, ["modelValue", "type"]))
                    : (_openBlock(), _createBlock(_component_date, {
                        key: 1,
                        getValue: $options.getValue,
                        defaultValue: $data.form.timeValue
                      }, null, 8, ["getValue", "defaultValue"]))
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "类型：",
                prop: "type"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: $data.form.type,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.type) = $event)),
                    placeholder: "请选择类型",
                    onChange: $options.typeChange
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.typeOptions, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          label: item.label,
                          value: item.value,
                          key: item.value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onChange"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { label: "人员：" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: $options.add
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("添加人员")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _withDirectives((_openBlock(), _createBlock(_component_el_table, {
                    data: $data.tableData,
                    onSelectionChange: $options.handleSelectionChange,
                    style: {"width":"400px !important","margin-left":"0px","float":"left"},
                    border: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_table_column, {
                        type: "selection",
                        width: "55"
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "code",
                        label: "编码"
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "name",
                        label: "姓名"
                      })
                    ]),
                    _: 1
                  }, 8, ["data", "onSelectionChange"])), [
                    [_directive_loading, $data.loadingUser]
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: $options.checkRules
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("下一步")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ])), [
          [_directive_loading, $data.loading]
        ])
      : _createCommentVNode("", true),
    ($data.active == 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_form, {
            inline: true,
            model: $data.formInline
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, { label: "评价周期：" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString($data.lodash.find($data.options, { value: $data.form.dimension }).label), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { label: "时间：" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_4, _toDisplayString($data.timeType == 'month'
              ? $data.form.timeValue.getFullYear() +
                ($data.form.timeValue.getMonth() + 1).toString().padStart(2, '0')
              : $data.timeType == 'year'
              ? $data.form.timeValue.getFullYear()
              : $data.quarterLabel), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, { label: "类型：" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_5, _toDisplayString($data.lodash.find($data.typeOptions, { value: $data.form.type }).label), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createVNode(_component_el_table, {
            data: $data.multipleSelection,
            ref: "addTable",
            "highlight-current-row": "",
            border: "",
            style: {"margin-left":"0px","width":"100% !important"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                prop: "code",
                label: "用户编码",
                align: "center"
              }),
              _createVNode(_component_el_table_column, {
                prop: "name",
                label: "用户名称",
                align: "center"
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.templateItems, (item) => {
                return (_openBlock(), _createBlock(_component_el_table_column, {
                  prop: item.item_code,
                  label: item.item_name + '(' + item.weight + '%)',
                  align: "center",
                  key: item.item_code,
                  width: "170"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_select, {
                        modelValue: scope['row'][item.item_code],
                        "onUpdate:modelValue": ($event: any) => ((scope['row'][item.item_code]) = $event),
                        placeholder: "请选择分数",
                        filterable: "",
                        "allow-create": "",
                        onChange: ($event: any) => (
                  $options.gradeChange(
                    scope.row,
                    scope.$index,
                    item.item_code,
                    scope['row'][item.item_code],
                    item.weight,
                    scope
                  )
                )
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.gradeOptions, (grade) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: grade.value,
                              label: grade.label,
                              value: grade.value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
                    ])
                  ]),
                  _: 2
                }, 1032, ["prop", "label"]))
              }), 128)),
              _createVNode(_component_el_table_column, {
                prop: "remark",
                label: "备注",
                align: "center"
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      class: "el-input__inner",
                      "onUpdate:modelValue": ($event: any) => ((scope.row.remark) = $event)
                    }, null, 8, _hoisted_6), [
                      [_vModelText, scope.row.remark]
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                prop: "total",
                label: "总分",
                align: "center",
                width: "120"
              })
            ]),
            _: 1
          }, 8, ["data"]),
          _createVNode(_component_el_row, { style: {"margin-top":"20px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, { onClick: $options.cancelAction }, {
                default: _withCtx(() => [
                  _createTextVNode("取消")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: $options.createAssess
              }, {
                default: _withCtx(() => [
                  _createTextVNode("确定")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_dialog, {
      title: "添加人员",
      modelValue: $data.dialogVisible,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.dialogVisible) = $event)),
      width: "50%",
      top: "5vh"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_7, [
          _createVNode(_component_el_button, {
            onClick: _cache[5] || (_cache[5] = ($event: any) => ($data.dialogVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取 消")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $options.confirm
          }, {
            default: _withCtx(() => [
              _createTextVNode("确 定")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          placeholder: "用户名称",
          modelValue: $data.userName,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.userName) = $event)),
          style: {"width":"250px","margin-bottom":"10px"},
          onKeyup: _withKeys($options.queryClick, ["enter"])
        }, {
          append: _withCtx(() => [
            _createVNode(_component_el_button, {
              icon: $data.ElIconSearch,
              onClick: $options.queryClick
            }, null, 8, ["icon", "onClick"])
          ]),
          _: 1
        }, 8, ["modelValue", "onKeyup"]),
        _createVNode(_component_el_table, {
          data: $data.userList,
          onSelectionChange: $options.handleSelectionChange1,
          border: "",
          style: {"margin-left":"0"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              type: "selection",
              width: "55"
            }),
            _createVNode(_component_el_table_column, {
              prop: "code",
              label: "编码"
            }),
            _createVNode(_component_el_table_column, {
              prop: "name",
              label: "姓名"
            })
          ]),
          _: 1
        }, 8, ["data", "onSelectionChange"]),
        _createVNode(_component_el_pagination, {
          onCurrentChange: $options.handlePagenumChange,
          "current-page": $data.currentPage,
          "page-size": $data.pageSize,
          layout: "total, prev, pager, next, jumper",
          total: $data.total,
          style: {"float":"right"}
        }, null, 8, ["onCurrentChange", "current-page", "page-size", "total"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 4))
}