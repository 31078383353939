
import { Search as ElIconSearch } from '@element-plus/icons-vue'
import { Component, Vue, Watch } from 'vue-facing-decorator'
import projectService from '../../../services/projectmanager/projectservice'
import milestoneservice from '../../../services/projectmanager/milestoneservice'
import costdownservice from '../../../services/projectmanager/costdownservice'
import orgService from '../../../services/org/orgservice'
import { project_types }  from '../../../util'
import moment from 'moment'
import * as _ from 'lodash'

@Component
export default class ProjectList extends Vue {

      userInfoString = localStorage.getItem('userInfo')
      activeName = 'milestone'
      lodash = _
      dialogStyle = ''
      weekPlanStyle = ''
      milestonetitle = ''
      probTitle = ''
      currentTab = 'milestone'
      costdownformtitle = ''
      currentRow: any = {}
      queryItem: any = {
        project_name: '',
        project_manager: '',
      }
      probQueryItem: any = {}
      currentPage = 1
      weekplanWeek = ''
      probWeek = ''
      addDialogType = ''
      probStyle = ''
      probDialogVisible = false
      needPreEndChange = false
      pageSize = 10
      tableDataLength = 0
      editform: any = {}
      detailrow: any = {}
      addWeekDialogVisible = false
      weekplantitle = ''
      notClose = false
      dialogVisible = false
      addDialogVisible = false
      addCdDialogVisible = false
      fullscreenEdit = true
      editDialogVisible = false
      loading = true
      needPage = true
      tabsloading = true
      enums = []
      calcHeight = document.documentElement.clientHeight - 60
      items = []
      milestoneitems = []
      costdownitems = []
      weekplanitems = []
      probitems = []
      userInfo = this.userInfoString !== null ? JSON.parse(this.userInfoString) : {}
      costdownItem: any = {}
      probFormItem: any = {
        project_code: '',
        prob_name: '',
        manager: '',
        pre_end_time: '',
        descri: '',
        status: '',
        type: '',
      }
      mileStoneItem: any = {
        project_code: '',
        milestone: '',
        pre_start_time: '',
        start_time: '',
        pre_end_time: '',
        end_time: '',
        delay_days: '',
        version: '',
        remark: '',
        preendchangeremark: '',
      }
      weekplanItem: any = {
        project_code: '',
        current_week: '',
        manager: '',
        descri: '',
        next_week: '',
        next_manager: '',
      }
      projecttypeoptions = project_types
      proborriskoptions = [
        {
          value: '0',
          text: '问题',
        },
        {
          value: '1',
          text: '风险',
        },
      ]
      probtypeoptions = [
        {
          value: '0',
          text: '打开',
        },
        {
          value: '1',
          text: '预警',
        },
        {
          value: '2',
          text: '紧急',
        },
        {
          value: '3',
          text: '延期',
        },
        {
          value: '4',
          text: '关闭',
        },
      ]
      projectstageoptions = [
        {
          value: '0',
          text: '未启动',
        },
        {
          value: '1',
          text: '进行中',
        },
        {
          value: '2',
          text: '已结项',
        },
        {
          value: '3',
          text: '已终止',
        },
        {
          value: '4',
          text: '已生效',
        },
        {
          value: '5',
          text: '已完成',
        },
      ]
      projectstatusclassoptions = [
        {
          value: '0',
          text: '提前',
        },
        {
          value: '1',
          text: '正常',
        },
        {
          value: '2',
          text: '预警',
        },
        {
          value: '3',
          text: '延期',
        },
        {
          value: '4',
          text: '暂停',
        },
        {
          value: '5',
          text: '结项',
        },
        {
          value: '6',
          text: '终止',
        },
      ]
      orgList = []
    

  public async created() {
    this.init()
    this.getOrgInfo()
  }
  private async getOrgInfo() {
    const result: any = await orgService.getOrgInfo()
    this.orgList = result.rows
  }
  private async getProjects() {
    const param = {
      is_view: '1',
      currentPage: this.currentPage,
      pageSize: this.pageSize,
    }
    if (!this.needPage) {
      param.currentPage = null
    }
    for (const key in this.queryItem) {
      if (this.queryItem[key] !== undefined && this.queryItem[key] !== '') {
        param[key] =
          typeof this.queryItem[key] === 'object'
            ? JSON.stringify(this.queryItem[key])
            : this.queryItem[key]
      }
    }
    const result: any = await projectService.getProject({
      params: param,
    })
    if (result.success) {
      return result.rows
    } else {
      return []
    }
  }

  private async init() {
    this.items = await this.getProjects()
    this.tableDataLength = 0
    if (this.items[0]) {
      this.setCurrent(this.items[0])
      this.tableDataLength = this.items[0].total
    }
    const table: any = this.$refs.singleTable
    table.doLayout()
    this.needPage = true
    this.loading = false
  }

  @Watch('currentRow')
  private onCurrentRowChanged(val: any, oldVal: any) {
    if (!val) {
      return
    }
    this.getMilestones(val.project_code)
    this.getCostdowns()
    this.getWeekPlans(val.project_code)
    this.getProblems(val.project_code)
    this.tabsloading = false
  }

  @Watch('mileStoneItem.pre_end_time')
  private preEndTimeChanged(val: any, oldVal: any) {
    if (this.dialogStyle === 'edit' && oldVal !== '') {
      this.needPreEndChange = true
      this.mileStoneItem.old_pre_end_time = oldVal
    }
  }

  // 获取里程碑
  private async getMilestones(project_code: string) {
    const param = {}
    param['is_show'] = JSON.stringify(['1'])
    param['project_code'] = project_code
    const result: any = await milestoneservice.getProjectMilestone({
      params: param,
    })

    if (result.success) {
      for (const item of result.rows) {
        const details = _.filter(result.details, (detailItem) => {
          return detailItem.milestone_id === item.id ? true : false
        })
        if (details.length > 0) {
          item.changeDetails = details
        }
      }
      this.milestoneitems = result.rows
    }
  }

  // 获取周计划
  private async getWeekPlans(project_code: string) {
    const result: any = await projectService.getProjectWeekplan({
      params: { project_code },
    })
    if (result.success) {
      this.weekplanitems = result.rows
    }
  }

  // 获取问题
  private async getProblems(project_code: string) {
    const param = {
      project_code,
    }
    for (const key in this.probQueryItem) {
      if (
        this.probQueryItem[key] !== undefined &&
        this.probQueryItem[key] !== ''
      ) {
        param[key] =
          typeof this.probQueryItem[key] === 'object'
            ? JSON.stringify(this.probQueryItem[key])
            : this.probQueryItem[key]
      }
    }
    const result: any = await projectService.getProjectProblem({
      params: param,
    })
    if (result.success) {
      this.probitems = result.rows
    }
  }

  private handleSizeChange(val) {
    this.pageSize = val
    this.init()
  }
  private handlePagenumChange(val) {
    this.currentPage = val
    this.init()
  }

  // 提交周计划
  private async onAddWeekplanSubmit(evt) {
    evt.preventDefault()
    this.weekplanItem.project_code = this.currentRow.project_code
    this.weekplanItem.last_modified = this.userInfo.username
    this.weekplanItem.last_modified_date = moment().format('YYYY-MM-DD')
    this.weekplanItem.startdate = moment(this.weekplanWeek).format('YYYY-MM-DD')
    this.weekplanItem.enddate = moment(this.weekplanWeek)
      .add(6, 'days')
      .format('YYYY-MM-DD')
    if (this.weekplanItem.current_week) {
      this.weekplanItem.current_week = this.weekplanItem.current_week
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    if (this.weekplanItem.descri) {
      this.weekplanItem.descri = this.weekplanItem.descri
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    if (this.weekplanItem.next_week) {
      this.weekplanItem.next_week = this.weekplanItem.next_week
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    const param = _.cloneDeep(this.weekplanItem)
    for (const key in param) {
      if (!param[key]) {
        delete param[key]
      }
    }
    if (this.weekPlanStyle === 'add') {
      const result: any = await projectService.addProjectWeekplan(param)
      if (result.success) {
        alert('添加周计划成功')
        this.getWeekPlans(this.currentRow.project_code)
        this.addWeekDialogVisible = false
      }
    }
    if (this.weekPlanStyle === 'edit') {
      const result: any = await projectService.updateProjectWeekplan(param)
      if (result.success) {
        alert('更新周计划成功')
        this.getWeekPlans(this.currentRow.project_code)
        this.addWeekDialogVisible = false
      }
    }
  }

  // 提交里程碑
  private async onAddMilestoneSubmit(evt) {
    evt.preventDefault()
    this.mileStoneItem.project_code = this.currentRow.project_code
    this.mileStoneItem.last_modified = this.userInfo.username
    const param = _.cloneDeep(this.mileStoneItem)
    if (param.remark) {
      param.remark = param.remark
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    if (param.complete_status) {
      param.complete_status = param.complete_status
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    for (const key in param) {
      if (!param[key]) {
        delete param[key]
      }
    }
    delete param.changeDetails
    if (this.dialogStyle === 'add') {
      const result: any = await milestoneservice.addProjectMilestone(param)
      if (result.success) {
        alert('添加里程碑成功')
        this.getMilestones(this.currentRow.project_code)
        this.addDialogVisible = false
        this.needPreEndChange = false
        delete this.mileStoneItem.old_pre_end_time
      }
    }
    if (this.dialogStyle === 'edit') {
      const result: any = await milestoneservice.updateProjectMilestone(param)
      if (result.success) {
        alert('更新里程碑成功')
        this.getMilestones(this.currentRow.project_code)
        this.addDialogVisible = false
        this.needPreEndChange = false
        delete this.mileStoneItem.old_pre_end_time
      }
    }
  }

  // 添加里程碑
  private async addNewMilestone() {
    this.dialogStyle = 'add'
    this.milestonetitle = '增加里程碑'
    for (const key in this.mileStoneItem) {
      if (this.mileStoneItem[key]) {
        this.mileStoneItem[key] = ''
      }
    }
    this.addDialogVisible = true
  }

  // 添加/更新周计划
  private addNewWeekplan() {
    this.weekPlanStyle = 'add'
    this.weekplantitle = '新增周计划'
    for (const key in this.weekplanItem) {
      if (this.weekplanItem[key]) {
        this.weekplanItem[key] = ''
      }
    }
    if (this.weekplanitems.length > 0) {
      const lastitem = this.weekplanitems[this.weekplanitems.length - 1]
      this.weekplanItem.current_week = lastitem.next_week
      this.weekplanItem.manager = lastitem.next_manager
    }
    if (this.weekplanItem.current_week) {
      this.weekplanItem.current_week = this.weekplanItem.current_week.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    if (this.weekplanItem.descri) {
      this.weekplanItem.descri = this.weekplanItem.descri.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    if (this.weekplanItem.next_week) {
      this.weekplanItem.next_week = this.weekplanItem.next_week.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    this.weekplanItem.project_code = this.currentRow.project_code
    this.addWeekDialogVisible = true
  }

  // 删除周计划
  private async deletePlanWeek(row) {
    this.$confirm('确认删除？')
      .then(async () => {
        const result: any = await projectService.deleteProjectWeekplan({
          params: {
            row,
          },
        })
        if (result.success) {
          this.$message({
            message: '删除成功',
            type: 'success',
          })
          this.getWeekPlans(this.currentRow.project_code)
        }
      })
      .catch((err) => {
        throw err
      })
  }

  // 编辑周计划
  private editPlanWeek(row) {
    this.weekPlanStyle = 'edit'
    this.weekplantitle = '编辑周计划'
    this.weekplanItem = _.cloneDeep(row)
    this.weekplanWeek = row.startdate
    if (this.weekplanItem.current_week) {
      this.weekplanItem.current_week = this.weekplanItem.current_week.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    if (this.weekplanItem.descri) {
      this.weekplanItem.descri = this.weekplanItem.descri.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    if (this.weekplanItem.next_week) {
      this.weekplanItem.next_week = this.weekplanItem.next_week.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    this.addWeekDialogVisible = true
  }

  // 删除问题记录
  private deleteProb(row) {
    this.$confirm('确认删除？')
      .then(async () => {
        const result: any = await projectService.deleteProjectProblem({
          params: {
            row,
          },
        })
        if (result.success) {
          this.$message({
            message: '删除成功',
            type: 'success',
          })
          this.getProblems(this.currentRow.project_code)
        }
      })
      .catch((err) => {
        throw err
      })
  }

  // 编辑问题记录
  private editProb(row) {
    this.probStyle = 'edit'
    this.probTitle = '编辑问题记录'
    this.probFormItem = _.cloneDeep(row)
    this.weekplanWeek = row.startdate
    if (this.probFormItem.descri) {
      this.probFormItem.descri = this.probFormItem.descri.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    if (this.probFormItem.prob_name) {
      this.probFormItem.prob_name = this.probFormItem.prob_name.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    this.probDialogVisible = true
  }

  // 添加问题记录
  private addNewProb() {
    this.probStyle = 'add'
    this.probTitle = '添加问题记录'
    for (const key in this.probFormItem) {
      if (this.probFormItem[key]) {
        this.probFormItem[key] = ''
      }
    }
    const index = this.probitems.length - 1
    const lastPro = this.probitems[index]
    if (lastPro) {
      this.probFormItem.prob_name = lastPro.prob_name
      this.probFormItem.manager = lastPro.manager
    }
    this.probFormItem.project_code = this.currentRow.project_code
    this.probDialogVisible = true
  }

  // 提交问题记录
  private async onAddProbSubmit(evt) {
    evt.preventDefault()
    this.probFormItem.project_code = this.currentRow.project_code
    this.probFormItem.last_modified = this.userInfo.username
    this.probFormItem.last_modified_date = moment().format('YYYY-MM-DD')
    this.probFormItem.startdate = moment(this.weekplanWeek).format('YYYY-MM-DD')
    this.probFormItem.enddate = moment(this.weekplanWeek)
      .add(6, 'days')
      .format('YYYY-MM-DD')
    const param = _.cloneDeep(this.probFormItem)
    if (param.descri) {
      param.descri = param.descri
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    if (param.next_week) {
      param.next_week = param.next_week
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    for (const key in param) {
      if (!param[key]) {
        delete param[key]
      }
    }
    if (this.probStyle === 'add') {
      const result: any = await projectService.addProjectProblem(param)
      if (result.success) {
        alert('添加周计划成功')
        this.getProblems(this.currentRow.project_code)
        this.probDialogVisible = false
      }
    }
    if (this.probStyle === 'edit') {
      const result: any = await projectService.updateProjectProblem(param)
      if (result.success) {
        alert('更新周计划成功')
        this.getProblems(this.currentRow.project_code)
        this.probDialogVisible = false
      }
    }
  }

  // 编辑里程碑
  private async editMilestone(data) {
    this.dialogStyle = 'edit'
    this.milestonetitle = '编辑里程碑'
    this.mileStoneItem = _.cloneDeep(data)
    if (this.mileStoneItem.complete_status) {
      this.mileStoneItem.complete_status =
        this.mileStoneItem.complete_status.replace(/<br\/>/g, '\r\n')
    }
    if (this.mileStoneItem.remark) {
      this.mileStoneItem.remark = this.mileStoneItem.remark.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    this.addDialogVisible = true
  }

  // 删除里程碑
  private async deleteMilestone(data) {
    this.$confirm('确认删除？')
      .then(async () => {
        const result: any = await milestoneservice.deleteProjectMilestone({
          params: {
            row: data,
          },
        })
        if (result.success) {
          this.$message({
            message: '删除成功',
            type: 'success',
          })
          this.getMilestones(this.currentRow.project_code)
        }
      })
      .catch((err) => {
        throw err
      })
  }

  // 获取降成本
  private async getCostdowns() {
    const result: any = await costdownservice.getCodedownPrice({
      params: {
        project_code: this.currentRow.project_code,
      },
    })
    if (result.success) {
      this.costdownitems = result.rows
    }
  }

  // 提交降成本
  private async onCostdownSubmit(evt) {
    evt.preventDefault()
    this.costdownItem.last_modified = this.userInfo.username
    this.costdownItem.project_code = this.currentRow.project_code
    let result: any = null
    if (this.cddialogType === 'edit') {
      result = await costdownservice.updateCodedownPrice(this.costdownItem)
    } else {
      result = await costdownservice.addCodedownPrice(this.costdownItem)
    }
    if (result.success) {
      this.$message({
        message: '提交成功',
        type: 'success',
      })
      this.getCostdowns()
      this.addCdDialogVisible = false
    } else {
      this.$message({
        message: result.err,
        type: 'error',
      })
    }
  }

  // 新增降成本
  private addNewCostdown() {
    this.cddialogType = 'add'
    this.costdownformtitle = '新增降成本项'
    this.costdownItem = _.cloneDeep({
      month: '',
      purchase_count: '',
      purchas_price: '',
    })
    this.addCdDialogVisible = true
  }

  // 删除降成本
  private async deleteCdRow(row) {
    this.$confirm('确认删除？')
      .then(async () => {
        const result: any = await costdownservice.deleteCodedownPrice({
          params: {
            row,
          },
        })
        if (result.success) {
          this.$message({
            message: '删除成功',
            type: 'success',
          })
          this.getCostdowns()
        }
      })
      .catch((err) => {
        throw err
      })
  }

  // 编辑降成本
  private editCd(row) {
    this.cddialogType = 'edit'
    this.costdownItem = _.cloneDeep(row)
    this.costdownformtitle = '编辑降成本项'
    this.addCdDialogVisible = true
  }

  private detail() {
    this.detailrow = this.currentRow
    this.dialogVisible = true
  }

  private edit(data: any) {
    this.addDialogType = 'edit'
    this.editform = this.currentRow
    if (this.editform.reason_measure) {
      this.editform.reason_measure = this.editform.reason_measure.replace(
        /<br\/>/g,
        '\r\n'
      )
    }
    this.editDialogVisible = true
    // this.$router.push({name: 'pmprojectedit', params: {row: this.currentRow}});
  }

  private add(data: any) {
    this.addDialogType = 'add'
    this.editDialogVisible = true
    this.editform = {}
    // this.$router.push({name: 'pmprojectedit', params: {row: this.currentRow}});
  }

  private milestone(data: any) {
    this.$router.push({
      name: 'pmprojectmilestone',
      params: { row: data },
    })
  }

  private costdown(data: any) {
    this.$router.push({ name: 'pmprojectcostdown', params: { row: data } })
  }

  private setCurrent(row) {
    const table: any = this.$refs.singleTable
    table.setCurrentRow(row)
  }

  private handleCurrentChange(val) {
    this.currentRow = val
  }

  private quitEdit() {
    this.editDialogVisible = false
  }

  // 提交项目
  private async onEditSubmit(evt) {
    evt.preventDefault()
    // for ( const key in this.form) {
    //   if (this.form[key] === '' || this.form[key] === null) {
    //     delete this.form[key];
    //   }
    // }
    // this.loading = true;
    const userInfoString = localStorage.getItem('userInfo')
    const userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
    this.editform.last_modified = userInfo.username
    if (this.editform.reason_measure) {
      this.editform.reason_measure = this.editform.reason_measure
        .replace(/\r\n/g, '<br/>')
        .replace(/\n/g, '<br/>')
        .replace(/\s/g, ' ')
    }
    if (this.addDialogType === 'edit') {
      const result: any = await projectService.updateProject(this.editform)
      if (result.success) {
        alert('提交成功')
        this.editDialogVisible = false
        this.init()
      }
    } else {
      const result: any = await projectService.addProject(this.editform)
      if (result.success) {
        alert('提交成功')
        this.editDialogVisible = false
        this.init()
      }
    }
  }

  private handleMainTableCellClass({
    row,
    column,
    rowIndex,
    columnIndex,
  }): string {
    if (
      columnIndex === 6 &&
      (row[column.property] === '0' || row[column.property] === '1')
    ) {
      return 'blue'
    }
    if (columnIndex === 6 && row[column.property] === '2') {
      return 'yellow'
    }
    if (columnIndex === 6 && row[column.property] === '3') {
      return 'red'
    }
    if (columnIndex === 6 && row[column.property] === '4') {
      return 'gray'
    }
    return null
  }

  private sortChanged(obj) {
    const table: any = this.$refs.singleTable
    this.queryItem.order_column = obj.prop
    this.queryItem.order_type = obj.order === 'ascending' ? 'asc' : 'desc'
    this.init()
  }

  private async showPro() {
    this.needPage = false
    const table: any = this.$refs.singleTable
    const items = await this.getProjects()
    this.$router.push({
      name: 'weekshowname',
      params: {
        pros: items,
        curProject: this.currentRow,
      },
    })
  }

  private filterProStatus(filters) {
    for (const key in filters) {
      if (key) {
        this.queryItem[key] =
          filters[key].length === 0 ? undefined : filters[key]
      }
    }
    this.currentPage = 1
    this.init()
  }

  private filterProblemStatus(filters) {
    for (const key in filters) {
      if (key) {
        this.probQueryItem[key] =
          filters[key].length === 0 ? undefined : filters[key]
      }
    }
    this.getProblems(this.currentRow.project_code)
  }

  // 查询按钮事件
  private queryClick() {
    this.currentPage = 1
    this.init()
  }

  private tabClicked(args) {
    this.currentTab = args.name
  }

  private addNewDetailItem() {
    switch (this.currentTab) {
      case 'milestone':
        this.addNewMilestone()
        break
      case 'weekplan':
        this.addNewWeekplan()
        break
      case 'probtrack':
        this.addNewProb()
        break
      case 'costdown':
        this.addNewCostdown()
        break
      default:
        break
    }
  }
}
