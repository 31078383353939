interface Permission {
  permission_code: string
  permission_desc: string
  usercode: string
}

declare global {
  interface Window {
    permissons: Permission[]
  }
}

export default {
  mounted(el, binding, vnode) {
    const { expression } = binding
    if (expression) {
      const permissionRoles: string = expression
      const hasPermission = window.permissons.some((item: Permission) => {
        return "'" + item.permission_code + "'" === permissionRoles
      })

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error(`need roles! Like v-permission='edit'`)
    }
  },
  install(Vue: any) {},
}
