import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "querypanel" }
const _hoisted_2 = { class: "query-item" }
const _hoisted_3 = { class: "query-item" }
const _hoisted_4 = { class: "query-item" }
const _hoisted_5 = { class: "query-item" }
const _hoisted_6 = { class: "query-item" }
const _hoisted_7 = { class: "query-item" }
const _hoisted_8 = { class: "query-item" }
const _hoisted_9 = { class: "query-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", {
    class: "project-hour-container",
    style: _normalizeStyle({ height: _ctx.calcHeight + 'px' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_select, {
          modelValue: _ctx.queryItem.financial_type,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.queryItem.financial_type) = $event)),
          placeholder: "选择项目/财务编码",
          filterable: "",
          clearable: "",
          onChange: _ctx.queryClick,
          style: {"width":"150px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_option, {
              label: "项目编码",
              value: "0"
            }),
            _createVNode(_component_el_option, {
              label: "财务编码",
              value: "1"
            })
          ]),
          _: 1
        }, 8, ["modelValue", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_select, {
          modelValue: _ctx.queryItem.pro_dep,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.queryItem.pro_dep) = $event)),
          placeholder: "项目所属组织",
          filterable: "",
          clearable: "",
          onChange: _ctx.queryClick,
          style: {"width":"150px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orgList, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.code,
                label: item.name,
                value: item.code
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_date_picker, {
          shortcuts: _ctx.pickerOptions && _ctx.pickerOptions.shortcuts,
          "disabled-date": _ctx.pickerOptions && _ctx.pickerOptions.disabledDate,
          "cell-class-name": _ctx.pickerOptions && _ctx.pickerOptions.cellClassName,
          class: "query-date",
          modelValue: _ctx.queryItem.querydate,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.queryItem.querydate) = $event)),
          type: "daterange",
          align: "right",
          "unlink-panels": "",
          "range-separator": "至",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "value-format": "YYYY-MM-DD"
        }, null, 8, ["shortcuts", "disabled-date", "cell-class-name", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_el_input, {
          type: "text",
          modelValue: _ctx.queryItem.project_name,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.queryItem.project_name) = $event)),
          placeholder: "项目名称"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_select, {
          modelValue: _ctx.queryItem.project_type,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.queryItem.project_type) = $event)),
          placeholder: "项目类型",
          filterable: "",
          clearable: "",
          onChange: _ctx.queryClick,
          style: {"width":"150px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectTypeList, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.value,
                label: item.text,
                value: item.value
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_el_select, {
          modelValue: _ctx.queryItem.project_status,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.queryItem.project_status) = $event)),
          placeholder: "项目状态",
          filterable: "",
          clearable: "",
          onChange: _ctx.queryClick,
          style: {"width":"150px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectstatusclassoptions, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.value,
                label: item.text,
                value: item.value
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_el_select, {
          modelValue: _ctx.queryItem.class_type,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.queryItem.class_type) = $event)),
          placeholder: "开发分类等级",
          filterable: "",
          clearable: "",
          onChange: _ctx.setDevTypeList,
          style: {"width":"150px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.developTypeClass, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.value,
                label: item.label,
                value: item.value
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_el_select, {
          modelValue: _ctx.queryItem.class,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.queryItem.class) = $event)),
          placeholder: "开发分类",
          filterable: "",
          clearable: "",
          onChange: _ctx.queryClick,
          style: {"width":"150px"}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.developTypeList, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.value,
                label: item.name,
                value: item.value
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange"])
      ]),
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: _ctx.queryClick
      }, {
        default: _withCtx(() => [
          _createTextVNode("查询")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_el_table, {
      "show-summary": "",
      height: _ctx.calcHeight - 60,
      "span-method": _ctx.objectSpanMethod,
      "summary-method": _ctx.getSummaries,
      data: _ctx.tableData,
      ref: "singleTable",
      "highlight-current-row": "",
      border: "",
      onCurrentChange: _ctx.handleCurrentChange
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "project_code",
          label: "项目编码",
          "show-overflow-tooltip": "",
          width: "150"
        }),
        _createVNode(_component_el_table_column, {
          prop: "project_name",
          label: "项目名称",
          "show-overflow-tooltip": "",
          width: "250"
        }),
        _createVNode(_component_el_table_column, {
          prop: "project_status",
          width: "100",
          label: "项目状态"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.project_status &&
            _ctx.lodash.find(_ctx.projectstatusclassoptions, {
              value: scope.row.project_status,
            })
              ? _ctx.lodash.find(_ctx.projectstatusclassoptions, {
                  value: scope.row.project_status,
                }).text
              : ''), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "p_class",
          label: "开发分类",
          "show-overflow-tooltip": "",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.dtObject[scope.row.p_class]), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "itemHours",
          label: "工时",
          width: "100",
          sortable: ""
        }),
        _createVNode(_component_el_table_column, {
          prop: "project_type",
          label: "项目类型",
          "show-overflow-tooltip": "",
          width: "100"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(_ctx.ptObject[scope.row.project_type]), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["height", "span-method", "summary-method", "data", "onCurrentChange"])
  ], 4))
}