
import { Component, Vue } from 'vue-facing-decorator'
import loginService from '../services/login'
import workhoursservice from '../services/workhours/workhours'
import depservice from '../services/projectmanager/department'
import { Base64 } from 'js-base64'

@Component
export default class Changepwd extends Vue {

    validateUser = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('用户姓名不能为空'))
      } else {
        var reg = /^[\u4E00-\u9FA5]+$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('只允许汉字'))
        }
      }
    }
    validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('用户账号不能为空'))
      } else {
        var reg = /^[a-zA-Z0-9]+$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('只允许字母数字'))
        }
      }
    }
      form: any = {
        password: '',
        newpassword: '',
        newpasswordagain: '',
      }
      calcHeight = document.documentElement.clientHeight - 60
      userList = []
      formAudit: any = {}
      instituteOptions = [
        {
          value: '0',
          text: '平台软件所',
        },
        {
          value: '1',
          text: '应用软件所',
        },
        {
          value: '2',
          text: '结构所',
        },
        {
          value: '3',
          text: '系统所',
        },
        {
          value: '4',
          text: '预研所',
        },
        {
          value: '5',
          text: '部品所',
        },
        {
          value: '6',
          text: '硬件一所',
        },
        {
          value: '7',
          text: '硬件二所',
        },
        {
          value: '8',
          text: '研发管理室',
        },
        {
          value: '9',
          text: '项目管理室',
        },
      ]
      depList = []
      rules: any = {
        username: [
          {
            required: true,
            validator: this.validateCode,
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            validator: this.validateUser,
            trigger: 'blur',
          },
          {
            min: 1,
            max: 20,
            message: '长度要求1~20个字符',
            trigger: 'blur',
          },
        ],
        dep_code: [
          {
            required: true,
            message: '请选择组织',
            trigger: 'change',
          },
        ],
      }
      typeOptions = [
        {
          value: '1',
          label: '总监',
        },
        {
          value: '2',
          label: '所长',
        },
        {
          value: '3',
          label: '专家',
        },
        {
          value: '4',
          label: '项目经理',
        },
        {
          value: '5',
          label: '员工',
        },
      ]

  async getDep() {
    const items: any = await depservice.getDep({})
    if (items.success) {
      this.depList = items.rows
    } else {
      this.$message.warning('获取组织信息失败')
    }
  }
  async onSubmit(evt) {
    evt.preventDefault()
    const userInfoString = localStorage.getItem('userInfo')
    const userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
    const pwdreg = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
    if (this.form.newpassword !== this.form.newpasswordagain) {
      alert('新密码不一致')
      return
    } else if (pwdreg.test(this.form.newpasswordagain) === false) {
      alert('不符合密码强度要求')
      return
    } else {
      const result: any = await loginService.changepwd({
        id: userInfo.id,
        username: userInfo.username,
        password: Base64.encode(this.form.password),
        newpassword: Base64.encode(this.form.newpassword),
      })
      if (result.success) {
        alert('修改成功，请重新登录！')
        localStorage.removeItem('token')
        localStorage.setItem('notlogin', '0')
        localStorage.removeItem('isOutDateChangePwd')
        this.$router.push('/')
      } else {
        alert(result.msg)
      }
    }
  }

  mounted() {
    const userInfoString = localStorage.getItem('userInfo')
    const userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
    this['calcHeight'] = document.documentElement.clientHeight - 60
    this.getAllUser()
    this.getDep()
    this['formAudit'] = userInfo
  }
  async getAllUser() {
    const items: any = await workhoursservice.getAllUser({})
    if (items.success) {
      var temp = items.rows
      this['userList'] = temp
      // for (var i = 0; i < temp.length; i++) {
      //     var obj = {};
      //     obj["value"] = temp[i].code;
      //     obj["name"] = temp[i].name;
      //     .push(obj);
      // }
    } else {
      this.$message.warning(items.msg)
    }
  }
  submit() {
    var self = this
    ;(this.$refs['formAudit'] as any).validate((valid) => {
      if (valid) {
        self.updateAudit()
      } else {
        return false
      }
    })
  }
  async updateAudit() {
    var userInfoString = localStorage.getItem('userInfo')
    var userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
    var login_code = userInfo['username']
    this['formAudit'].code = this['formAudit']['username']
    delete this['formAudit'].username
    // const params = {
    //     code: login_code,
    //     audit: this["formAudit"]
    // };
    const result: any = await loginService.updateUser(this['formAudit'])
    if (result.success) {
      this.$message.success('修改成功')
    } else {
      this.$message.warning(result.msg)
    }
    this['formAudit'].username = this['formAudit']['code']
  }
}
