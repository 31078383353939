import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../../config/index'

const apiPath = {
  customer: '/mpapi/customer',
}

async function getCustomerList(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.customer, {
    params: param,
  })
  return result.data
}

async function addtCustomer(param: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.customer, param)
  return result.data
}

async function deleteCustomer(param: object): Promise<AxiosResponse> {
  const result = await axios.delete(config.url + apiPath.customer, param)
  return result.data
}

async function updateCustomer(param: object): Promise<AxiosResponse> {
  const result = await axios.put(config.url + apiPath.customer, param)
  return result.data
}

export default {
  getCustomerList,
  addtCustomer,
  updateCustomer,
  deleteCustomer,
}
