
import { Search as ElIconSearch } from '@element-plus/icons-vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as _ from 'lodash'
import workhoursservice from '../../services/workhours/workhours'
import loginService from '../../services/login'
import orgService from '../../services/org/orgservice'
export default {
  data() {
    var validateUser = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('用户姓名不能为空'))
      } else {
        var reg = /^[\u4E00-\u9FA5]+$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('只允许汉字'))
        }
      }
    }
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('用户账号不能为空'))
      } else {
        var reg = /^[a-zA-Z0-9]+$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('只允许字母数字'))
        }
      }
    }
    return {
      lodash: _,
      searchWord: '',
      tableData: [],
      percentState: false,
      currentPage: 1,
      tableDataLength: 0,
      pageSize: 20,
      loading: false,
      calcHeight: document.documentElement.clientHeight - 60,
      addDialogVisible: false,
      status: '',
      title: '',
      userList: [],
      total: 0,
      userName: '',
      orgList: [],
      formAudit: {},
      userOptions: [],
      rules: {
        code: [
          {
            required: true,
            validator: validateCode,
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            validator: validateUser,
            trigger: 'blur',
          },
          {
            min: 1,
            max: 20,
            message: '长度要求1~20个字符',
            trigger: 'blur',
          },
        ],
        dep_code: [
          {
            required: true,
            message: '请选择组织',
            trigger: 'change',
          },
        ],
      },
      ElIconSearch,
    }
  },
  mounted() {
    this.loading = true
    this.getOrgInfo()
    this.getAllUser()
    this.init()
  },
  methods: {
    // 初始化获取信息
    async init() {
      this.loading = true
      const params = {
        name: this.userName,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        status: '1',
      }
      const items: any = await workhoursservice.getAllUser(params)
      if (items.success) {
        this.userList = items.rows
        this.total = items.total
      } else {
        this.$message.warning(items.msg)
      }
      this.loading = false
    },
    async getOrgInfo() {
      var self = this
      const items: any = await orgService.getOrgInfo()
      if (items.success) {
        this.orgList = items.rows
      } else {
        this.$message.warning(items.msg)
      }
    },
    add() {
      //this.$message.error("请联系管理员")
      this.$router.push('/register')
    },
    submit() {
      var self = this
      ;(this.$refs['formAudit'] as any).validate((valid) => {
        if (valid) {
          self.updateAudit()
        } else {
          return false
        }
      })
    },
    updateUser(row) {
      this.formAudit = _.cloneDeep(row)
      this.addDialogVisible = true
    },
    enableUser(row) {
      const data = _.cloneDeep(row)
      let name = ''
      if (row.is_active == '1') {
        name = '禁用'
        data.is_active = '0'
      } else {
        name = '启用'
        data.is_active = '1'
      }
      this.$confirm('确认' + name + '？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const result: any = await loginService.updateUser(data)
          if (result.success) {
            this.$message({
              message: '处理成功',
              type: 'success',
            })
            this.init()
          } else {
            this.$message({
              message: result.msg,
              type: 'error',
            })
          }
        })
        .catch((err) => {
          throw err
        })
    },
    async updateAudit() {
      const result: any = await loginService.updateUser(this['formAudit'])
      if (result.success) {
        this.$message.success('修改成功')
        this.addDialogVisible = false
        this.init()
      } else {
        this.$message.warning(result.msg)
      }
    },
    async getAllUser() {
      const items: any = await workhoursservice.getAllUser({})
      if (items.success) {
        this.userOptions = items.rows
      } else {
        this.$message.warning(items.msg)
      }
    },
    close() {
      this.$refs['formAudit'].clearValidate()
    },
    async deleteUser(row) {
      var params = {
        code: row.code,
      }
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const result: any = await loginService.deleteUser({
            params,
          })
          if (result.success) {
            this.$message({
              message: '删除成功',
              type: 'success',
            })
            this.init()
          }
        })
        .catch((err) => {
          throw err
        })
    },
    async restPwd(row) {
      this.$confirm('确认将密码重置为123qwe？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const result: any = await loginService.resetPwd(row)
          if (result.success) {
            this.$message({
              message: '重置成功',
              type: 'success',
            })
            this.init()
          }
        })
        .catch((err) => {
          throw err
        })
    },
    handlePagenumChange(val) {
      this.currentPage = val
      this.init()
    },

    queryClick() {
      ;(this.currentPage = 1), this.init()
    },
  },
}
