import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../config/index'

const apiPath = {
  permission: '/permission',
}

async function getPermission(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.permission, {
    params: param,
  })
  return result.data
}

export default {
  getPermission,
}
