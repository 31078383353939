import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../config/index'
import router from '../router'
import { ElLoading as Loading } from 'element-plus'
import { ElMessage } from 'element-plus'
let loadingInstance = null

axios.defaults.withCredentials = true
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    loadingInstance = Loading.service({ fullscreen: true })
    config.headers.common['Authorization'] = localStorage.getItem('token')
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    loadingInstance.close()
    if (response.data.success === false && response.data.status == 707) {
      const tip = localStorage.getItem('notlogin')
      if (tip !== '1') {
        router.push('/')
        ElMessage(response.data.msg)
        localStorage.setItem('notlogin', '1')
      }
      console.log(response.data)
      return;
    }
    if (response.data.token) {
      localStorage.setItem('token', response.data.token)
    }
    return response
  },
  (error) => {
    console.log(error)
    return {}
  }
)

const apiPath = {
  login: '/login',
  loginhis: '/loginhis',
  checkUser: '/checkUser',
  getSmsUser: '/smsuser',
  logout: '/logout',
  changepwd: '/changepwd',
  resetPwd: '/resetPwd',
  resetPwdRequest: '/resetPwdRequest',
  resetPwdSubmit: '/resetPwdSubmit',
  updateUser: '/updateUser',
  deleteUser: '/deleteuser',
  getUpdateLog: '/mpapi/updatelog',
  getSts: '/sts',
}

export interface UserForm {
  username: string
  password: string
  mobile: string
}

async function login(param: UserForm): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.login, param)
  return result.data
}

async function checkUser(param): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.checkUser, param)
  return result.data
}

// 获取sts
async function getSts(param): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.getSts, param)
  return result
}

async function getSmsUser(): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.getSmsUser)
  return result.data
}

async function changepwd(param): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.changepwd, param)
  return result.data
}

async function resetPwd(param): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.resetPwd, param)
  return result.data
}

async function resetPwdRequest(param): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.resetPwdRequest, param)
  return result.data
}

async function resetPwdSubmit(param): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.resetPwdSubmit, param)
  return result.data
}

async function getLoginhis(param): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.loginhis, param)
  return result.data
}

async function logout(): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.logout, {})
  return result.data
}
async function updateUser(param): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.updateUser, param)
  return result.data
}

async function deleteUser(param): Promise<AxiosResponse> {
  const result = await axios.delete(config.url + apiPath.deleteUser, param)
  return result.data
}

async function getUpdateLog(param): Promise<string> {
  const result = await axios.get(config.url + apiPath.getUpdateLog, param)
  return result.data
}

export default {
  login,
  getSmsUser,
  checkUser,
  changepwd,
  logout,
  getLoginhis,
  updateUser,
  deleteUser,
  getUpdateLog,
  resetPwd,
  resetPwdRequest,
  resetPwdSubmit,
  getSts,
}
