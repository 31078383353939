import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../../config/index'

const apiPath = {
  orginfo: '/org/info',
}

async function getOrgInfo(): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.orginfo)
  return result.data
}

async function addOrg(params: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.orginfo, params)
  return result.data
}

async function deleteOrg(params: object): Promise<AxiosResponse> {
  const result = await axios.delete(config.url + apiPath.orginfo, params)
  return result.data
}

async function updateOrg(): Promise<AxiosResponse> {
  const result = await axios.put(config.url + apiPath.orginfo)
  return result.data
}

export default {
  getOrgInfo,
  addOrg,
  deleteOrg,
  updateOrg,
}
